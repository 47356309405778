import { Grid, Card, Snackbar, Alert, Slide } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import DataTable from "examples/Tables/DataTable";
import * as React from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { useEffect, useState, useContext } from "react";
import { Font } from "@react-pdf/renderer";
import { UserContext } from "context/UserContext";
import PDFViewerDialog from "./components/PDFViewer";
import OnlineStatusSnackbar from "../../onlineStatusSnackbar/onlineStatusSnackbar";

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

function LocationsAnalysisResults() {
  useEffect(() => {
    document.title = "Analysis Results - RadDB";
  }, []);
  const { locations } = useContext(UserContext);

  const [newYear, setNewYear] = useState("");

  const [newMonth, setNewMonth] = useState("");

  let doseCode;
  let locationID;

  if (
    newMonth === "January" ||
    newMonth === "March" ||
    newMonth === "May" ||
    newMonth === "July" ||
    newMonth === "September" ||
    newMonth === "November"
  ) {
    doseCode = "dosimeterID1";
    locationID = "locationID1";
  } else {
    doseCode = "dosimeterID2";
    locationID = "locationID2";
  }

  const [snackbarOpen, setSnackbarOpen] = useState({
    openSnackBar: false,
    vertical: "top",
    horizontal: "center",
  });

  const [snackbarSuccessOpen, setSnackbarSuccessOpen] = useState({
    openSnackBarSuccess: false,
    vertical: "top",
    horizontal: "center",
  });

  const { vertical, horizontal, openSnackBar } = snackbarOpen;

  const { verticalSuccess, horizontalSuccess, openSnackBarSuccess } = snackbarSuccessOpen;

  const filteredArrLocAssign = Array.from(locations).filter((loc) => loc.dosimeterID1);

  const filteredArr = Array.from(locations)
    .filter(
      (loc) =>
        loc[newYear]?.[newMonth]?.ambientRaw >= 0 || loc[newYear]?.[newMonth]?.directionalRaw >= 0
    )
    .sort((a, b) =>
      // // Compare units
      // const unitComparison = a.unit.localeCompare(b.unit);
      // if (unitComparison !== 0) {
      //   return unitComparison;
      // }

      // // Compare professions
      // const professionComparison = a.job.localeCompare(b.job);
      // if (professionComparison !== 0) {
      //   return professionComparison;
      // }

      // Compare names
      a.locationName.localeCompare(b.locationName)
    );

  const handleSnackbarOpen = () => {
    setSnackbarOpen({
      openSnackBar: true,
      vertical: "top",
      horizontal: "center",
    });
  };

  const handleSnackbarClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen({
      ...snackbarOpen,
      openSnackBar: false,
    });
  };

  const handleSnackbarSuccessClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarSuccessOpen({
      ...snackbarSuccessOpen,
      openSnackBarSuccess: false,
    });
  };

  const handleSnackbarSuccessOpen = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarSuccessOpen({
      ...snackbarSuccessOpen,
      openSnackBarSuccess: true,
    });
  };

  const collArrWithCtrlRdgAndActualBg = [
    {
      Header: "name",
      accessor: "name",
      width: "35%",
      align: "left",
    },
    { Header: "location ID", accessor: "locationID", align: "center" },
    { Header: "dosimeter ID", accessor: "badgeNo", align: "center" },
    { Header: "Ambient actual dose (mSv)", accessor: "ambientActual", align: "center" },
    { Header: "Directional actual dose (mSv)", accessor: "directionalActual", align: "center" },
    { Header: "Ambient background dose (mSv)", accessor: "ambientBackground", align: "center" },
    {
      Header: "Directional background dose (mSv)",
      accessor: "directionalBackground",
      align: "center",
    },
    { Header: "Ambient net dose (mSv)", accessor: "ambientNet", align: "center" },
    { Header: "Directional net dose (mSv)", accessor: "directionalNet", align: "center" },
  ];

  const mappedRowsArrWithCtrlRdgAndActualBg = filteredArr.map((e) => ({
    name: e.locationName,
    badgeNo: e[doseCode],
    locationID: e[locationID],
    ambientActual:
      e[newYear]?.[newMonth]?.ambientRaw === 0 ? `0.00` : e[newYear]?.[newMonth]?.ambientRaw,

    directionalActual:
      e[newYear]?.[newMonth]?.directionalRaw === 0
        ? `0.00`
        : e[newYear]?.[newMonth]?.directionalRaw,

    ambientBackground:
      e[newYear]?.[newMonth]?.ambientBackground === 0
        ? `0.00`
        : e[newYear]?.[newMonth]?.ambientBackground,

    directionalBackground:
      e[newYear]?.[newMonth]?.directionalBackground === 0
        ? `0.00`
        : e[newYear]?.[newMonth]?.directionalBackground,

    ambientNet:
      e[newYear]?.[newMonth]?.ambientNet === 0 ? `0.00` : e[newYear]?.[newMonth]?.ambientNet,
    directionalNet:
      e[newYear]?.[newMonth]?.directionalNet === 0
        ? `0.00`
        : e[newYear]?.[newMonth]?.directionalNet,
  }));

  useEffect(() => {
    if (filteredArrLocAssign.length === 0 && newMonth.length !== 0) {
      handleSnackbarOpen();
    }
  }, [filteredArrLocAssign.length === 0, newMonth.length !== 0]);

  useEffect(() => {
    if (filteredArrLocAssign.length > 0 && newMonth.length !== 0) {
      handleSnackbarClose();
    }
  }, [filteredArrLocAssign.length > 0, newMonth.length !== 0]);

  useEffect(() => {
    if (filteredArr.length > 0 && filteredArrLocAssign.length > 0 && newMonth?.length !== 0) {
      handleSnackbarSuccessClose();
    }
  }, [filteredArr.length > 0, filteredArrLocAssign.length > 0 && newMonth]);

  useEffect(() => {
    if (filteredArr.length === 0 && filteredArrLocAssign.length > 0 && newMonth?.length !== 0) {
      handleSnackbarSuccessOpen();
    }
  }, [filteredArr.length > 0, filteredArrLocAssign.length > 0, newMonth]);

  const [openDialog, setOpenDialog] = useState(false);

  const pdfClicked = () => {
    setOpenDialog(true);
  };

  const closeDialog = () => {
    setOpenDialog(false);
  };

  Font.register({
    family: "Oswald",
    src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
  });

  const handleDateChange = (date) => {
    if (date) {
      const { $M, $y } = date;
      const monthMap = {
        0: "January",
        1: "February",
        2: "March",
        3: "April",
        4: "May",
        5: "June",
        6: "July",
        7: "August",
        8: "September",
        9: "October",
        10: "November",
        11: "December",
      };

      setNewMonth(monthMap[$M]);

      setNewYear($y);
    }
  };

  return (
    <DashboardLayout>
      <OnlineStatusSnackbar />

      <Snackbar
        key={vertical + horizontal}
        open={openSnackBar}
        autoHideDuration={6000}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert severity="error" onClose={handleSnackbarClose} sx={{ width: "100%" }}>
          No locations assigned yet. Please assign some locations in Locations Assignment tab.
        </Alert>
      </Snackbar>

      <Snackbar
        key={verticalSuccess + horizontalSuccess}
        open={openSnackBarSuccess}
        autoHideDuration={6000}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert severity="warning" onClose={handleSnackbarSuccessClose} sx={{ width: "100%" }}>
          The dosimeter readings for {newMonth} {newYear} have not yet been analyzed. Please
          navigate to the Dosimeter Analysis tab to complete the analysis.
        </Alert>
      </Snackbar>

      <PDFViewerDialog
        openDialog={openDialog}
        closeDialog={closeDialog}
        Transition={Transition}
        newMonth={newMonth}
        newYear={newYear}
        filteredArr={filteredArr}
        locationID={locationID}
        dosimeterID={doseCode}
        // filteredUsersDept={users[0]?.dept}
        filteredUsersHosp={locations[0]?.hosp}
      />

      <DashboardNavbar />

      <MDBox py={3}>
        <Card>
          <Grid container spacing={0}>
            <Grid item xs={12} md={6} lg={6}>
              <MDBox pt={4} px={3} sx={{ pb: { xs: 0, sm: 0, md: 3, lg: 3 } }}>
                <MDBox>
                  <MDBox mb={2}>
                    <MDTypography variant="h6">
                      Please select month and year of results
                    </MDTypography>
                  </MDBox>
                  <MDBox mb={2}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Month and Year"
                        views={["month", "year"]}
                        onChange={handleDateChange}
                      />
                    </LocalizationProvider>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Grid>
          </Grid>
        </Card>
      </MDBox>

      {filteredArr.length > 0 &&
        newMonth?.length > 0 &&
        filteredArr.some((e) => e?.[newYear]?.[newMonth]?.ambientNet) && (
          <MDBox pt={6} pb={3}>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Card>
                  <MDBox
                    mx={2}
                    mt={-3}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                  >
                    <MDTypography variant="h6" color="white" mb={2}>
                      Analysis results of {newMonth} {newYear}
                    </MDTypography>
                    <MDTypography variant="subtitle1" color="white" mb={1}>
                      Ambient dose control value :{" "}
                      {filteredArr[0][newYear]?.[newMonth]?.ambientControl} mSv
                    </MDTypography>
                    <MDTypography variant="subtitle1" color="white" mb={2}>
                      Directional dose control value :{" "}
                      {filteredArr[0][newYear]?.[newMonth]?.directionalControl} mSv
                    </MDTypography>
                    <MDBox display="flex" alignItems="center">
                      <MDButton onClick={pdfClicked} variant="contained" color="success">
                        Generate PDF
                      </MDButton>
                    </MDBox>
                  </MDBox>
                  <MDBox pt={3}>
                    <DataTable
                      table={{
                        columns: collArrWithCtrlRdgAndActualBg,
                        rows: mappedRowsArrWithCtrlRdgAndActualBg,
                      }}
                      entriesPerPage
                      isSorted
                      showTotalEntries
                      noEndBorder
                    />
                  </MDBox>
                </Card>
              </Grid>
            </Grid>
          </MDBox>
        )}
      <Footer />
    </DashboardLayout>
  );
}

export default LocationsAnalysisResults;
