import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
// import { useEffect, useState } from "react";
// import { collection, onSnapshot, orderBy, query, where } from "firebase/firestore";
// import Skeleton from "@mui/material/Skeleton";
// import MDButton from "components/MDButton";
import { useDispatch } from "react-redux";
// import { Button } from "react-bootstrap";
// import MDButton from "components/MDButton";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import { IconButton } from "@mui/material";
import FaceIcon from "@mui/icons-material/Face";
import Face3Icon from "@mui/icons-material/Face3";
// import AccountCircleIcon from "@mui/icons-material/AccountCircle";
// import ManIcon from "@mui/icons-material/Man";
// import WomanIcon from "@mui/icons-material/Woman";
import { useContext } from "react";
import { UserContext } from "context/UserContext";
import { openDialogView } from "../../../slices/deleteSlice";
import { idDelete, nameDelete } from "../../../slices/idDeleteSlice";

// import { db, auth } from "../../../firebase-config";

export default function data() {
  const { users } = useContext(UserContext);
  const dispatch = useDispatch();

  // const Author = ({ image, name, email }) => (
  //   <MDBox display="flex" alignItems="center" lineHeight={1}>
  //     {/*
  //       <Skeleton variant="circular" width={37} height={37} /> */}

  //     <MDAvatar src={image} name={name} size="sm" />

  //     <MDBox ml={2} lineHeight={1}>
  //       {/* <Skeleton width={250} height={12} /> */}

  //       <MDTypography display="block" variant="button" fontWeight="medium">
  //         {name}
  //       </MDTypography>

  //       {/* <Skeleton width={200} height={12} margin-top={20} /> */}

  //       <MDTypography variant="caption">{email}</MDTypography>
  //     </MDBox>
  //   </MDBox>
  // );
  // function Author({ image, name, email }) {
  //   return (
  //     <MDBox display="flex" alignItems="center" lineHeight={1}>
  //       <MDAvatar src={image} name={name} size="sm" />
  //       <MDBox ml={2} lineHeight={1}>
  //         <MDTypography display="block" variant="button" fontWeight="medium">
  //           {name}
  //         </MDTypography>
  //         <MDTypography variant="caption">{email}</MDTypography>
  //       </MDBox>
  //     </MDBox>
  //   );
  // }

  // const Job = ({ title, description }) => (
  //   <MDBox lineHeight={1} textAlign="left">
  //     {/* <Skeleton width={90} height={15} margin-top={20} /> */}

  //     <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
  //       {title}
  //     </MDTypography>

  //     {/* <Skeleton width={70} height={15} margin-top={20} /> */}

  //     <MDTypography variant="caption">{description}</MDTypography>
  //   </MDBox>
  // );

  function NextCheckUp({ checkUpDate, checkDiff }) {
    return (
      <MDBox>
        {(() => {
          // if (loading) return <Skeleton width={90} height={15} />;

          if (checkDiff > 0)
            return (
              <MDTypography variant="caption" color="primary" fontWeight="medium">
                {checkUpDate}
              </MDTypography>
            );

          return (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {checkUpDate}
            </MDTypography>
          );
        })()}
      </MDBox>
    );
  }

  // const IcNum = ({ icNum }) => (
  //   <MDBox>
  //     {/* <Skeleton width={90} height={15} /> */}

  //     <MDTypography variant="caption" color="text" fontWeight="medium">
  //       {icNum}
  //     </MDTypography>
  //   </MDBox>
  // );

  function Employed({ emplDate }) {
    return (
      <MDBox>
        {/* <Skeleton width={90} height={15} /> */}

        <MDTypography variant="caption" color="text" fontWeight="medium">
          {emplDate}
        </MDTypography>
      </MDBox>
    );
  }

  function Status({ active }) {
    return (
      <MDBox ml={-1}>
        {(() => {
          // if (loading) return <Skeleton variant="rectangle" width={60} height={15} />;

          if (active)
            return <MDBadge badgeContent="active" color="success" variant="gradient" size="sm" />;

          return <MDBadge badgeContent="inactive" color="error" variant="gradient" size="sm" />;
        })()}
      </MDBox>
    );
  }

  // const deleteUser = async (id) => {
  //   const userDoc = doc(db, "users", id);
  //   deleteDoc(userDoc);
  // };

  const collArr = [
    { Header: "view", accessor: "view", align: "center" },
    {
      Header: "name",
      accessor: "name",
      width: "45%",
      align: "left",
    },
    { Header: "ic number", accessor: "ic_number", align: "center" },
    { Header: "nanodot ring", accessor: "nanodot", align: "center" },
    { Header: "profession", accessor: "function", align: "center" },
    { Header: "grade", accessor: "grade", align: "center" },
    { Header: "status", accessor: "status", align: "center" },
    { Header: "health screening no", accessor: "healthScrNo", align: "center" },
    { Header: "next medCheckUp", accessor: "next_check_up", align: "center" },
  ];

  const mappedRowsArr = users.map((e) => ({
    key: e.id,
    view: (
      <IconButton
        color="inherit"
        size="small"
        onClick={() => {
          dispatch(openDialogView());
          dispatch(idDelete(e.id));
          dispatch(nameDelete(e.name));
        }}
      >
        {e.sex === "Female" ? <Face3Icon fontSize="small" /> : <FaceIcon fontSize="small" />}
      </IconButton>
    ),
    name: e.name,
    next_check_up: (
      <NextCheckUp
        checkDiff={new Date() - new Date(e.nextCheckUp.seconds * 1000)}
        checkUpDate={`${new Date(e.nextCheckUp.seconds * 1000).getDate()}/${
          new Date(e.nextCheckUp.seconds * 1000).getMonth() + 1
        }/${new Date(e.nextCheckUp.seconds * 1000).getFullYear()}`}
      />
    ),
    healthScrNo: e.healthScrNo,
    nanodot: (() => {
      if (e?.useNanodot2) {
        return (
          <>
            <QrCodeScannerIcon />
            <QrCodeScannerIcon sx={{ ml: 0.5 }} />
          </>
        );
      }

      if (e?.useNanodot1) {
        return <QrCodeScannerIcon />;
      }

      return null;
    })(),
    ic_number: e.icNum,
    function: e.job,
    grade: e.grade,
    status: <Status active={e.active} />,
    employed: (
      <Employed
        emplDate={`${new Date(e.emplDate.seconds * 1000).getDate()}/${
          new Date(e.emplDate.seconds * 1000).getMonth() + 1
        }/${new Date(e.emplDate.seconds * 1000).getFullYear()}`}
      />
    ),
    // action: (
    //   <MDButton
    //     key={e.id}
    //     size="small"
    //     variant="outlined"
    //     color="primary"
    //     onClick={() => {
    //       dispatch(openDialog());
    //       dispatch(idDelete(e.id));
    //       dispatch(nameDelete(e.name));
    //     }}
    //   >
    //     Edit
    //   </MDButton>
    // ),
  }));

  return {
    columns: collArr,

    rows: mappedRowsArr,
  };
}
