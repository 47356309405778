import { useContext } from "react";
import { UserContext } from "context/UserContext";
import colors from "assets/theme/base/colors";

export default function maximumHp10Data(year) {
  const { users } = useContext(UserContext);

  const jan = Math.max(
    ...users.filter((user) => user[year]?.January?.hp10).map((user) => user[year]?.January?.hp10)
  );
  const feb = Math.max(
    ...users.filter((user) => user[year]?.February?.hp10).map((user) => user[year]?.February?.hp10)
  );
  const mac = Math.max(
    ...users.filter((user) => user[year]?.March?.hp10).map((user) => user[year]?.March?.hp10)
  );
  const apr = Math.max(
    ...users.filter((user) => user[year]?.April?.hp10).map((user) => user[year]?.April?.hp10)
  );
  const may = Math.max(
    ...users.filter((user) => user[year]?.May?.hp10).map((user) => user[year]?.May?.hp10)
  );
  const jun = Math.max(
    ...users.filter((user) => user[year]?.June?.hp10).map((user) => user[year]?.June?.hp10)
  );
  const jul = Math.max(
    ...users.filter((user) => user[year]?.July?.hp10).map((user) => user[year]?.July?.hp10)
  );
  const aug = Math.max(
    ...users.filter((user) => user[year]?.August?.hp10).map((user) => user[year]?.August?.hp10)
  );
  const sep = Math.max(
    ...users
      .filter((user) => user[year]?.September?.hp10)
      .map((user) => user[year]?.September?.hp10)
  );
  const oct = Math.max(
    ...users.filter((user) => user[year]?.October?.hp10).map((user) => user[year]?.October?.hp10)
  );
  const nov = Math.max(
    ...users.filter((user) => user[year]?.November?.hp10).map((user) => user[year]?.November?.hp10)
  );
  const dec = Math.max(
    ...users.filter((user) => user[year]?.December?.hp10).map((user) => user[year]?.December?.hp10)
  );

  const janC =
    users.filter((user) => user[year]?.January?.hp10).length > 0
      ? Math.max(
          ...users
            .filter((user) => user[year]?.January?.hp10)
            .map((user) => user[year]?.January?.hp10 || 0)
        )
      : null;

  const febC =
    users.filter((user) => user[year]?.February?.hp10).length > 0
      ? Math.max(
          ...users
            .filter((user) => user[year]?.February?.hp10 || user[year]?.January?.hp10)
            .map((user) => (user[year]?.February?.hp10 || 0) + (user[year]?.January?.hp10 || 0))
        )
      : null;

  const macC =
    users.filter((user) => user[year]?.March?.hp10).length > 0
      ? Math.max(
          ...users
            .filter(
              (user) =>
                user[year]?.March?.hp10 || user[year]?.February?.hp10 || user[year]?.January?.hp10
            )
            .map(
              (user) =>
                (user[year]?.March?.hp10 || 0) +
                (user[year]?.February?.hp10 || 0) +
                (user[year]?.January?.hp10 || 0)
            )
        )
      : null;

  const aprC =
    users.filter((user) => user[year]?.April?.hp10).length > 0
      ? Math.max(
          ...users
            .filter(
              (user) =>
                user[year]?.April?.hp10 ||
                user[year]?.March?.hp10 ||
                user[year]?.February?.hp10 ||
                user[year]?.January?.hp10
            )
            .map(
              (user) =>
                (user[year]?.April?.hp10 || 0) +
                (user[year]?.March?.hp10 || 0) +
                (user[year]?.February?.hp10 || 0) +
                (user[year]?.January?.hp10 || 0)
            )
        )
      : null;

  const mayC =
    users.filter((user) => user[year]?.May?.hp10).length > 0
      ? Math.max(
          ...users
            .filter(
              (user) =>
                user[year]?.May?.hp10 ||
                user[year]?.April?.hp10 ||
                user[year]?.March?.hp10 ||
                user[year]?.February?.hp10 ||
                user[year]?.January?.hp10
            )
            .map(
              (user) =>
                (user[year]?.May?.hp10 || 0) +
                (user[year]?.April?.hp10 || 0) +
                (user[year]?.March?.hp10 || 0) +
                (user[year]?.February?.hp10 || 0) +
                (user[year]?.January?.hp10 || 0)
            )
        )
      : null;

  const junC =
    users.filter((user) => user[year]?.June?.hp10).length > 0
      ? Math.max(
          ...users
            .filter(
              (user) =>
                user[year]?.June?.hp10 ||
                user[year]?.May?.hp10 ||
                user[year]?.April?.hp10 ||
                user[year]?.March?.hp10 ||
                user[year]?.February?.hp10 ||
                user[year]?.January?.hp10
            )
            .map(
              (user) =>
                (user[year]?.June?.hp10 || 0) +
                (user[year]?.May?.hp10 || 0) +
                (user[year]?.April?.hp10 || 0) +
                (user[year]?.March?.hp10 || 0) +
                (user[year]?.February?.hp10 || 0) +
                (user[year]?.January?.hp10 || 0)
            )
            .filter((number) => !Number.isNaN(number))
        )
      : null;

  const julC =
    users.filter((user) => user[year]?.July?.hp10).length > 0
      ? Math.max(
          ...users
            .filter(
              (user) =>
                user[year]?.July?.hp10 ||
                user[year]?.June?.hp10 ||
                user[year]?.May?.hp10 ||
                user[year]?.April?.hp10 ||
                user[year]?.March?.hp10 ||
                user[year]?.February?.hp10 ||
                user[year]?.January?.hp10
            )
            .map(
              (user) =>
                (user[year]?.July?.hp10 || 0) +
                (user[year]?.June?.hp10 || 0) +
                (user[year]?.May?.hp10 || 0) +
                (user[year]?.April?.hp10 || 0) +
                (user[year]?.March?.hp10 || 0) +
                (user[year]?.February?.hp10 || 0) +
                (user[year]?.January?.hp10 || 0)
            )
        )
      : null;

  const augC =
    users.filter((user) => user[year]?.August?.hp10).length > 0
      ? Math.max(
          ...users
            .filter(
              (user) =>
                user[year]?.August?.hp10 ||
                user[year]?.July?.hp10 ||
                user[year]?.June?.hp10 ||
                user[year]?.May?.hp10 ||
                user[year]?.April?.hp10 ||
                user[year]?.March?.hp10 ||
                user[year]?.February?.hp10 ||
                user[year]?.January?.hp10
            )
            .map(
              (user) =>
                (user[year]?.August?.hp10 || 0) +
                (user[year]?.July?.hp10 || 0) +
                (user[year]?.June?.hp10 || 0) +
                (user[year]?.May?.hp10 || 0) +
                (user[year]?.April?.hp10 || 0) +
                (user[year]?.March?.hp10 || 0) +
                (user[year]?.February?.hp10 || 0) +
                (user[year]?.January?.hp10 || 0)
            )
        )
      : null;

  const sepC =
    users.filter((user) => user[year]?.September?.hp10).length > 0
      ? Math.max(
          ...users
            .filter(
              (user) =>
                user[year]?.September?.hp10 ||
                user[year]?.August?.hp10 ||
                user[year]?.July?.hp10 ||
                user[year]?.June?.hp10 ||
                user[year]?.May?.hp10 ||
                user[year]?.April?.hp10 ||
                user[year]?.March?.hp10 ||
                user[year]?.February?.hp10 ||
                user[year]?.January?.hp10
            )
            .map(
              (user) =>
                (user[year]?.September?.hp10 || 0) +
                (user[year]?.August?.hp10 || 0) +
                (user[year]?.July?.hp10 || 0) +
                (user[year]?.June?.hp10 || 0) +
                (user[year]?.May?.hp10 || 0) +
                (user[year]?.April?.hp10 || 0) +
                (user[year]?.March?.hp10 || 0) +
                (user[year]?.February?.hp10 || 0) +
                (user[year]?.January?.hp10 || 0)
            )
        )
      : null;

  const octC =
    users.filter((user) => user[year]?.October?.hp10).length > 0
      ? Math.max(
          ...users
            .filter(
              (user) =>
                user[year]?.October?.hp10 ||
                user[year]?.September?.hp10 ||
                user[year]?.August?.hp10 ||
                user[year]?.July?.hp10 ||
                user[year]?.June?.hp10 ||
                user[year]?.May?.hp10 ||
                user[year]?.April?.hp10 ||
                user[year]?.March?.hp10 ||
                user[year]?.February?.hp10 ||
                user[year]?.January?.hp10
            )
            .map(
              (user) =>
                (user[year]?.October?.hp10 || 0) +
                (user[year]?.September?.hp10 || 0) +
                (user[year]?.August?.hp10 || 0) +
                (user[year]?.July?.hp10 || 0) +
                (user[year]?.June?.hp10 || 0) +
                (user[year]?.May?.hp10 || 0) +
                (user[year]?.April?.hp10 || 0) +
                (user[year]?.March?.hp10 || 0) +
                (user[year]?.February?.hp10 || 0) +
                (user[year]?.January?.hp10 || 0)
            )
        )
      : null;

  const novC =
    users.filter((user) => user[year]?.November?.hp10).length > 0
      ? Math.max(
          ...users
            .filter(
              (user) =>
                user[year]?.November?.hp10 ||
                user[year]?.October?.hp10 ||
                user[year]?.September?.hp10 ||
                user[year]?.August?.hp10 ||
                user[year]?.July?.hp10 ||
                user[year]?.June?.hp10 ||
                user[year]?.May?.hp10 ||
                user[year]?.April?.hp10 ||
                user[year]?.March?.hp10 ||
                user[year]?.February?.hp10 ||
                user[year]?.January?.hp10
            )
            .map(
              (user) =>
                (user[year]?.November?.hp10 || 0) +
                (user[year]?.October?.hp10 || 0) +
                (user[year]?.September?.hp10 || 0) +
                (user[year]?.August?.hp10 || 0) +
                (user[year]?.July?.hp10 || 0) +
                (user[year]?.June?.hp10 || 0) +
                (user[year]?.May?.hp10 || 0) +
                (user[year]?.April?.hp10 || 0) +
                (user[year]?.March?.hp10 || 0) +
                (user[year]?.February?.hp10 || 0) +
                (user[year]?.January?.hp10 || 0)
            )
        )
      : null;

  const decC =
    users.filter((user) => user[year]?.December?.hp10).length > 0
      ? Math.max(
          ...users
            .filter(
              (user) =>
                user[year]?.December?.hp10 ||
                user[year]?.November?.hp10 ||
                user[year]?.October?.hp10 ||
                user[year]?.September?.hp10 ||
                user[year]?.August?.hp10 ||
                user[year]?.July?.hp10 ||
                user[year]?.June?.hp10 ||
                user[year]?.May?.hp10 ||
                user[year]?.April?.hp10 ||
                user[year]?.March?.hp10 ||
                user[year]?.February?.hp10 ||
                user[year]?.January?.hp10
            )
            .map(
              (user) =>
                (user[year]?.December?.hp10 || 0) +
                (user[year]?.November?.hp10 || 0) +
                (user[year]?.October?.hp10 || 0) +
                (user[year]?.September?.hp10 || 0) +
                (user[year]?.August?.hp10 || 0) +
                (user[year]?.July?.hp10 || 0) +
                (user[year]?.June?.hp10 || 0) +
                (user[year]?.May?.hp10 || 0) +
                (user[year]?.April?.hp10 || 0) +
                (user[year]?.March?.hp10 || 0) +
                (user[year]?.February?.hp10 || 0) +
                (user[year]?.January?.hp10 || 0)
            )
        )
      : null;

  const chartDataMaxHp10 = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    datasets: [
      {
        label: "Cumulative Hp10 dose (mSv)",
        data: [janC, febC, macC, aprC, mayC, junC, julC, augC, sepC, octC, novC, decC],
        borderColor: colors.primary.main,
        pointBackgroundColor: colors.primary.main,
      },
      {
        label: "Monthly Hp10 dose (mSv)",
        data: [jan, feb, mac, apr, may, jun, jul, aug, sep, oct, nov, dec],
        borderColor: colors.info.main, // original : "rgba(255, 255, 255, .8)"
        pointBackgroundColor: colors.info.main,
      },
    ],
  };

  return chartDataMaxHp10;
}
