import { Dialog, AppBar, Toolbar, IconButton } from "@mui/material";
import { Document, Page, Text, View, Image, PDFViewer } from "@react-pdf/renderer";
import CloseIcon from "@mui/icons-material/Close";
import styles from "../styles";
import logoKKM from "../../../../assets/images/logoKKM.png";
import logoRadDB from "../../../../assets/images/raddb.png";

export default function PDFViewerDialog({
  openDialog,
  closeDialog,
  newMonth,
  newYear,
  Transition,
  filteredArr,
  filteredUsersDept,
  filteredUsersHosp,
}) {
  return (
    <Dialog open={openDialog} onClose={closeDialog} TransitionComponent={Transition} fullScreen>
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton edge="end" color="error" onClick={closeDialog}>
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <PDFViewer height="100%" width="100%">
        <Document>
          <Page orientation="landscape" style={styles.body} size="A4">
            <Text fixed style={styles.header}>
              Powered by RadDB @ raddosedb.com
            </Text>
            <View style={styles.headerContainer}>
              <View style={styles.logoContainer}>
                <Image style={styles.logoRadDB} src={logoRadDB} />
                <Image style={styles.logoKKM} src={logoKKM} />
              </View>
              <View style={styles.textContainer}>
                <Text style={styles.title}>Personnel Dose Monitoring Analysis Monthly Report</Text>
                <Text style={styles.title}>Nuclear Medicine Department</Text>
                <Text style={styles.title}>Kuala Lumpur Hospital (HKL)</Text>
                <Text style={styles.subtitle}>
                  Dosimeter analysis results of {newMonth} {newYear}
                </Text>
              </View>
            </View>

            {/* <Text style={styles.text}>
                Control value of Hp10 : {filteredArr[0]?.[newYear]?.[newMonth]?.ctrlValueHp10} mSv
              </Text>
              <Text style={styles.text}>
                Control value of Hp0.07 : {filteredArr[0]?.[newYear]?.[newMonth]?.ctrlValueHp007}{" "}
                mSv
              </Text> */}
            <View style={styles.tableContainer}>
              <View fixed style={styles.container}>
                <Text style={styles.no}>No.</Text>
                <Text style={styles.unit}>Unit</Text>
                <Text style={styles.profession}>Profession</Text>
                <Text style={styles.grade}>Grade</Text>
                <Text style={styles.name}>Name</Text>
                <Text style={styles.dose}>Hp10 (mSv)</Text>
                <Text style={styles.dose}>Hp0.07 (mSv)</Text>
                <Text style={styles.dose}>Cumu. Hp10 (mSv)</Text>
                <Text style={styles.dose}>Cumu. Hp0.07 (mSv)</Text>
                {/* <Text style={styles.signature}>Signature</Text>
                <Text style={styles.signature}>Date</Text> */}
              </View>
              {filteredArr.map((e, i) => (
                <View style={styles.row} key={e.id}>
                  <Text style={styles.noRow}>{i + 1}</Text>
                  <Text style={styles.unitRow}>{e.unit}</Text>
                  <Text style={styles.professionRow}>{e.job}</Text>
                  <Text style={styles.gradeRow}>{e.grade}</Text>
                  <Text style={styles.nameRow}>{e.name}</Text>
                  <Text style={styles.doseRow}>
                    {e[newYear]?.[newMonth]?.hp10 === 0
                      ? `0.00`
                      : e[newYear]?.[newMonth]?.hp10?.toFixed(2)}
                  </Text>
                  <Text style={styles.doseRow}>
                    {e[newYear]?.[newMonth]?.hp007 === 0
                      ? `0.00`
                      : e[newYear]?.[newMonth]?.hp007?.toFixed(2)}
                  </Text>
                  <Text style={styles.doseRow}>
                    {(() => {
                      const january = e[newYear].January?.hp10;
                      const february = e[newYear].February?.hp10;
                      const march = e[newYear].March?.hp10;
                      const april = e[newYear].April?.hp10;
                      const may = e[newYear].May?.hp10;
                      const june = e[newYear].June?.hp10;
                      const july = e[newYear].July?.hp10;
                      const august = e[newYear].August?.hp10;
                      const september = e[newYear].September?.hp10;
                      const october = e[newYear].October?.hp10;
                      const november = e[newYear].November?.hp10;
                      const december = e[newYear].December?.hp10;

                      if (newMonth === "February") {
                        return (january || 0) + (february || 0) === 0
                          ? "0.00"
                          : ((january || 0) + (february || 0)).toFixed(2);
                      }

                      if (newMonth === "March") {
                        return (january || 0) + (february || 0) + (march || 0) === 0
                          ? "0.00"
                          : ((january || 0) + (february || 0) + (march || 0)).toFixed(2);
                      }

                      if (newMonth === "April") {
                        return (january || 0) + (february || 0) + (march || 0) + (april || 0) === 0
                          ? "0.00"
                          : (
                              (january || 0) +
                              (february || 0) +
                              (march || 0) +
                              (april || 0)
                            ).toFixed(2);
                      }

                      if (newMonth === "May") {
                        return (january || 0) +
                          (february || 0) +
                          (march || 0) +
                          (april || 0) +
                          (may || 0) ===
                          0
                          ? "0.00"
                          : (
                              (january || 0) +
                              (february || 0) +
                              (march || 0) +
                              (april || 0) +
                              (may || 0)
                            ).toFixed(2);
                      }

                      if (newMonth === "June") {
                        return (january || 0) +
                          (february || 0) +
                          (march || 0) +
                          (april || 0) +
                          (may || 0) +
                          (june || 0) ===
                          0
                          ? "0.00"
                          : (
                              (january || 0) +
                              (february || 0) +
                              (march || 0) +
                              (april || 0) +
                              (may || 0) +
                              (june || 0)
                            ).toFixed(2);
                      }

                      if (newMonth === "July") {
                        return (january || 0) +
                          (february || 0) +
                          (march || 0) +
                          (april || 0) +
                          (may || 0) +
                          (june || 0) +
                          (july || 0) ===
                          0
                          ? "0.00"
                          : (
                              (january || 0) +
                              (february || 0) +
                              (march || 0) +
                              (april || 0) +
                              (may || 0) +
                              (june || 0) +
                              (july || 0)
                            ).toFixed(2);
                      }

                      if (newMonth === "August") {
                        return (january || 0) +
                          (february || 0) +
                          (march || 0) +
                          (april || 0) +
                          (may || 0) +
                          (june || 0) +
                          (july || 0) +
                          (august || 0) ===
                          0
                          ? "0.00"
                          : (
                              (january || 0) +
                              (february || 0) +
                              (march || 0) +
                              (april || 0) +
                              (may || 0) +
                              (june || 0) +
                              (july || 0) +
                              (august || 0)
                            ).toFixed(2);
                      }

                      if (newMonth === "September") {
                        return (january || 0) +
                          (february || 0) +
                          (march || 0) +
                          (april || 0) +
                          (may || 0) +
                          (june || 0) +
                          (july || 0) +
                          (august || 0) +
                          (september || 0) ===
                          0
                          ? "0.00"
                          : (
                              (january || 0) +
                              (february || 0) +
                              (march || 0) +
                              (april || 0) +
                              (may || 0) +
                              (june || 0) +
                              (july || 0) +
                              (august || 0) +
                              (september || 0)
                            ).toFixed(2);
                      }

                      if (newMonth === "October") {
                        return (january || 0) +
                          (february || 0) +
                          (march || 0) +
                          (april || 0) +
                          (may || 0) +
                          (june || 0) +
                          (july || 0) +
                          (august || 0) +
                          (september || 0) +
                          (october || 0) ===
                          0
                          ? "0.00"
                          : (
                              (january || 0) +
                              (february || 0) +
                              (march || 0) +
                              (april || 0) +
                              (may || 0) +
                              (june || 0) +
                              (july || 0) +
                              (august || 0) +
                              (september || 0) +
                              (october || 0)
                            ).toFixed(2);
                      }

                      if (newMonth === "November") {
                        return (january || 0) +
                          (february || 0) +
                          (march || 0) +
                          (april || 0) +
                          (may || 0) +
                          (june || 0) +
                          (july || 0) +
                          (august || 0) +
                          (september || 0) +
                          (october || 0) +
                          (november || 0) ===
                          0
                          ? "0.00"
                          : (
                              (january || 0) +
                              (february || 0) +
                              (march || 0) +
                              (april || 0) +
                              (may || 0) +
                              (june || 0) +
                              (july || 0) +
                              (august || 0) +
                              (september || 0) +
                              (october || 0) +
                              (november || 0)
                            ).toFixed(2);
                      }

                      if (newMonth === "December") {
                        return (january || 0) +
                          (february || 0) +
                          (march || 0) +
                          (april || 0) +
                          (may || 0) +
                          (june || 0) +
                          (july || 0) +
                          (august || 0) +
                          (september || 0) +
                          (october || 0) +
                          (november || 0) +
                          (december || 0) ===
                          0
                          ? "0.00"
                          : (
                              (january || 0) +
                              (february || 0) +
                              (march || 0) +
                              (april || 0) +
                              (may || 0) +
                              (june || 0) +
                              (july || 0) +
                              (august || 0) +
                              (september || 0) +
                              (october || 0) +
                              (november || 0) +
                              (december || 0)
                            ).toFixed(2);
                      }

                      return (january || 0) === 0 ? "0.00" : (january || 0).toFixed(2);
                    })()}
                  </Text>
                  <Text style={styles.doseRow}>
                    {(() => {
                      const january = e[newYear].January?.hp007;
                      const february = e[newYear].February?.hp007;
                      const march = e[newYear].March?.hp007;
                      const april = e[newYear].April?.hp007;
                      const may = e[newYear].May?.hp007;
                      const june = e[newYear].June?.hp007;
                      const july = e[newYear].July?.hp007;
                      const august = e[newYear].August?.hp007;
                      const september = e[newYear].September?.hp007;
                      const october = e[newYear].October?.hp007;
                      const november = e[newYear].November?.hp007;
                      const december = e[newYear].December?.hp007;

                      if (newMonth === "February") {
                        return (january || 0) + (february || 0) === 0
                          ? "0.00"
                          : ((january || 0) + (february || 0)).toFixed(2);
                      }

                      if (newMonth === "March") {
                        return (january || 0) + (february || 0) + (march || 0) === 0
                          ? "0.00"
                          : ((january || 0) + (february || 0) + (march || 0)).toFixed(2);
                      }

                      if (newMonth === "April") {
                        return (january || 0) + (february || 0) + (march || 0) + (april || 0) === 0
                          ? "0.00"
                          : (
                              (january || 0) +
                              (february || 0) +
                              (march || 0) +
                              (april || 0)
                            ).toFixed(2);
                      }

                      if (newMonth === "May") {
                        return (january || 0) +
                          (february || 0) +
                          (march || 0) +
                          (april || 0) +
                          (may || 0) ===
                          0
                          ? "0.00"
                          : (
                              (january || 0) +
                              (february || 0) +
                              (march || 0) +
                              (april || 0) +
                              (may || 0)
                            ).toFixed(2);
                      }

                      if (newMonth === "June") {
                        return (january || 0) +
                          (february || 0) +
                          (march || 0) +
                          (april || 0) +
                          (may || 0) +
                          (june || 0) ===
                          0
                          ? "0.00"
                          : (
                              (january || 0) +
                              (february || 0) +
                              (march || 0) +
                              (april || 0) +
                              (may || 0) +
                              (june || 0)
                            ).toFixed(2);
                      }

                      if (newMonth === "July") {
                        return (january || 0) +
                          (february || 0) +
                          (march || 0) +
                          (april || 0) +
                          (may || 0) +
                          (june || 0) +
                          (july || 0) ===
                          0
                          ? "0.00"
                          : (
                              (january || 0) +
                              (february || 0) +
                              (march || 0) +
                              (april || 0) +
                              (may || 0) +
                              (june || 0) +
                              (july || 0)
                            ).toFixed(2);
                      }

                      if (newMonth === "August") {
                        return (january || 0) +
                          (february || 0) +
                          (march || 0) +
                          (april || 0) +
                          (may || 0) +
                          (june || 0) +
                          (july || 0) +
                          (august || 0) ===
                          0
                          ? "0.00"
                          : (
                              (january || 0) +
                              (february || 0) +
                              (march || 0) +
                              (april || 0) +
                              (may || 0) +
                              (june || 0) +
                              (july || 0) +
                              (august || 0)
                            ).toFixed(2);
                      }

                      if (newMonth === "September") {
                        return (january || 0) +
                          (february || 0) +
                          (march || 0) +
                          (april || 0) +
                          (may || 0) +
                          (june || 0) +
                          (july || 0) +
                          (august || 0) +
                          (september || 0) ===
                          0
                          ? "0.00"
                          : (
                              (january || 0) +
                              (february || 0) +
                              (march || 0) +
                              (april || 0) +
                              (may || 0) +
                              (june || 0) +
                              (july || 0) +
                              (august || 0) +
                              (september || 0)
                            ).toFixed(2);
                      }

                      if (newMonth === "October") {
                        return (january || 0) +
                          (february || 0) +
                          (march || 0) +
                          (april || 0) +
                          (may || 0) +
                          (june || 0) +
                          (july || 0) +
                          (august || 0) +
                          (september || 0) +
                          (october || 0) ===
                          0
                          ? "0.00"
                          : (
                              (january || 0) +
                              (february || 0) +
                              (march || 0) +
                              (april || 0) +
                              (may || 0) +
                              (june || 0) +
                              (july || 0) +
                              (august || 0) +
                              (september || 0) +
                              (october || 0)
                            ).toFixed(2);
                      }

                      if (newMonth === "November") {
                        return (january || 0) +
                          (february || 0) +
                          (march || 0) +
                          (april || 0) +
                          (may || 0) +
                          (june || 0) +
                          (july || 0) +
                          (august || 0) +
                          (september || 0) +
                          (october || 0) +
                          (november || 0) ===
                          0
                          ? "0.00"
                          : (
                              (january || 0) +
                              (february || 0) +
                              (march || 0) +
                              (april || 0) +
                              (may || 0) +
                              (june || 0) +
                              (july || 0) +
                              (august || 0) +
                              (september || 0) +
                              (october || 0) +
                              (november || 0)
                            ).toFixed(2);
                      }

                      if (newMonth === "December") {
                        return (january || 0) +
                          (february || 0) +
                          (march || 0) +
                          (april || 0) +
                          (may || 0) +
                          (june || 0) +
                          (july || 0) +
                          (august || 0) +
                          (september || 0) +
                          (october || 0) +
                          (november || 0) +
                          (december || 0) ===
                          0
                          ? "0.00"
                          : (
                              (january || 0) +
                              (february || 0) +
                              (march || 0) +
                              (april || 0) +
                              (may || 0) +
                              (june || 0) +
                              (july || 0) +
                              (august || 0) +
                              (september || 0) +
                              (october || 0) +
                              (november || 0) +
                              (december || 0)
                            ).toFixed(2);
                      }

                      return (january || 0) === 0 ? "0.00" : (january || 0).toFixed(2);
                    })()}
                  </Text>
                  {/* <Text style={styles.signatureRow}>{e?.signature}</Text>
                  <Text style={styles.signatureRow}>{e?.date}</Text> */}
                </View>
              ))}
            </View>

            <Text style={{ marginTop: 7 }}>Notes :</Text>
            <Text style={{ marginTop: 5 }}>
              *Deep dose equivalent is defined as the proposed dose equivalent at a depth of 10 mm
              in ICRU tissue and is denoted by Hp(10).
            </Text>
            <Text style={{ marginTop: 5 }}>
              In ICRP 60, deep dose equivalent is referred to as individual dose equivalent,
              penetrating.
            </Text>
            <Text style={{ marginTop: 5 }}>
              **Shallow dose equivalent is defined as the proposed dose equivalent at a depth of
              0.07 mm in ICRU tissue and is denoted by Hp(0.07).
            </Text>
            <Text style={{ marginTop: 5 }}>
              In ICRP 60, shallow dose equivalent is referred to as individual dose equivalent,
              superficial.
            </Text>
            <Text style={{ marginTop: 5 }}>
              1. The yearly limited dose for a worker&apos;s whole body exposure is 20 millisievert
              (20 mSv) according to the Atomic Energy Lincensing Act 1984, (Basic Safety Radiation
              Protection) Regulations 2010.
            </Text>
            <Text style={{ marginTop: 5 }}>
              2. The operational limitation for an individual is set at 6 mSv per year, ensuring
              that the yearly limited dose of 20 mSv is not exceeded.
            </Text>
            <Text style={{ marginTop: 5 }}>
              3. In cases where the limit of 20 mSv is exceeded, the licensee must report this to
              the Ministry of Health (MOH).
            </Text>
            <Text style={{ marginTop: 5 }}>
              4. The measurement uncertainty for OSL dosimeters is ***29.6%.
            </Text>
            <Text style={{ marginTop: 5 }}>
              5. The results are applicable only to the tested OSL dosimeters.
            </Text>
            <Text style={{ marginTop: 5 }}>
              6. The minimum detection limit is 0.05 mSv (any value less than 0.05 mSv will be
              considered as 0.00 mSv).
            </Text>
            <Text style={{ marginTop: 5 }}>
              Copyright of this certificate is owned by the issuing laboratory and may be reproduced
              other than in full except with the prior written approval of the Head of the issuing
              laboratory.
            </Text>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
                marginTop: 20,
                marginLeft: 25,
              }}
            >
              <Text>Prepared by :</Text>
              {/* <Text>Approved by :</Text> */}
            </View>
            <Text
              style={styles.pageNumber}
              render={({ pageNumber, totalPages }) => `Page ${pageNumber} of ${totalPages}`}
              fixed
            />
            <Text style={styles.footerLeft} fixed>
              {filteredUsersDept}, {filteredUsersHosp}
            </Text>
            <Text style={styles.footerRight} fixed>
              {newMonth} {newYear}
            </Text>
          </Page>
        </Document>
      </PDFViewer>
    </Dialog>
  );
}
