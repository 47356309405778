import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  id: "",
  name: "",
  circularProgressStatus: false,
  analysedUsers: [],
};

/* eslint-disable no-param-reassign */
const idDeleteSlice = createSlice({
  name: "idDelete",
  initialState,
  reducers: {
    idDelete: (state, action) => {
      state.id = action.payload;
    },
    nameDelete: (state, action) => {
      state.name = action.payload;
    },
    circularProgressOpen: (state) => {
      state.circularProgressStatus = true;
    },
    circularProgressClose: (state) => {
      state.circularProgressStatus = false;
    },
    analysed: (state, action) => ({
      analysedUsers: [action.payload, ...state.analysedUsers],
    }),
    resetAnalysed: (state) => {
      state.analysedUsers = [];
    },
    deleteAnalysed: (state, action) => {
      state.analysedUsers = state.analysedUsers.filter((item) => item.id !== action.payload);
    },
  },
});
/* eslint-enable no-param-reassign */

export const {
  idDelete,
  nameDelete,
  circularProgressOpen,
  circularProgressClose,
  analysed,
  resetAnalysed,
  deleteAnalysed,
} = idDeleteSlice.actions;

export default idDeleteSlice.reducer;
