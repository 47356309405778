import { StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  body: {
    paddingTop: 35, // initial: 35
    paddingBottom: 65,
    paddingHorizontal: 35,
    flexDirection: "column",
    fontSize: 9,
    lineHeight: 1.5,
  },
  title: {
    fontSize: 15,
    textAlign: "center",
    // fontFamily: "Arial",
  },
  author: {
    fontSize: 12,
    textAlign: "center",
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 13,
    textAlign: "center",
    marginTop: 20,
    // fontFamily: "Oswald",
  },
  summary: {
    fontSize: 13,
    margin: 12,
    textAlign: "left",
    // fontFamily: "Oswald",
  },
  text: {
    marginTop: 12,
    marginBottom: 0,
    fontSize: 10,
    textAlign: "justify",
    // fontFamily: "Times-Roman",
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  headerRight: {
    fontSize: 8,
    // marginBottom: 20,
    textAlign: "right",
    color: "grey",
  },
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    padding: 20,
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
    marginTop: 10,
    marginLeft: 50,
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  footerLeft: {
    position: "absolute",
    fontSize: 9,
    bottom: 30,
    left: 30,
    right: 0,
    textAlign: "left",
    color: "grey",
  },
  footerRight: {
    position: "absolute",
    fontSize: 9,
    bottom: 30,
    left: 0,
    right: 30,
    textAlign: "right",
    color: "grey",
  },
  container: {
    flexDirection: "row",
    borderBottomColor: "#bff0fd",
    backgroundColor: "#bff0fd",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 50,
    textAlign: "center",
    fontStyle: "bold",
    flexGrow: 1,
    paddingTop: 2,
  },
  no: {
    width: "4%",
    borderRightColor: "#90e5fc",
    borderRightWidth: 1,
    height: "100%",
    paddingTop: 17,
  },
  noRow: {
    width: "4%",
    textAlign: "center",
    borderRightColor: "#90e5fc",
    borderRightWidth: 1,
    paddingTop: 6.5,
    height: "100%",
  },
  unit: {
    width: "10%",
    borderRightColor: "#90e5fc",
    borderRightWidth: 1,
    height: "100%",
    paddingTop: 17,
  },
  unitRow: {
    width: "10%",
    textAlign: "center",
    borderRightColor: "#90e5fc",
    borderRightWidth: 1,
    paddingTop: 6.5,
    height: "100%",
  },
  profession: {
    width: "25%",
    borderRightColor: "#90e5fc",
    borderRightWidth: 1,
    height: "100%",
    paddingTop: 17,
  },
  professionRow: {
    width: "25%",
    textAlign: "center",
    borderRightColor: "#90e5fc",
    borderRightWidth: 1,
    paddingTop: 6.5,
    height: "100%",
  },
  grade: {
    width: "8%",
    borderRightColor: "#90e5fc",
    borderRightWidth: 1,
    height: "100%",
    paddingTop: 17,
  },
  gradeRow: {
    width: "8%",
    textAlign: "center",
    borderRightColor: "#90e5fc",
    borderRightWidth: 1,
    paddingTop: 6.5,
    height: "100%",
  },
  name: {
    width: "40%",
    borderRightColor: "#90e5fc",
    borderRightWidth: 1,
    height: "100%",
    paddingTop: 17,
  },
  nameRow: {
    width: "40%",
    textAlign: "left",
    borderRightColor: "#90e5fc",
    borderRightWidth: 1,
    paddingLeft: 8,
    paddingTop: 6.5,
    height: "100%",
  },
  ringNo: {
    width: "7%",
    borderRightColor: "#90e5fc",
    borderRightWidth: 1,
    height: "100%",
    paddingTop: 6,
  },
  ringNoRow: {
    width: "7%",
    borderRightColor: "#90e5fc",
    borderRightWidth: 1,
    textAlign: "center",
    paddingTop: 6.5,
    height: "100%",
  },
  dose: {
    width: "7%",
    borderRightColor: "#90e5fc",
    borderRightWidth: 1,
    height: "100%",
    paddingTop: 6,
  },
  doseRow: {
    width: "7%",
    borderRightColor: "#90e5fc",
    borderRightWidth: 1,
    textAlign: "center",
    paddingTop: 6.5,
    height: "100%",
  },
  signature: {
    width: "7%",
    borderRightColor: "#90e5fc",
    borderRightWidth: 1,
    paddingTop: 17,
    height: "100%",
  },
  signatureRow: {
    width: "7%",
    borderRightColor: "#90e5fc",
    borderRightWidth: 1,
    paddingTop: 6.5,
    height: "100%",
  },
  icNumber: {
    width: "17%",
    borderRightColor: "#90e5fc",
    borderRightWidth: 1,
    paddingTop: 4,
  },
  dosimeterCode: {
    width: "17%",
    borderRightColor: "#90e5fc",
    borderRightWidth: 1,
    paddingTop: 4,
  },
  row: {
    flexDirection: "row",
    borderBottomColor: "#bff0fd",
    borderBottomWidth: 1,
    borderRightStyle: "solid",
    alignItems: "center",
    textAlign: "center",
    height: 24,
    fontStyle: "bold",
  },
  icNumberRow: {
    width: "17%",
    borderRightColor: "#90e5fc",
    borderRightWidth: 1,
    textAlign: "center",
    paddingTop: 4,
  },
  dosimeterCodeRow: {
    width: "17%",
    borderRightColor: "#90e5fc",
    borderRightWidth: 1,
    textAlign: "center",
    paddingTop: 4,
  },
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 5,
    borderWidth: 1,
    borderColor: "#bff0fd",
  },
  logoKKM: {
    height: 60,
    marginLeft: "auto",
    marginRight: "auto",
    // marginBottom: 10,
  },
  logoRadDB: {
    height: 60,
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: 5,
  },
  logoContainer: {
    display: "flex",
    flexDirection: "column",
  },
  graphContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 15,
    borderWidth: 1,
    borderColor: "#bff0fd",
  },
});

export default styles;
