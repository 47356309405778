import { configureStore } from "@reduxjs/toolkit";
import deleteReducer from "./slices/deleteSlice";
import idDeleteReducer from "./slices/idDeleteSlice";
import extremityDosimeterReducer from "./slices/extremityDosimeterSlice";

export const store = configureStore({
  reducer: {
    delete: deleteReducer,
    idDelete: idDeleteReducer,
    extremityDosimeter: extremityDosimeterReducer,
  },
});
