import Tooltip from "@mui/material/Tooltip";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDProgress from "components/MDProgress";
import { Skeleton } from "@mui/material";

// Images
import kl from "assets/images/flags/kl.svg";
import putrajaya from "assets/images/flags/putrajaya.svg";
import penang from "assets/images/flags/penang.svg";
import johor from "assets/images/flags/johor.svg";
import sarawak from "assets/images/flags/sarawak.svg";
import sabah from "assets/images/flags/sabah.svg";
// import logoXD from "assets/images/small-logos/logo-xd.svg";
// import logoAtlassian from "assets/images/small-logos/logo-atlassian.svg";
// import logoSlack from "assets/images/small-logos/logo-slack.svg";
// import logoSpotify from "assets/images/small-logos/logo-spotify.svg";
// import logoJira from "assets/images/small-logos/logo-jira.svg";
// import logoInvesion from "assets/images/small-logos/logo-invision.svg";
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import { UserContext } from "context/UserContext";
import { useContext } from "react";

export default function data() {
  const { unfilteredUsers } = useContext(UserContext);
  const avatars = (members) =>
    members.map(([image, name]) => (
      <Tooltip key={name} title={name} placeholder="bottom">
        <MDAvatar
          src={image}
          alt="name"
          size="xs"
          sx={{
            border: ({ borders: { borderWidth }, palette: { white } }) =>
              `${borderWidth[2]} solid ${white.main}`,
            cursor: "pointer",
            position: "relative",

            "&:not(:first-of-type)": {
              ml: -1.25,
            },

            "&:hover, &:focus": {
              zIndex: "10",
            },
          }}
        />
      </Tooltip>
    ));

  function Hospitals({ image, name }) {
    return (
      <MDBox display="flex" alignItems="center" lineHeight={1}>
        <MDAvatar variant="square" src={image} name={name} size="sm" />
        <MDTypography variant="button" ml={1} lineHeight={1}>
          {name}
        </MDTypography>
      </MDBox>
    );
  }

  function SkeletonComp() {
    return <Skeleton variant="text" height={22} width={55} />;
  }

  return {
    columns: [
      { Header: "Hospitals", accessor: "companies", width: "45%", align: "left" },
      // { Header: "members", accessor: "members", width: "10%", align: "left" },
      { Header: "Rad workers (active / total)", accessor: "workers", align: "center" },
      // { Header: "completion", accessor: "completion", align: "center" },
    ],

    rows: [
      {
        companies: <Hospitals image={kl} name="Hospital Kuala Lumpur" />,
        // members: (
        //   <MDBox display="flex" py={1}>
        //     {avatars([
        //       [team1, "Ryan Tompson"],
        //       [team2, "Romina Hadid"],
        //       [team3, "Alexander Smith"],
        //       [team4, "Jessica Doe"],
        //     ])}
        //   </MDBox>
        // ),
        workers:
          unfilteredUsers.length === 0 ? (
            <SkeletonComp />
          ) : (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {unfilteredUsers.filter((e) => e.hosp === "Hospital Kuala Lumpur" && e.active).length}{" "}
              / {unfilteredUsers.filter((e) => e.hosp === "Hospital Kuala Lumpur").length}
            </MDTypography>
          ),
        completion: (
          <MDBox width="8rem" textAlign="left">
            <MDProgress value={60} color="info" variant="gradient" label={false} />
          </MDBox>
        ),
      },
      {
        companies: <Hospitals image={putrajaya} name="Institut Kanser Negara" />,
        members: (
          <MDBox display="flex" py={1}>
            {avatars([
              [team2, "Romina Hadid"],
              [team4, "Jessica Doe"],
            ])}
          </MDBox>
        ),
        workers:
          unfilteredUsers.length === 0 ? (
            <SkeletonComp />
          ) : (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {
                unfilteredUsers.filter((e) => e.hosp === "Institut Kanser Negara" && e.active)
                  .length
              }{" "}
              / {unfilteredUsers.filter((e) => e.hosp === "Institut Kanser Negara").length}
            </MDTypography>
          ),
        completion: (
          <MDBox width="8rem" textAlign="left">
            <MDProgress value={10} color="info" variant="gradient" label={false} />
          </MDBox>
        ),
      },
      {
        companies: <Hospitals image={penang} name="Hospital Pulau Pinang" />,
        members: (
          <MDBox display="flex" py={1}>
            {avatars([
              [team1, "Ryan Tompson"],
              [team3, "Alexander Smith"],
            ])}
          </MDBox>
        ),
        workers:
          unfilteredUsers.length === 0 ? (
            <SkeletonComp />
          ) : (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {unfilteredUsers.filter((e) => e.hosp === "Hospital Pulau Pinang" && e.active).length}{" "}
              / {unfilteredUsers.filter((e) => e.hosp === "Hospital Pulau Pinang").length}
            </MDTypography>
          ),
        completion: (
          <MDBox width="8rem" textAlign="left">
            <MDProgress value={100} color="success" variant="gradient" label={false} />
          </MDBox>
        ),
      },
      {
        companies: <Hospitals image={johor} name="Hospital Sultanah Aminah" />,
        members: (
          <MDBox display="flex" py={1}>
            {avatars([
              [team4, "Jessica Doe"],
              [team3, "Alexander Smith"],
              [team2, "Romina Hadid"],
              [team1, "Ryan Tompson"],
            ])}
          </MDBox>
        ),
        workers:
          unfilteredUsers.length === 0 ? (
            <SkeletonComp />
          ) : (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {
                unfilteredUsers.filter((e) => e.hosp === "Hospital Sultanah Aminah" && e.active)
                  .length
              }{" "}
              / {unfilteredUsers.filter((e) => e.hosp === "Hospital Sultanah Aminah").length}
            </MDTypography>
          ),
        completion: (
          <MDBox width="8rem" textAlign="left">
            <MDProgress value={100} color="success" variant="gradient" label={false} />
          </MDBox>
        ),
      },
      {
        companies: <Hospitals image={sarawak} name="Hospital Umum Sarawak" />,
        members: (
          <MDBox display="flex" py={1}>
            {avatars([[team4, "Jessica Doe"]])}
          </MDBox>
        ),
        workers:
          unfilteredUsers.length === 0 ? (
            <SkeletonComp />
          ) : (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {unfilteredUsers.filter((e) => e.hosp === "Hospital Umum Sarawak" && e.active).length}{" "}
              / {unfilteredUsers.filter((e) => e.hosp === "Hospital Umum Sarawak").length}
            </MDTypography>
          ),
        completion: (
          <MDBox width="8rem" textAlign="left">
            <MDProgress value={25} color="info" variant="gradient" label={false} />
          </MDBox>
        ),
      },
      {
        companies: <Hospitals image={sabah} name="Hospital Wanita & Kanak-Kanak Sabah" />,
        members: (
          <MDBox display="flex" py={1}>
            {avatars([
              [team1, "Ryan Tompson"],
              [team4, "Jessica Doe"],
            ])}
          </MDBox>
        ),
        workers:
          unfilteredUsers.length === 0 ? (
            <SkeletonComp />
          ) : (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {
                unfilteredUsers.filter(
                  (e) => e.hosp === "Hospital Wanita & Kanak-Kanak Sabah" && e.active
                ).length
              }{" "}
              /{" "}
              {
                unfilteredUsers.filter((e) => e.hosp === "Hospital Wanita & Kanak-Kanak Sabah")
                  .length
              }
            </MDTypography>
          ),
        completion: (
          <MDBox width="8rem" textAlign="left">
            <MDProgress value={40} color="info" variant="gradient" label={false} />
          </MDBox>
        ),
      },
    ],
  };
}
