import { useMemo, useState } from "react";
import dayjs from "dayjs";
import { styled } from "@mui/material/styles";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  Dialog,
  Card,
  Box,
  Stack,
  Chip,
  IconButton,
  Tooltip,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  ButtonGroup,
  Grid,
} from "@mui/material";
import MDTypography from "components/MDTypography";
import BackHandIcon from "@mui/icons-material/BackHand";
import AccessibilityIcon from "@mui/icons-material/Accessibility";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import EditIcon from "@mui/icons-material/Edit";
import HomeIcon from "@mui/icons-material/Home";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import WorkIcon from "@mui/icons-material/Work";
import MonitorHeartIcon from "@mui/icons-material/MonitorHeart";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import MDBox from "components/MDBox";
import ReactWhatsapp from "react-whatsapp";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import individualHp10 from "../data/individualHp10";
import individualExtremities from "../data/individualExtremities";

const MAccordion = styled((props) => <Accordion disableGutters elevation={0} square {...props} />)(
  ({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    "&:not(:last-child)": {
      borderTop: 0,
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
  })
);

const MAccordionSummary = styled((props) => (
  <AccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper": {
    color: "inherit",
  },
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
    color: "inherit",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const MAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
}));

export default function DialogProfileView({
  openDialogView,
  active,
  handleClickEditDialog,
  handleDelete,
  dispatchCloseDialogView,
  newName,
  newGrade,
  icNum,
  newUnit,
  newDept,
  job,
  birthday,
  address,
  tel,
  email,
  employDate,
  checkUpDate,
  useNanodot1,
  useNanodot2,
  healthScrNo,
}) {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const [newYear, setNewYear] = useState(new Date().getFullYear());

  const handleDateChange = (newValue) => {
    setNewYear(newValue?.$y);
  };

  function handleResetYear() {
    setNewYear(new Date().getFullYear());
  }

  function handleResetExpanded() {
    setExpanded(false);
  }

  const data = individualHp10(icNum, newYear);
  const dataExtremities = individualExtremities(icNum, newYear);

  const memoizedData = useMemo(() => data, [icNum, newYear]);
  const memoizedDataExtremities = useMemo(() => dataExtremities, [icNum, newYear]);

  const age = (new Date().getTime() - birthday) / (3600 * 24 * 365 * 1000);

  let year = Math.floor(age);

  let month = Math.round((age - year) * 12);

  if (month === 12) {
    month = 0;
    year += 1;
  }

  const [groupButtonState, setGroupButtonState] = useState("personnel");

  return (
    <Dialog open={openDialogView} maxWidth="sm" fullWidth>
      <Card>
        <Box sx={{ p: 2, display: "flex", justifyContent: "space-between" }}>
          <Stack spacing={0.5}>
            <MDTypography variant="h5">{newName}</MDTypography>
            <Stack direction="row" divider={<Divider orientation="vertical" flexItem />}>
              <MDTypography variant="body2" color="text">
                {icNum}
              </MDTypography>
              <MDTypography variant="body2" color="text">
                {year} y {month} m
              </MDTypography>
              {/* <MDBox sx={{ height: "10px" }} color="text">
                {sex === "Male" ? <FaceIcon /> : <Face3Icon />}
              </MDBox> */}
              {(() => {
                if (useNanodot2) {
                  return (
                    <MDBox sx={{ height: "10px" }} color="text">
                      <QrCodeScannerIcon /> <QrCodeScannerIcon />
                    </MDBox>
                  );
                }

                if (useNanodot1) {
                  return (
                    <MDBox sx={{ height: "10px" }} color="text">
                      <QrCodeScannerIcon />
                    </MDBox>
                  );
                }

                return null;
              })()}
            </Stack>
            <MDTypography variant="body2" color="text">
              {job} {newGrade}
            </MDTypography>
            <MDTypography variant="body2" color="text">
              Unit {newUnit}, {newDept}
            </MDTypography>
          </Stack>
          <Stack>
            <Tooltip title="Close" placement="right-start" enterTouchDelay={0}>
              <IconButton
                color="error"
                onClick={() => {
                  dispatchCloseDialogView();
                  handleResetYear();
                  handleResetExpanded();
                  setGroupButtonState("personnel");
                }}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Edit profile" placement="right-start" enterTouchDelay={0}>
              <IconButton color="inherit" onClick={handleClickEditDialog}>
                <EditIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Stack>
        </Box>
        <MAccordion
          TransitionProps={{ unmountOnExit: true }}
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <MAccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <MDTypography variant="button" color="text">
              {expanded ? "Hide Details" : "Show Details"}
            </MDTypography>
          </MAccordionSummary>
          <MAccordionDetails>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <Stack spacing={2} direction="row">
                  <MDBox>
                    <Tooltip title="Contact no." placement="left-start" enterTouchDelay={0}>
                      <PhoneIcon color="text" />
                    </Tooltip>
                  </MDBox>
                  {tel ? (
                    <ReactWhatsapp
                      style={{ backgroundColor: "transparent", border: "none", marginTop: -8 }}
                      number={`+6${tel}`}
                    >
                      <MDTypography variant="body2" color="text">
                        {tel}
                      </MDTypography>
                    </ReactWhatsapp>
                  ) : (
                    <MDTypography variant="body2" color="text">
                      N/A
                    </MDTypography>
                  )}
                </Stack>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Stack spacing={2} direction="row">
                  <MDBox>
                    <Tooltip
                      title="Health screening no."
                      placement="left-start"
                      enterTouchDelay={0}
                    >
                      <MenuBookIcon color="text" />
                    </Tooltip>
                  </MDBox>
                  {healthScrNo ? (
                    <MDTypography variant="body2" color="text">
                      {healthScrNo}
                    </MDTypography>
                  ) : (
                    <MDTypography variant="body2" color="text">
                      N/A
                    </MDTypography>
                  )}
                </Stack>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={12} sm={6}>
                <Stack spacing={2} direction="row">
                  <MDBox>
                    <Tooltip title="Report duty" placement="left-start" enterTouchDelay={0}>
                      <WorkIcon color="text" />
                    </Tooltip>
                  </MDBox>
                  <MDTypography variant="body2" color="text">
                    {new Date(employDate).toString().split(" ")[2]}{" "}
                    {new Date(employDate).toString().split(" ")[1]}{" "}
                    {new Date(employDate).toString().split(" ")[3]}
                  </MDTypography>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Stack spacing={2} direction="row">
                  <MDBox>
                    <Tooltip title="Next checkup" placement="left-start" enterTouchDelay={0}>
                      <MonitorHeartIcon color="text" />
                    </Tooltip>
                  </MDBox>
                  <MDTypography
                    variant="body2"
                    color={new Date().getTime() > new Date(checkUpDate) ? "error" : "text"}
                  >
                    {new Date(checkUpDate).toString().split(" ")[2]}{" "}
                    {new Date(checkUpDate).toString().split(" ")[1]}{" "}
                    {new Date(checkUpDate).toString().split(" ")[3]}
                  </MDTypography>
                </Stack>
              </Grid>
            </Grid>

            <Stack spacing={2} direction="row">
              <MDBox>
                <Tooltip title="Email" placement="left-start" enterTouchDelay={0}>
                  <EmailIcon color="text" />
                </Tooltip>
              </MDBox>
              <MDTypography variant="body2" color="text">
                {email || "N/A"}
              </MDTypography>
            </Stack>
            <Stack spacing={2} direction="row">
              <MDBox>
                <Tooltip title="Address" placement="left-start" enterTouchDelay={0}>
                  <HomeIcon color="text" />
                </Tooltip>
              </MDBox>
              <MDTypography variant="body2" color="text">
                {address || "N/A"}
              </MDTypography>
            </Stack>
          </MAccordionDetails>
        </MAccordion>
        <ReportsLineChart
          chartOnly
          chart={groupButtonState === "personnel" ? memoizedData : memoizedDataExtremities}
          color="dark"
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            paddingBottom: 2,
            paddingRight: 2,
            paddingLeft: 2,
          }}
        >
          <ButtonGroup variant="outlined" color="text">
            <Tooltip title="Show personnel dose">
              <IconButton
                color={groupButtonState === "personnel" ? "info" : "light"}
                size="small"
                onClick={() => setGroupButtonState("personnel")}
              >
                <AccessibilityIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Show extremities dose">
              <IconButton
                color={groupButtonState === "extremities" ? "info" : "light"}
                size="small"
                onClick={() => setGroupButtonState("extremities")}
              >
                <BackHandIcon />
              </IconButton>
            </Tooltip>
          </ButtonGroup>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Year"
              views={["year"]}
              onChange={handleDateChange}
              defaultValue={dayjs()}
              sx={{ width: { lg: "20%", md: "20%", sm: "30%", xs: "40%" } }}
            />
          </LocalizationProvider>
        </Box>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ px: 2, py: 1, bgcolor: "background.default" }}
        >
          {active ? (
            <Chip
              label="Active"
              color="success"
              deleteIcon={<DoneIcon />}
              onDelete={handleDelete}
            />
          ) : (
            <Chip
              label="Inactive"
              color="error"
              deleteIcon={<CloseIcon />}
              onDelete={handleDelete}
            />
          )}
        </Stack>
      </Card>
    </Dialog>
  );
}
