import { Dialog, Card, Grid, DialogActions } from "@mui/material";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
// import { UserAuth } from "context/AuthContext";

export default function DialogProfileEdit({
  openDialogEdit,
  submit,
  height,
  handleClickConfirmationOpen,
  handleCloseEditDialog,
  locationID1,
  locationID2,
  dosimeterID1,
  dosimeterID2,
  handleDosimeterID1Change,
  handleDosimeterID2Change,
  errorDosID1,
  errorDosID2,
  locationName,
  handleLocationNameChange,
}) {
  // const { user } = UserAuth();
  return (
    <Dialog
      open={openDialogEdit}
      maxWidth="md"
      fullWidth
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Card component="form" role="form" onSubmit={submit}>
        <Grid container spacing={0}>
          <Grid item xs={12} md={6} lg={6}>
            <MDBox pt={4} px={3}>
              <MDBox>
                <MDBox mb={2}>
                  <MDInput
                    type="text"
                    inputProps={{ readOnly: true }}
                    value={locationID1}
                    style={height}
                    label="Location ID 1"
                    fullWidth
                  />
                </MDBox>

                <MDBox mb={2}>
                  <MDInput
                    id="name"
                    required
                    style={height}
                    helperText={errorDosID1}
                    error={!!errorDosID1}
                    value={dosimeterID1}
                    inputProps={{ maxLength: 11 }}
                    type="text"
                    label="Dosimeter ID 1"
                    fullWidth
                    onChange={handleDosimeterID1Change}
                  >
                    <MDTypography />
                  </MDInput>
                </MDBox>
              </MDBox>
            </MDBox>
          </Grid>

          <Grid item xs={12} md={6} lg={6}>
            <MDBox px={3} sx={{ pt: { xs: 0, sm: 0, md: 4, lg: 4 } }}>
              <MDBox>
                <MDBox mb={2}>
                  <MDInput
                    inputProps={{ readOnly: true }}
                    style={height}
                    value={locationID2}
                    type="text"
                    label="Location ID 2"
                    fullWidth
                  />
                </MDBox>
                <MDBox mb={2}>
                  <MDInput
                    required
                    style={height}
                    error={!!errorDosID2}
                    helperText={errorDosID2}
                    value={dosimeterID2}
                    inputProps={{ maxLength: 11 }}
                    type="text"
                    label="Dosimeter ID 2"
                    fullWidth
                    onChange={handleDosimeterID2Change}
                  />
                </MDBox>
              </MDBox>
            </MDBox>
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <MDBox pb={1} px={3}>
              <MDBox>
                <MDBox mb={2}>
                  <MDInput
                    required
                    value={locationName}
                    style={height}
                    type="text"
                    label="Location name"
                    fullWidth
                    onChange={handleLocationNameChange}
                  />
                </MDBox>
              </MDBox>
            </MDBox>
          </Grid>
        </Grid>
        <DialogActions style={{ display: "flex", justifyContent: "space-between" }}>
          <MDBox>
            <MDButton variant="outlined" color="error" onClick={handleClickConfirmationOpen}>
              Delete
            </MDButton>
          </MDBox>
          <MDBox display="flex" flexDirection={{ xs: "column", sm: "row" }}>
            <MDButton
              sx={{ mb: { xs: 1, sm: 0 } }}
              variant="outlined"
              color="warning"
              onClick={handleCloseEditDialog}
            >
              Cancel
            </MDButton>
            <MDButton
              sx={{ ml: { xs: 0, sm: "8px" } }}
              type="submit"
              variant="outlined"
              color="success"
              onClick={submit}
              disabled={
                errorDosID1 !== "" ||
                errorDosID2 !== "" ||
                dosimeterID1 === "" ||
                dosimeterID2 === "" ||
                locationName === ""
              }
            >
              Update
            </MDButton>
          </MDBox>
        </DialogActions>
      </Card>
    </Dialog>
  );
}
