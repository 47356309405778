// @mui material components
import {
  Grid,
  Card,
  // FormControl,
  // InputLabel,
  // Select,
  // MenuItem,
  Snackbar,
  Alert,
  // Checkbox,
  Slide,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

// import DeleteIcon from "@mui/icons-material/DeleteForever";

import DataTable from "examples/Tables/DataTable";

import * as React from "react";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
// import DataTable from "examples/Tables/DataTable";

import { useEffect, useState, useContext } from "react";

// import MDInput from "components/MDInput";

import { Font } from "@react-pdf/renderer";
// import dayjs from "dayjs";
import { UserContext } from "context/UserContext";
import PDFViewerDialog from "./components/PDFViewer";
import OnlineStatusSnackbar from "../../onlineStatusSnackbar/onlineStatusSnackbar";

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

function AnalysisResults() {
  useEffect(() => {
    document.title = "Analysis Results - RadDB";
  }, []);
  const { users } = useContext(UserContext);
  // const [hideICColumn, setHideICColumn] = useState(false);
  // const [test, setTest] = useState(null);
  // console.log(test);

  const [newYear, setNewYear] = useState("");

  // const setNewYearFunc = useCallback(
  //   (e) => {
  //     setNewYear(e.target.value);
  //   },
  //   [newYear]
  // );

  const [newMonth, setNewMonth] = useState("");

  // const setNewMonthFunc = (e) => {
  //   setNewMonth(e.target.value);
  //   // console.log("newMonth useCallback in action!");
  // };

  // const setNewMonthFunc = useCallback(
  //   (e) => {
  //     setNewMonth(e.target.value);
  //   },
  //   [newMonth]
  // );

  const [snackbarOpen, setSnackbarOpen] = useState({
    openSnackBar: false,
    vertical: "top",
    horizontal: "center",
  });

  const [snackbarSuccessOpen, setSnackbarSuccessOpen] = useState({
    openSnackBarSuccess: true,
    vertical: "top",
    horizontal: "center",
  });

  const { vertical, horizontal, openSnackBar } = snackbarOpen;

  const { verticalSuccess, horizontalSuccess, openSnackBarSuccess } = snackbarSuccessOpen;

  // useEffect(() => {
  //   if (document.getElementById("month-select")) {
  //     document.getElementById("month-select").focus();
  //   }
  // }, [newYear.length === 4]);

  const filteredArr = Array.from(users)
    .filter((user) => user[newYear]?.[newMonth]?.doseCode)
    .sort((a, b) => {
      // Compare units
      const unitComparison = a.unit.localeCompare(b.unit);
      if (unitComparison !== 0) {
        return unitComparison;
      }

      // Compare professions
      const professionComparison = a.job.localeCompare(b.job);
      if (professionComparison !== 0) {
        return professionComparison;
      }

      // Compare names
      return a.name.localeCompare(b.name);
    });

  const handleSnackbarOpen = () => {
    setSnackbarOpen({
      openSnackBar: true,
      vertical: "top",
      horizontal: "center",
    });
  };

  const handleSnackbarClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen({
      ...snackbarOpen,
      openSnackBar: false,
    });
  };

  const handleSnackbarSuccessClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarSuccessOpen({
      ...snackbarSuccessOpen,
      openSnackBarSuccess: false,
    });
  };

  const handleSnackbarSuccessOpen = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarSuccessOpen({
      ...snackbarSuccessOpen,
      openSnackBarSuccess: true,
    });
  };

  const collArrWithCtrlRdgAndActualBg = [
    {
      Header: "name",
      accessor: "name",
      width: "35%",
      align: "left",
    },
    { Header: "ic number", accessor: "icNum", align: "center" },
    { Header: "dosimeter code", accessor: "badgeNo", align: "center" },
    { Header: "Actual Hp10 (mSv)", accessor: "actualHp10", align: "center" },
    { Header: "Actual Hp0.07 (mSv)", accessor: "actualHp007", align: "center" },
    { Header: "Background Hp10 (mSv)", accessor: "bgHp10", align: "center" },
    { Header: "Background Hp0.07 (mSv)", accessor: "bgHp007", align: "center" },
    { Header: "Hp10 (mSv)", accessor: "hp10", align: "center" },
    { Header: "Hp0.07 (mSv)", accessor: "hp007", align: "center" },
  ];

  const mappedRowsArrWithCtrlRdgAndActualBg = filteredArr.map((e) => ({
    name: e.name,
    icNum: e.icNum,
    badgeNo: e[newYear]?.[newMonth]?.doseCode,
    actualHp10:
      e[newYear]?.[newMonth]?.rawHp10 === 0 ? `0` : e[newYear]?.[newMonth]?.rawHp10?.toFixed(2),

    actualHp007:
      e[newYear]?.[newMonth]?.rawHp007 === 0 ? `0` : e[newYear]?.[newMonth]?.rawHp007?.toFixed(2),

    bgHp10: e[newYear]?.[newMonth]?.bgHp10 === 0 ? `0` : e[newYear]?.[newMonth]?.bgHp10?.toFixed(2),

    bgHp007:
      e[newYear]?.[newMonth]?.bgHp007 === 0 ? `0` : e[newYear]?.[newMonth]?.bgHp007?.toFixed(2),

    hp10: e[newYear]?.[newMonth]?.hp10 === 0 ? `0` : e[newYear]?.[newMonth]?.hp10?.toFixed(2),
    hp007: e[newYear]?.[newMonth]?.hp007 === 0 ? `0` : e[newYear]?.[newMonth]?.hp007?.toFixed(2),
  }));

  // const resetInputYear = (e) => {
  //   e.target.value = "";
  //   setNewYear("");
  //   setNewMonth("");
  // };

  useEffect(() => {
    if (filteredArr.length === 0 && newMonth?.length !== 0) {
      handleSnackbarOpen();
    }

    if (filteredArr.length > 0 && newMonth?.length !== 0) {
      handleSnackbarClose();
    }

    if (
      filteredArr.length > 0 &&
      newMonth?.length !== 0 &&
      !filteredArr[0][newYear][newMonth].hp007
    ) {
      handleSnackbarSuccessOpen();
    }
  }, [filteredArr.length > 0, newMonth]);

  const [openDialog, setOpenDialog] = useState(false);

  const pdfClicked = () => {
    setOpenDialog(true);
  };

  const closeDialog = () => {
    setOpenDialog(false);
  };

  Font.register({
    family: "Oswald",
    src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
  });

  const handleDateChange = (date) => {
    if (date) {
      const { $M, $y } = date;
      const monthMap = {
        0: "January",
        1: "February",
        2: "March",
        3: "April",
        4: "May",
        5: "June",
        6: "July",
        7: "August",
        8: "September",
        9: "October",
        10: "November",
        11: "December",
      };

      setNewMonth(monthMap[$M]);

      setNewYear($y);
    }
  };

  return (
    <DashboardLayout>
      <OnlineStatusSnackbar />
      {filteredArr.length === 0 && newMonth?.length !== 0 && newYear?.toString().length === 4 && (
        <Snackbar
          key={vertical + horizontal}
          open={openSnackBar}
          autoHideDuration={6000}
          anchorOrigin={{ vertical, horizontal }}
        >
          <Alert severity="error" onClose={handleSnackbarClose} sx={{ width: "100%" }}>
            No users have been assigned dosimeters for the month of {newMonth} {newYear}. Please
            navigate to the Dosimeter Assignment tab to assign dosimeters to the appropriate users.
          </Alert>
        </Snackbar>
      )}

      {filteredArr.length !== 0 &&
        newMonth?.length !== 0 &&
        filteredArr[0][newYear][newMonth].hp10 === null &&
        newYear?.toString().length === 4 && (
          <Snackbar
            key={verticalSuccess + horizontalSuccess}
            open={openSnackBarSuccess}
            autoHideDuration={6000}
            anchorOrigin={{ vertical, horizontal }}
          >
            <Alert severity="warning" onClose={handleSnackbarSuccessClose} sx={{ width: "100%" }}>
              The dosimeter readings for {newMonth} {newYear} have not yet been analyzed. Please
              navigate to the Dosimeter Analysis tab to complete the analysis.
            </Alert>
          </Snackbar>
        )}

      <PDFViewerDialog
        openDialog={openDialog}
        closeDialog={closeDialog}
        Transition={Transition}
        newMonth={newMonth}
        newYear={newYear}
        filteredArr={filteredArr}
        filteredUsersDept={users[0]?.dept}
        filteredUsersHosp={users[0]?.hosp}
      />

      <DashboardNavbar />

      <MDBox py={3}>
        <Card>
          <Grid container spacing={0}>
            <Grid item xs={12} md={6} lg={6}>
              <MDBox pt={4} px={3} sx={{ pb: { xs: 0, sm: 0, md: 3, lg: 3 } }}>
                <MDBox>
                  <MDBox mb={2}>
                    <MDTypography variant="h6">
                      Please select month and year of results
                    </MDTypography>
                  </MDBox>
                  <MDBox mb={2}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Month and Year"
                        views={["month", "year"]}
                        // value={test}
                        onChange={handleDateChange}
                        // onChange={(newValue) => setTest(newValue)}
                      />
                    </LocalizationProvider>
                  </MDBox>
                  {/* <MDBox mb={2}>
                    <MDInput
                      autoFocus
                      onFocus={resetInputYear}
                      onChange={setNewYearFunc}
                      inputProps={{ maxLength: 4 }}
                      style={{ width: "100px" }}
                      placeholder="Insert year..."
                    />
                  </MDBox> */}
                </MDBox>
              </MDBox>
            </Grid>
            {/* <Grid item xs={12} md={6} lg={6}>
              <MDBox pb={3} px={3} sx={{ pt: { xs: 0, sm: 0, md: 4, lg: 4 } }}>
                <MDBox>
                  {newYear.length === 4 && (
                    <>
                      <MDBox mb={2}>
                        <MDTypography variant="h6">Month of analysis : {newMonth}</MDTypography>
                      </MDBox>
                      <MDBox mb={2}>
                        <FormControl required style={{ width: "100px" }}>
                          <InputLabel id="status-select-label">Month</InputLabel>
                          <Select
                            labelId="month-select-label"
                            id="month-select"
                            value={newMonth}
                            label="Month"
                            onChange={setNewMonthFunc}
                            style={{ height: 44 }}
                          >
                            <MenuItem value="">
                              <em>Please Select</em>
                            </MenuItem>
                            <MenuItem value="January">January</MenuItem>
                            <MenuItem value="February">February</MenuItem>
                            <MenuItem value="March">March</MenuItem>
                            <MenuItem value="April">April</MenuItem>
                            <MenuItem value="May">May</MenuItem>
                            <MenuItem value="June">June</MenuItem>
                            <MenuItem value="July">July</MenuItem>
                            <MenuItem value="August">August</MenuItem>
                            <MenuItem value="September">September</MenuItem>
                            <MenuItem value="October">October</MenuItem>
                            <MenuItem value="November">November</MenuItem>
                            <MenuItem value="December">December</MenuItem>
                          </Select>
                        </FormControl>
                      </MDBox>
                    </>
                  )}
                </MDBox>
              </MDBox>
            </Grid> */}
          </Grid>
        </Card>
      </MDBox>

      {filteredArr.length > 0 &&
        newMonth?.length > 0 &&
        filteredArr.filter((e) => e[newYear][newMonth].hp007 !== null).length > 0 && (
          <MDBox pt={6} pb={3}>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Card>
                  <MDBox
                    mx={2}
                    mt={-3}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                  >
                    <MDTypography variant="h6" color="white" mb={2}>
                      Analysis results of {newMonth} {newYear}
                    </MDTypography>
                    <MDTypography variant="subtitle1" color="white" mb={1}>
                      Hp10 control value : {filteredArr[0][newYear][newMonth].ctrlValueHp10} mSv
                    </MDTypography>
                    <MDTypography variant="subtitle1" color="white" mb={2}>
                      Hp0.07 control value : {filteredArr[0][newYear][newMonth].ctrlValueHp007} mSv
                    </MDTypography>
                    <MDBox display="flex" alignItems="center">
                      <MDButton onClick={pdfClicked} variant="contained" color="success">
                        Generate PDF
                      </MDButton>
                      {/* <Checkbox onChange={() => setHideICColumn(!hideICColumn)} />
                      <MDTypography variant="subtitle2" color="white">
                        Hide IC column in generated PDF
                      </MDTypography> */}
                    </MDBox>
                  </MDBox>
                  <MDBox pt={3}>
                    <DataTable
                      table={{
                        columns: collArrWithCtrlRdgAndActualBg,
                        rows: mappedRowsArrWithCtrlRdgAndActualBg,
                      }}
                      entriesPerPage
                      isSorted
                      showTotalEntries
                      noEndBorder
                    />
                  </MDBox>
                </Card>
              </Grid>
            </Grid>
          </MDBox>
        )}
      <Footer />
    </DashboardLayout>
  );
}

export default AnalysisResults;
