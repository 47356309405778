import * as XLSX from "xlsx";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import {
  Grid,
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  // FormControl,
  // InputLabel,
  // Select,
  // MenuItem,
  DialogActions,
  Backdrop,
  CircularProgress,
  Snackbar,
  Alert,
  IconButton,
} from "@mui/material";

import DeleteIcon from "@mui/icons-material/DeleteForever";

import { useSelector, useDispatch } from "react-redux";

import DataTable from "examples/Tables/DataTable";

import * as React from "react";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
// import DataTable from "examples/Tables/DataTable";

import { useEffect, useState, useCallback, useContext } from "react";
import { doc, writeBatch } from "firebase/firestore";

import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

import { UserContext } from "context/UserContext";
import { db } from "../../../firebase-config";
import { analysed, resetAnalysed, deleteAnalysed } from "../../../slices/idDeleteSlice";
import OnlineStatusSnackbar from "../../onlineStatusSnackbar/onlineStatusSnackbar";

function PersonnelPreAnalysis() {
  useEffect(() => {
    document.title = "Dosimeter Assignment - RadDB";
  }, []);
  const { users } = useContext(UserContext);
  const dispatch = useDispatch();
  const { analysedUsers } = useSelector((state) => state.idDelete);
  const [arrBg, setArrBg] = useState([]);
  const [openSwitchDialog, setOpenSwitchDialog] = useState(false);

  const [newYear, setNewYear] = useState("");
  const [newMonth, setNewMonth] = useState("");
  const [newIcNum, setNewIcNum] = useState("");
  const [open, setOpen] = useState(false);
  const [newDoseCode, setNewDoseCode] = useState("");
  const [circularOpen, setCircularOpen] = useState(false);
  const [duplicationDialogOpen, setDuplicationDialogOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState({
    openSnackBar: false,
    vertical: "top",
    horizontal: "center",
  });

  const { vertical, horizontal, openSnackBar } = snackbarOpen;

  const onChangeFileBackground = useCallback(
    (e) => {
      const [file] = e.target.files;
      const reader = new FileReader();

      reader.onload = (evt) => {
        const jsonObj = [];
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, { type: "binary" });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const dataXLSX = XLSX.utils.sheet_to_csv(ws, { header: 1 }).split("\n");
        // setArr(dataXLSX);

        // Source: https://stackoverflow.com/questions/59016562/parse-csv-records-in-to-an-array-of-objects-in-javascript
        const headers = dataXLSX[0].split(",");
        for (let i = 1; i < dataXLSX.length; i += 1) {
          const dutu = dataXLSX[i].split(",");
          const obj = {};
          for (let j = 0; j < dutu.length; j += 1) {
            obj[headers[j].trim()] = dutu[j].trim();
          }

          jsonObj.push(obj);
        }

        const mapped = jsonObj.filter((element) => element["Serial Number"] !== "");

        setArrBg(mapped);
      };

      reader.readAsBinaryString(file);
    },
    [arrBg]
  );

  useEffect(() => {
    if (document.getElementById("bgFile")) {
      document.getElementById("bgFile").focus();
    }
  }, [newMonth]);

  useEffect(() => {
    if (document.getElementById("month-select")) {
      document.getElementById("month-select").focus();
    }
  }, [newYear.length === 4]);

  useEffect(() => {
    setOpen(newIcNum.length === 12);
  }, [newIcNum.length === 12]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseSwitchDialog = () => {
    setOpenSwitchDialog(false);
  };

  const handleCircularOpen = () => {
    setCircularOpen(true);
  };

  const handleCircularClose = () => {
    setCircularOpen(false);
  };

  const handleSnackbarOpen = () => {
    setSnackbarOpen({
      openSnackBar: true,
      vertical: "top",
      horizontal: "center",
    });
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen({
      ...snackbarOpen,
      openSnackBar: false,
    });
  };

  const filteredUser = users.filter((user) => user.icNum === newIcNum);
  const filteredDoseCode = arrBg.filter((e) => e["Serial Number"] === newDoseCode);

  useEffect(() => {
    if (filteredDoseCode.length === 0) {
      setOpenSwitchDialog(true);
    }
  }, [filteredDoseCode.length === 0, newDoseCode.length === 11]);

  useEffect(() => {
    setOpen(!(newDoseCode.length === 11 && filteredDoseCode.length > 0));
    setDuplicationDialogOpen(
      analysedUsers.filter((user) => user.doseCode === newDoseCode).length > 0
    );

    if (
      newDoseCode.length === 11 &&
      filteredDoseCode.length > 0 &&
      analysedUsers.filter((user) => user.doseCode === newDoseCode).length === 0
    ) {
      dispatch(
        analysed({
          name: filteredUser[0]?.name,
          doseCode: newDoseCode,
          year: newYear,
          month: newMonth,
          id: filteredUser[0]?.id,
          ic: filteredUser[0]?.icNum,
          bgHp10: (parseFloat(filteredDoseCode[0]?.["Deep Dose"]) * 100) / 100,
          bgHp007: (parseFloat(filteredDoseCode[0]?.["Shallow Dose"]) * 100) / 100,
        })
      );
    }
  }, [newDoseCode.length === 11]);

  const collArr = [
    {
      accessor: "delete",
      align: "center",
      width: "1%",
    },
    {
      Header: "name",
      accessor: "name",
      width: "45%",
      align: "left",
    },
    { Header: "ic number", accessor: "icNum", align: "center" },
    { Header: "dosimeter code", accessor: "badgeNo", align: "center" },
    { Header: "Background Hp10 (mSv)", accessor: "bgHp10", align: "center" },
    { Header: "Background Hp0.07 (mSv)", accessor: "bgHp007", align: "center" },
  ];

  const mappedRowsArr = analysedUsers.map((e) => ({
    delete: (
      <IconButton
        key={e.id}
        color="error"
        size="small"
        onClick={() => {
          dispatch(deleteAnalysed(e.id));
        }}
      >
        <DeleteIcon fontSize="small" />
      </IconButton>
    ),
    name: e.name,
    icNum: e.ic,
    badgeNo: e.doseCode,
    bgHp10: e.bgHp10,
    bgHp007: e.bgHp007,
  }));

  // const resetInputYear = (e) => {
  //   e.target.value = "";
  //   setNewMonth("");
  //   setNewYear("");
  //   setArrBg([]);
  // };

  const resetInputIcNum = (e) => {
    e.target.value = "";
    setNewIcNum("");
  };

  const resetDoseCodeInput = (e) => {
    e.target.value = "";
    setNewDoseCode("");
  };

  const icDuplicateCheck = analysedUsers.filter((user) => user.ic === newIcNum);

  const submit = async (e) => {
    handleCircularOpen();
    e.preventDefault();
    const batch = writeBatch(db);
    for (let i = 0; i < analysedUsers.length; i += 1) {
      batch.update(doc(db, "users", analysedUsers[i]?.id), {
        // https://dev.to/milesbd/updating-nested-fields-in-firestore-with-dot-notation-53hp
        [`${newYear}.${newMonth}`]: {
          doseCode: analysedUsers[i]?.doseCode,
          bgHp10: parseFloat(filteredDoseCode[0]?.["Deep Dose"]),
          bgHp007: parseFloat(filteredDoseCode[0]?.["Shallow Dose"]),
          hp10: null,
          hp007: null,
        },
      });
    }

    await batch.commit().then(() => {
      handleCircularClose();
      handleSnackbarOpen();
      setNewYear("");
      setNewMonth("");
      dispatch(resetAnalysed());
    });
  };

  const handleDateChange = (date) => {
    if (date) {
      const { $M, $y } = date;
      const monthMap = {
        0: "January",
        1: "February",
        2: "March",
        3: "April",
        4: "May",
        5: "June",
        6: "July",
        7: "August",
        8: "September",
        9: "October",
        10: "November",
        11: "December",
      };

      setNewMonth(monthMap[$M]);

      setNewYear($y);
    }
  };

  return (
    <DashboardLayout>
      <OnlineStatusSnackbar />
      <Snackbar
        key={vertical + horizontal}
        open={openSnackBar}
        autoHideDuration={6000}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: "100%" }}>
          Successfully synced to server
        </Alert>
      </Snackbar>
      <div>
        <Dialog
          maxWidth="sm"
          fullWidth
          open={
            open &&
            newIcNum.length === 12 &&
            filteredUser.length === 1 &&
            icDuplicateCheck.length === 0
          }
        >
          <>
            <DialogTitle>
              {/*
                https://bobbyhadz.com/blog/javascript-cannot-read-property-of-undefined#:~:text=The%20%22Cannot%20read%20properties%20of%20undefined%22%20error%20occurs%20when%20trying,not%20present%20in%20an%20array
                */}
              Assigning {filteredUser[0]?.name} dosimeter for {newMonth} {newYear}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Please scan dosimeter barcode or type in the code manually
              </DialogContentText>
              <MDInput
                autoFocus
                margin="dense"
                id="name"
                label="Dosimeter code"
                type="text"
                fullWidth
                variant="outlined"
                onChange={(e) => setNewDoseCode(e.target.value.toUpperCase())}
                onFocus={resetDoseCodeInput}
                inputProps={{ maxLength: 11 }}
                value={newDoseCode}
              />
            </DialogContent>
            <DialogActions>
              <MDButton variant="outlined" onClick={handleClose} color="error">
                Cancel
              </MDButton>
            </DialogActions>
          </>
        </Dialog>
      </div>
      <div>
        <Dialog
          open={
            openSwitchDialog &&
            newDoseCode.length === 11 &&
            filteredUser.length === 1 &&
            icDuplicateCheck.length === 0 &&
            filteredDoseCode.length === 0
          }
        >
          <>
            <DialogTitle>
              The code for the specified dosimeter was not found in the Excel file.{" "}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>Please switch to another dosimeter</DialogContentText>
            </DialogContent>
            <DialogActions>
              <MDButton variant="outlined" onClick={handleCloseSwitchDialog} color="error">
                Okay
              </MDButton>
            </DialogActions>
          </>
        </Dialog>
      </div>
      <div>
        <Dialog
          open={
            open &&
            newIcNum.length === 12 &&
            filteredUser.length === 1 &&
            icDuplicateCheck.length === 1
          }
        >
          <>
            <DialogTitle>
              The user {icDuplicateCheck[0]?.name} has already been assigned with the dosimeter code{" "}
              {icDuplicateCheck[0]?.doseCode}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>Please proceed to scan the next user</DialogContentText>
            </DialogContent>
            <DialogActions>
              <MDButton variant="outlined" onClick={handleClose} color="error">
                Okay
              </MDButton>
            </DialogActions>
          </>
        </Dialog>
      </div>
      <div>
        <Dialog open={duplicationDialogOpen}>
          <>
            <DialogTitle>
              The dosimeter with code {newDoseCode} has already been assigned to the user{" "}
              {analysedUsers.filter((user) => user.doseCode === newDoseCode)[0]?.name}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>Please avoid the duplication</DialogContentText>
            </DialogContent>
            <DialogActions>
              <MDButton
                variant="outlined"
                onClick={() => setDuplicationDialogOpen(false)}
                color="error"
              >
                Okay
              </MDButton>
            </DialogActions>
          </>
        </Dialog>
      </div>
      <div>
        <Dialog open={open && newIcNum.length === 12 && filteredUser.length === 0}>
          <>
            <DialogTitle>User not found</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Please make sure the user with IC number {newIcNum} is registered
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <MDButton variant="outlined" onClick={handleClose} color="error">
                Okay
              </MDButton>
            </DialogActions>
          </>
        </Dialog>
      </div>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={circularOpen}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <DashboardNavbar />
      <MDBox py={3}>
        <Card>
          <Grid container spacing={0}>
            <Grid item xs={12} md={4} lg={4}>
              <MDBox pt={4} px={3} sx={{ pb: { xs: 0, sm: 0, md: 3, lg: 3 } }}>
                <MDBox>
                  <MDBox mb={2}>
                    <MDTypography variant="h6">
                      Please select month and year of assignment
                    </MDTypography>
                  </MDBox>
                  <MDBox mb={2}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Month and Year"
                        views={["month", "year"]}
                        onChange={handleDateChange}
                      />
                    </LocalizationProvider>
                  </MDBox>
                </MDBox>
                {/* <MDBox>
                  <MDBox mb={2}>
                    <MDTypography variant="h6">Year of analysis : {newYear}</MDTypography>
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      autoFocus
                      onFocus={resetInputYear}
                      onChange={(e) => setNewYear(e.target.value)}
                      inputProps={{ maxLength: 4 }}
                      style={{ width: "100px" }}
                      placeholder="Insert year..."
                    />
                  </MDBox>
                  {newYear.length === 4 && (
                    <>
                      <MDBox mb={2}>
                        <MDTypography variant="h6">Month of analysis : {newMonth}</MDTypography>
                      </MDBox>
                      <MDBox mb={2}>
                        <FormControl required style={{ width: "100px" }}>
                          <InputLabel id="status-select-label">Month</InputLabel>
                          <Select
                            labelId="month-select-label"
                            id="month-select"
                            value={newMonth}
                            label="Month"
                            onChange={(e) => setNewMonth(e.target.value)}
                            style={{ height: 44 }}
                          >
                            <MenuItem value="">
                              <em>Please Select</em>
                            </MenuItem>
                            <MenuItem value="January">January</MenuItem>
                            <MenuItem value="February">February</MenuItem>
                            <MenuItem value="March">March</MenuItem>
                            <MenuItem value="April">April</MenuItem>
                            <MenuItem value="May">May</MenuItem>
                            <MenuItem value="June">June</MenuItem>
                            <MenuItem value="July">July</MenuItem>
                            <MenuItem value="August">August</MenuItem>
                            <MenuItem value="September">September</MenuItem>
                            <MenuItem value="October">October</MenuItem>
                            <MenuItem value="November">November</MenuItem>
                            <MenuItem value="December">December</MenuItem>
                          </Select>
                        </FormControl>
                      </MDBox>
                    </>
                  )}
                </MDBox> */}
              </MDBox>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <MDBox
                px={3}
                sx={{ pb: { xs: 0, sm: 0, md: 3, lg: 3 }, pt: { xs: 0, sm: 0, md: 4, lg: 4 } }}
              >
                <MDBox>
                  {newMonth?.length > 0 && newYear.toString().length === 4 && (
                    <>
                      <MDBox mb={2}>
                        <MDTypography variant="h6">
                          Select file of .xls / .xlsx (background)
                        </MDTypography>
                      </MDBox>
                      <MDBox mb={2}>
                        <MDInput
                          id="bgFile"
                          type="file"
                          accept=".xls,.xlsx"
                          onChange={onChangeFileBackground}
                        />
                      </MDBox>
                    </>
                  )}
                </MDBox>
              </MDBox>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <MDBox
                px={3}
                sx={{ pb: { xs: 0, sm: 0, md: 3, lg: 3 }, pt: { xs: 0, sm: 0, md: 4, lg: 4 } }}
              >
                <MDBox>
                  {newMonth?.length > 0 && newYear.toString().length === 4 && arrBg.length > 0 && (
                    <>
                      <MDBox mb={2}>
                        <MDTypography variant="h6">
                          Please scan IC barcode or type in the IC number manually
                        </MDTypography>
                      </MDBox>
                      <MDBox mb={2}>
                        <MDInput
                          id="ic-num-input"
                          autoFocus
                          onFocus={(e) => resetInputIcNum(e)}
                          onChange={(e) => setNewIcNum(e.target.value)}
                          inputProps={{ maxLength: 12 }}
                          style={{ width: "140px" }}
                          placeholder="Insert IC number..."
                        />
                      </MDBox>
                    </>
                  )}
                </MDBox>
              </MDBox>
            </Grid>
          </Grid>
        </Card>
      </MDBox>

      {analysedUsers.length > 0 && newMonth?.length > 0 && newYear.toString().length === 4 && (
        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography variant="h6" color="white" mb={2}>
                    Dosimeter assignment for {newMonth} {newYear}
                  </MDTypography>
                  <MDButton variant="contained" color="success" onClick={submit}>
                    Sync to server ({analysedUsers.length} of {users.filter((e) => e.active).length}{" "}
                    active users assigned)
                  </MDButton>
                </MDBox>
                <MDBox pt={3}>
                  <DataTable
                    table={{ columns: collArr, rows: mappedRowsArr }}
                    entriesPerPage
                    isSorted
                    showTotalEntries
                    noEndBorder
                  />
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      )}
      <Footer />
    </DashboardLayout>
  );
}

export default PersonnelPreAnalysis;
