import { useMemo, useState } from "react";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  Dialog,
  Card,
  Box,
  Stack,
  // Chip,
  IconButton,
  Tooltip,
  // ButtonGroup,
  Grid,
} from "@mui/material";
import MDTypography from "components/MDTypography";
// import NotListedLocationIcon from "@mui/icons-material/NotListedLocation";
import BadgeIcon from "@mui/icons-material/Badge";
// import BackHandIcon from "@mui/icons-material/BackHand";
// import AccessibilityIcon from "@mui/icons-material/Accessibility";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import CloseIcon from "@mui/icons-material/Close";
// import DoneIcon from "@mui/icons-material/Done";
import EditIcon from "@mui/icons-material/Edit";
import MDBox from "components/MDBox";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import individualHp10 from "../data/individualHp10";

export default function DialogProfileView({
  dispatchCloseDialogView,
  openDialogView,
  locationName,
  locationID1,
  dosimeterID1,
  locationID2,
  dosimeterID2,
  handleClickEditDialog,
  id,
  birthday,
}) {
  // const [expanded, setExpanded] = useState(false);

  // const handleChange = (panel) => (event, newExpanded) => {
  //   setExpanded(newExpanded ? panel : false);
  // };
  const [newYear, setNewYear] = useState(new Date().getFullYear());

  const handleDateChange = (newValue) => {
    setNewYear(newValue?.$y);
  };

  function handleResetYear() {
    setNewYear(new Date().getFullYear());
  }

  // function handleResetExpanded() {
  //   setExpanded(false);
  // }

  const data = individualHp10(id, newYear);

  const memoizedData = useMemo(() => data, [id, newYear]);

  const age = (new Date().getTime() - birthday) / (3600 * 24 * 365 * 1000);

  let year = Math.floor(age);

  let month = Math.round((age - year) * 12);

  if (month === 12) {
    month = 0;
    year += 1;
  }

  return (
    <Dialog open={openDialogView} maxWidth="sm" fullWidth>
      <Card>
        <Box sx={{ p: 2, display: "flex", justifyContent: "space-between" }}>
          <Stack spacing={2}>
            <MDTypography variant="h5">{locationName}</MDTypography>
            <MDBox>
              <Grid container>
                <Grid item xs={12} sm={6}>
                  <Stack spacing={2} direction="row">
                    <MDBox>
                      <Tooltip title="Location ID 1" placement="left-start" enterTouchDelay={0}>
                        <LocationOnIcon color="text" />
                      </Tooltip>
                    </MDBox>

                    <MDTypography variant="body2" color="text">
                      {locationID1}
                    </MDTypography>
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Stack spacing={2} direction="row">
                    <MDBox>
                      <Tooltip title="Dosimeter ID 1" placement="left-start" enterTouchDelay={0}>
                        <BadgeIcon color="text" />
                      </Tooltip>
                    </MDBox>
                    <MDTypography variant="body2" color="text">
                      {dosimeterID1}
                    </MDTypography>
                  </Stack>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} sm={6}>
                  <Stack spacing={2} direction="row">
                    <MDBox>
                      <Tooltip title="Location ID 2" placement="left-start" enterTouchDelay={0}>
                        <LocationOnIcon color="text" />
                      </Tooltip>
                    </MDBox>

                    <MDTypography variant="body2" color="text">
                      {locationID2}
                    </MDTypography>
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Stack spacing={2} direction="row">
                    <MDBox>
                      <Tooltip title="Dosimeter ID 2" placement="left-start" enterTouchDelay={0}>
                        <BadgeIcon color="text" />
                      </Tooltip>
                    </MDBox>
                    <MDTypography variant="body2" color="text">
                      {dosimeterID2}
                    </MDTypography>
                  </Stack>
                </Grid>
              </Grid>
            </MDBox>
          </Stack>
          <Stack>
            <Tooltip title="Close" placement="right-start" enterTouchDelay={0}>
              <IconButton
                color="error"
                onClick={() => {
                  dispatchCloseDialogView();
                  handleResetYear();
                  // handleResetExpanded();
                }}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Edit profile" placement="right-start" enterTouchDelay={0}>
              <IconButton color="inherit" onClick={handleClickEditDialog}>
                <EditIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Stack>
        </Box>
        {/* <MAccordion
          TransitionProps={{ unmountOnExit: true }}
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <MAccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <MDTypography variant="button" color="text">
              {expanded ? "Hide Details" : "Show Details"}
            </MDTypography>
          </MAccordionSummary>
          <MAccordionDetails>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <Stack spacing={2} direction="row">
                  <MDBox>
                    <Tooltip title="Contact no." placement="left-start" enterTouchDelay={0}>
                      <PhoneIcon color="text" />
                    </Tooltip>
                  </MDBox>
                  {tel ? (
                    <ReactWhatsapp
                      style={{ backgroundColor: "transparent", border: "none", marginTop: -8 }}
                      number={`+6${tel}`}
                    >
                      <MDTypography variant="body2" color="text">
                        {tel}
                      </MDTypography>
                    </ReactWhatsapp>
                  ) : (
                    <MDTypography variant="body2" color="text">
                      N/A
                    </MDTypography>
                  )}
                </Stack>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Stack spacing={2} direction="row">
                  <MDBox>
                    <Tooltip
                      title="Health screening no."
                      placement="left-start"
                      enterTouchDelay={0}
                    >
                      <MenuBookIcon color="text" />
                    </Tooltip>
                  </MDBox>
                  {healthScrNo ? (
                    <MDTypography variant="body2" color="text">
                      {healthScrNo}
                    </MDTypography>
                  ) : (
                    <MDTypography variant="body2" color="text">
                      N/A
                    </MDTypography>
                  )}
                </Stack>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={12} sm={6}>
                <Stack spacing={2} direction="row">
                  <MDBox>
                    <Tooltip title="Report duty" placement="left-start" enterTouchDelay={0}>
                      <WorkIcon color="text" />
                    </Tooltip>
                  </MDBox>
                  <MDTypography variant="body2" color="text">
                    {new Date(employDate).toString().split(" ")[2]}{" "}
                    {new Date(employDate).toString().split(" ")[1]}{" "}
                    {new Date(employDate).toString().split(" ")[3]}
                  </MDTypography>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Stack spacing={2} direction="row">
                  <MDBox>
                    <Tooltip title="Next checkup" placement="left-start" enterTouchDelay={0}>
                      <MonitorHeartIcon color="text" />
                    </Tooltip>
                  </MDBox>
                  <MDTypography
                    variant="body2"
                    color={new Date().getTime() > new Date(checkUpDate) ? "error" : "text"}
                  >
                    {new Date(checkUpDate).toString().split(" ")[2]}{" "}
                    {new Date(checkUpDate).toString().split(" ")[1]}{" "}
                    {new Date(checkUpDate).toString().split(" ")[3]}
                  </MDTypography>
                </Stack>
              </Grid>
            </Grid>

            <Stack spacing={2} direction="row">
              <MDBox>
                <Tooltip title="Email" placement="left-start" enterTouchDelay={0}>
                  <EmailIcon color="text" />
                </Tooltip>
              </MDBox>
              <MDTypography variant="body2" color="text">
                {email || "N/A"}
              </MDTypography>
            </Stack>
            <Stack spacing={2} direction="row">
              <MDBox>
                <Tooltip title="Address" placement="left-start" enterTouchDelay={0}>
                  <HomeIcon color="text" />
                </Tooltip>
              </MDBox>
              <MDTypography variant="body2" color="text">
                {address || "N/A"}
              </MDTypography>
            </Stack>
          </MAccordionDetails>
        </MAccordion> */}
        <ReportsLineChart chartOnly chart={memoizedData} color="dark" />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            paddingBottom: 2,
            paddingRight: 2,
            paddingLeft: 2,
          }}
        >
          {/* <ButtonGroup variant="outlined" color="text">
            <Tooltip title="Show personnel dose">
              <IconButton
                color={groupButtonState === "personnel" ? "info" : "light"}
                size="small"
                onClick={() => setGroupButtonState("personnel")}
              >
                <AccessibilityIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Show extremities dose">
              <IconButton
                color={groupButtonState === "extremities" ? "info" : "light"}
                size="small"
                onClick={() => setGroupButtonState("extremities")}
              >
                <BackHandIcon />
              </IconButton>
            </Tooltip>
          </ButtonGroup> */}
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Year"
              views={["year"]}
              onChange={handleDateChange}
              defaultValue={dayjs()}
              sx={{ width: { lg: "20%", md: "20%", sm: "30%", xs: "40%" } }}
            />
          </LocalizationProvider>
        </Box>
        {/* <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ px: 2, py: 1, bgcolor: "background.default" }}
        >
          {active ? (
            <Chip
              label="Active"
              color="success"
              deleteIcon={<DoneIcon />}
              onDelete={handleDelete}
            />
          ) : (
            <Chip
              label="Inactive"
              color="error"
              deleteIcon={<CloseIcon />}
              onDelete={handleDelete}
            />
          )}
        </Stack> */}
      </Card>
    </Dialog>
  );
}
