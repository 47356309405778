import * as XLSX from "xlsx";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Grid, Card, Snackbar, Alert, Backdrop, CircularProgress } from "@mui/material";
import DataTable from "examples/Tables/DataTable";
import * as React from "react";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { useEffect, useState, useCallback, useContext } from "react";
import { UserContext } from "context/UserContext";
import MDInput from "components/MDInput";
import { doc, writeBatch } from "firebase/firestore";
import { db } from "../../../firebase-config";
import OnlineStatusSnackbar from "../../onlineStatusSnackbar/onlineStatusSnackbar";

function LocationsAnalysis() {
  useEffect(() => {
    document.title = "Dosimeter Analysis - RadDB";
  }, []);
  const { locations } = useContext(UserContext);
  const [circularOpen, setCircularOpen] = useState(false);
  const [arrExcelActual, setArrExcelActual] = useState([]);
  const [arrExcelBackground, setArrExcelBackground] = useState([]);

  const onChangeFileActual = useCallback(
    (e) => {
      const [file] = e.target.files;
      const reader = new FileReader();

      reader.onload = (evt) => {
        const jsonObj = [];
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, { type: "binary" });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const dataXLSX = XLSX.utils.sheet_to_csv(ws, { header: 1 }).split("\n");
        // setArr(dataXLSX);

        // Source: https://stackoverflow.com/questions/59016562/parse-csv-records-in-to-an-array-of-objects-in-javascript
        const headers = dataXLSX[0].split(",");
        for (let i = 1; i < dataXLSX.length; i += 1) {
          const dutu = dataXLSX[i].split(",");
          const obj = {};
          for (let j = 0; j < dutu.length; j += 1) {
            obj[headers[j].trim()] = dutu[j].trim();
          }

          jsonObj.push(obj);
        }

        const mapped = jsonObj.filter((element) => element["Serial Number"] !== "");

        setArrExcelActual(mapped);
      };

      reader.readAsBinaryString(file);
    },
    [arrExcelActual]
  );

  const onChangeFileBackground = useCallback(
    (e) => {
      const [file] = e.target.files;
      const reader = new FileReader();

      reader.onload = (evt) => {
        const jsonObj = [];
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, { type: "binary" });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const dataXLSX = XLSX.utils.sheet_to_csv(ws, { header: 1 }).split("\n");
        // setArr(dataXLSX);

        // Source: https://stackoverflow.com/questions/59016562/parse-csv-records-in-to-an-array-of-objects-in-javascript
        const headers = dataXLSX[0].split(",");
        for (let i = 1; i < dataXLSX.length; i += 1) {
          const dutu = dataXLSX[i].split(",");
          const obj = {};
          for (let j = 0; j < dutu.length; j += 1) {
            obj[headers[j].trim()] = dutu[j].trim();
          }

          jsonObj.push(obj);
        }

        const mapped = jsonObj.filter((element) => element["Serial Number"] !== "");

        setArrExcelBackground(mapped);
      };

      reader.readAsBinaryString(file);
    },
    [arrExcelBackground]
  );

  const [newYear, setNewYear] = useState("");

  const [newMonth, setNewMonth] = useState("");

  const [snackbarOpen, setSnackbarOpen] = useState({
    openSnackBar: false,
    vertical: "top",
    horizontal: "center",
  });

  const [snackbarSuccessOpen, setSnackbarSuccessOpen] = useState({
    openSnackBarSuccess: false,
    vertical: "top",
    horizontal: "center",
  });

  const [newHp10ControlReading, setNewHp10ControlReading] = useState("");

  const setNewHp10ControlReadingFunc = useCallback(
    (e) => {
      setNewHp10ControlReading(e.target.value);
    },
    [newHp10ControlReading]
  );

  const [newHp007ControlReading, setNewHp007ControlReading] = useState("");

  const setNewHp007ControlReadingFunc = useCallback(
    (e) => {
      setNewHp007ControlReading(e.target.value);
    },
    [newHp007ControlReading]
  );

  const { vertical, horizontal, openSnackBar } = snackbarOpen;

  const { verticalSuccess, horizontalSuccess, openSnackBarSuccess } = snackbarSuccessOpen;

  let locationID;
  let dosimeterID;

  if (
    newMonth === "January" ||
    newMonth === "March" ||
    newMonth === "May" ||
    newMonth === "July" ||
    newMonth === "September" ||
    newMonth === "November"
  ) {
    locationID = "locationID1";
    dosimeterID = "dosimeterID1";
  } else {
    locationID = "locationID2";
    dosimeterID = "dosimeterID2";
  }
  const filteredArr = locations.filter((loc) => loc?.[locationID]);
  const newArr = filteredArr.map((e) => ({
    ...e,
    [newYear]: {
      [newMonth]: {
        ambientRaw: null,
        ambientBackground: null,
        ambientControl: null,
        ambientNet: null,
        directionalRaw: null,
        directionalBackground: null,
        directionalControl: null,
        directionalNet: null,
      },
    },
  }));

  useEffect(() => {
    if (document.getElementById("backgroundFile")) {
      document.getElementById("backgroundFile").focus();
    }
  }, [arrExcelActual.length > 0]);

  useEffect(() => {
    if (document.getElementById("deepDoseInput")) {
      document.getElementById("deepDoseInput").focus();
    }
  }, [filteredArr.length > 0]);

  useEffect(() => {
    if (document.getElementById("shallowDoseInput")) {
      document.getElementById("shallowDoseInput").focus();
    }
  }, [newHp10ControlReading.length === 4]);

  useEffect(() => {
    if (document.getElementById("actualFile")) {
      document.getElementById("actualFile").focus();
    }
  }, [newHp007ControlReading.length === 4]);

  const row = [...newArr];

  const calculateActualDose = () => {
    for (let i = 0; i < row.length; i += 1) {
      for (let j = 0; j < arrExcelActual.length; j += 1) {
        if (arrExcelActual[j]["Serial Number"] === row[i]?.[dosimeterID]) {
          const x = arrExcelActual[j]["Deep Dose"];
          const y = arrExcelActual[j]["Lens Dose"];
          row[i][newYear][newMonth].ambientRaw = parseFloat(x).toFixed(3);
          row[i][newYear][newMonth].ambientControl = newHp10ControlReading;
          row[i][newYear][newMonth].directionalRaw = parseFloat(y).toFixed(3);
          row[i][newYear][newMonth].directionalControl = newHp007ControlReading;
        }
      }
    }
  };

  if (arrExcelActual.length > 0) {
    calculateActualDose();
  }

  const arrWithBackgroundDose = [...row];

  const calculateBackgroundDose = () => {
    for (let i = 0; i < row.length; i += 1) {
      for (let j = 0; j < arrExcelBackground.length; j += 1) {
        if (arrExcelBackground[j]["Serial Number"] === row[i]?.[dosimeterID]) {
          const x = arrExcelBackground[j]["Deep Dose"];
          const y = arrExcelBackground[j]["Lens Dose"];
          row[i][newYear][newMonth].ambientBackground = parseFloat(x).toFixed(3);
          row[i][newYear][newMonth].ambientControl = newHp10ControlReading;
          row[i][newYear][newMonth].ambientNet =
            row[i][newYear][newMonth].ambientRaw -
              row[i][newYear][newMonth].ambientBackground -
              row[i][newYear][newMonth].ambientControl <
            0.05
              ? "0.00"
              : (
                  Math.round(
                    (row[i][newYear][newMonth].ambientRaw -
                      row[i][newYear][newMonth].ambientBackground -
                      row[i][newYear][newMonth].ambientControl) *
                      100
                  ) / 100
                ).toFixed(2);

          row[i][newYear][newMonth].directionalBackground = parseFloat(y).toFixed(3);
          row[i][newYear][newMonth].directionalControl = newHp007ControlReading;
          row[i][newYear][newMonth].directionalNet =
            row[i][newYear][newMonth].directionalRaw -
              row[i][newYear][newMonth].directionalBackground -
              row[i][newYear][newMonth].directionalControl <
            0.05
              ? "0.00"
              : (
                  Math.round(
                    (row[i][newYear][newMonth].directionalRaw -
                      row[i][newYear][newMonth].directionalBackground -
                      row[i][newYear][newMonth].directionalControl) *
                      100
                  ) / 100
                ).toFixed(2);
        }
      }
    }
  };

  if (arrExcelBackground.length > 0) {
    calculateBackgroundDose();
  }

  const handleSnackbarOpen = () => {
    setSnackbarOpen({
      openSnackBar: true,
      vertical: "top",
      horizontal: "center",
    });
  };

  const handleSnackbarClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen({
      ...snackbarOpen,
      openSnackBar: false,
    });
  };

  const handleSnackbarSuccessClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarSuccessOpen({
      ...snackbarSuccessOpen,
      openSnackBarSuccess: false,
    });
  };

  const collArr = [
    {
      Header: "location",
      accessor: "locName",
      width: "45%",
      align: "left",
    },
    { Header: "Location ID", accessor: "locID", align: "center" },
    { Header: "dosimeter ID", accessor: "dosID", align: "center" },
  ];

  let mappedRowsArr;

  if (
    newMonth === "January" ||
    newMonth === "March" ||
    newMonth === "May" ||
    newMonth === "July" ||
    newMonth === "September" ||
    newMonth === "November"
  ) {
    mappedRowsArr = filteredArr.map((e) => ({
      locName: e.locationName,
      locID: e.locationID1,
      dosID: e.dosimeterID1,
    }));
  } else {
    mappedRowsArr = filteredArr.map((e) => ({
      locName: e.locationName,
      locID: e.locationID2,
      dosID: e.dosimeterID2,
    }));
  }

  const collArrWithActualDose = [
    {
      Header: "location",
      accessor: "locName",
      width: "45%",
      align: "left",
    },
    { Header: "Location ID", accessor: "locID", align: "center" },
    { Header: "dosimeter ID", accessor: "dosID", align: "center" },
    { Header: "Ambient actual dose", accessor: "ambientActualDose", align: "center" },
    { Header: "Directional actual dose", accessor: "directionalActualDose", align: "center" },
  ];

  let mappedRowsArrWithActualDose;

  if (
    newMonth === "January" ||
    newMonth === "March" ||
    newMonth === "May" ||
    newMonth === "July" ||
    newMonth === "September" ||
    newMonth === "November"
  ) {
    mappedRowsArrWithActualDose = row.map((e) => ({
      locName: e.locationName,
      locID: e.locationID1,
      dosID: e.dosimeterID1,
      ambientActualDose: e?.[newYear]?.[newMonth]?.ambientRaw,
      directionalActualDose: e?.[newYear]?.[newMonth]?.directionalRaw,
    }));
  } else {
    mappedRowsArrWithActualDose = row.map((e) => ({
      locName: e.locationName,
      locID: e.locationID2,
      dosID: e.dosimeterID2,
      ambientActualDose: e?.[newYear]?.[newMonth]?.ambientRaw,
      directionalActualDose: e?.[newYear]?.[newMonth]?.directionalRaw,
    }));
  }

  const collArrWithBackgroundlDose = [
    {
      Header: "location",
      accessor: "locName",
      width: "45%",
      align: "left",
    },
    { Header: "Location ID", accessor: "locID", align: "center" },
    { Header: "dosimeter ID", accessor: "dosID", align: "center" },
    { Header: "Ambient actual dose", accessor: "ambientActualDose", align: "center" },
    { Header: "Directional actual dose", accessor: "directionalActualDose", align: "center" },
    { Header: "Ambient background dose", accessor: "ambientBackgroundDose", align: "center" },
    {
      Header: "Directional background dose",
      accessor: "directionalBackgroundDose",
      align: "center",
    },
    { Header: "Ambient net dose", accessor: "ambientNetDose", align: "center" },
    { Header: "Directional net dose", accessor: "directionalNetDose", align: "center" },
  ];

  let mappedRowsArrWithBackgroundDose;

  if (
    newMonth === "January" ||
    newMonth === "March" ||
    newMonth === "May" ||
    newMonth === "July" ||
    newMonth === "September" ||
    newMonth === "November"
  ) {
    mappedRowsArrWithBackgroundDose = arrWithBackgroundDose.map((e) => ({
      locName: e.locationName,
      locID: e.locationID1,
      dosID: e.dosimeterID1,
      ambientActualDose: e?.[newYear]?.[newMonth]?.ambientRaw,
      directionalActualDose: e?.[newYear]?.[newMonth]?.directionalRaw,
      ambientBackgroundDose: e?.[newYear]?.[newMonth]?.ambientBackground,
      directionalBackgroundDose: e?.[newYear]?.[newMonth]?.directionalBackground,
      ambientNetDose: e?.[newYear]?.[newMonth]?.ambientNet,
      directionalNetDose: e?.[newYear]?.[newMonth]?.directionalNet,
    }));
  } else {
    mappedRowsArrWithBackgroundDose = arrWithBackgroundDose.map((e) => ({
      locName: e.locationName,
      locID: e.locationID2,
      dosID: e.dosimeterID2,
      ambientActualDose: e?.[newYear]?.[newMonth]?.ambientRaw,
      directionalActualDose: e?.[newYear]?.[newMonth]?.directionalRaw,
      ambientBackgroundDose: e?.[newYear]?.[newMonth]?.ambientBackground,
      directionalBackgroundDose: e?.[newYear]?.[newMonth]?.directionalBackground,
      ambientNetDose: e?.[newYear]?.[newMonth]?.ambientNet,
      directionalNetDose: e?.[newYear]?.[newMonth]?.directionalNet,
    }));
  }

  const resetHp10InputControlReading = (e) => {
    e.target.value = "";
    setNewHp10ControlReading("");
    setArrExcelActual([]);
    setArrExcelBackground([]);
  };

  const resetHp007InputControlReading = (e) => {
    e.target.value = "";
    setNewHp007ControlReading("");
    setArrExcelActual([]);
    setArrExcelBackground([]);
  };

  useEffect(() => {
    if (document.getElementById("month-select")) {
      document.getElementById("month-select").focus();
    }
  }, [newYear.length === 4]);

  useEffect(() => {
    if (filteredArr.length === 0 && newMonth?.length !== 0) {
      handleSnackbarOpen();
    }

    if (filteredArr.length > 0 && newMonth?.length !== 0) {
      handleSnackbarClose();
    }
  }, [filteredArr.length > 0, newMonth]);

  const handleCircularOpen = () => {
    setCircularOpen(true);
  };

  const handleCircularClose = () => {
    setCircularOpen(false);
  };

  const handleSnackbarSuccessOpen = () => {
    setSnackbarSuccessOpen({
      openSnackBarSuccess: true,
      verticalSuccess: "top",
      horizontalSuccess: "center",
    });
  };

  const submit = async (e) => {
    handleCircularOpen();
    e.preventDefault();
    const batch = writeBatch(db);
    for (let i = 0; i < arrWithBackgroundDose.length; i += 1) {
      batch.update(doc(db, "locations", arrWithBackgroundDose[i]?.id), {
        // https://dev.to/milesbd/updating-nested-fields-in-firestore-with-dot-notation-53hp
        [`${newYear}.${newMonth}`]: {
          ambientBackground: parseFloat(
            arrWithBackgroundDose[i][newYear][newMonth].ambientBackground
          ),
          ambientControl: parseFloat(arrWithBackgroundDose[i][newYear][newMonth].ambientControl),
          ambientNet: parseFloat(arrWithBackgroundDose[i][newYear][newMonth].ambientNet),
          ambientRaw: parseFloat(arrWithBackgroundDose[i][newYear][newMonth].ambientRaw),
          directionalBackground: parseFloat(
            arrWithBackgroundDose[i][newYear][newMonth].directionalBackground
          ),
          directionalControl: parseFloat(
            arrWithBackgroundDose[i][newYear][newMonth].directionalControl
          ),
          directionalNet: parseFloat(arrWithBackgroundDose[i][newYear][newMonth].directionalNet),
          directionalRaw: parseFloat(arrWithBackgroundDose[i][newYear][newMonth].directionalRaw),
        },
      });
    }

    await batch.commit().then(() => {
      handleCircularClose();
      handleSnackbarSuccessOpen();
      setNewYear("");
      setNewMonth("");
      setNewHp10ControlReading("");
      setNewHp007ControlReading("");
      setArrExcelActual([]);
      setArrExcelBackground([]);
    });
  };

  const handleDateChange = (date) => {
    if (date) {
      const { $M, $y } = date;
      const monthMap = {
        0: "January",
        1: "February",
        2: "March",
        3: "April",
        4: "May",
        5: "June",
        6: "July",
        7: "August",
        8: "September",
        9: "October",
        10: "November",
        11: "December",
      };

      setNewMonth(monthMap[$M]);

      setNewYear($y);
    }
  };

  return (
    <DashboardLayout>
      <OnlineStatusSnackbar />
      {filteredArr.length === 0 && newMonth?.length !== 0 && newYear.toString().length === 4 && (
        <Snackbar
          key={vertical + horizontal}
          open={openSnackBar}
          autoHideDuration={6000}
          anchorOrigin={{ vertical, horizontal }}
        >
          <Alert severity="error" onClose={handleSnackbarClose} sx={{ width: "100%" }}>
            No locations assigned yet. Please assign some locations in Locations Assignment tab.
          </Alert>
        </Snackbar>
      )}

      <Snackbar
        key={verticalSuccess + horizontalSuccess}
        open={openSnackBarSuccess}
        autoHideDuration={6000}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert severity="success" onClose={handleSnackbarSuccessClose} sx={{ width: "100%" }}>
          Successfully synced to server
        </Alert>
      </Snackbar>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={circularOpen}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <DashboardNavbar />

      <MDBox py={3}>
        <Card>
          <Grid container spacing={0}>
            <Grid item xs={12} md={4} lg={4}>
              <MDBox pt={4} px={3} sx={{ pb: { xs: 0, sm: 0, md: 3, lg: 3 } }}>
                <MDBox>
                  <MDBox mb={2}>
                    <MDTypography variant="h6">
                      Please select month and year of analysis
                    </MDTypography>
                  </MDBox>
                  <MDBox mb={2}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Month and Year"
                        views={["month", "year"]}
                        onChange={handleDateChange}
                      />
                    </LocalizationProvider>
                  </MDBox>
                </MDBox>
                {/* <MDBox>
                  <MDBox mb={2}>
                    <MDTypography variant="h6">Year of analysis : {newYear}</MDTypography>
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      autoFocus
                      onFocus={resetInputYear}
                      onChange={setNewYearFunc}
                      inputProps={{ maxLength: 4 }}
                      style={{ width: "100px" }}
                      placeholder="Insert year..."
                    />
                  </MDBox>
                  {newYear.length === 4 && (
                    <>
                      <MDBox mb={2}>
                        <MDTypography variant="h6">Month of analysis : {newMonth}</MDTypography>
                      </MDBox>
                      <MDBox mb={2}>
                        <FormControl required style={{ width: "100px" }}>
                          <InputLabel id="status-select-label">Month</InputLabel>
                          <Select
                            labelId="month-select-label"
                            id="month-select"
                            value={newMonth}
                            label="Month"
                            onChange={setNewMonthFunc}
                            style={{ height: 44 }}
                          >
                            <MenuItem value="">
                              <em>Please Select</em>
                            </MenuItem>
                            <MenuItem value="January">January</MenuItem>
                            <MenuItem value="February">February</MenuItem>
                            <MenuItem value="March">March</MenuItem>
                            <MenuItem value="April">April</MenuItem>
                            <MenuItem value="May">May</MenuItem>
                            <MenuItem value="June">June</MenuItem>
                            <MenuItem value="July">July</MenuItem>
                            <MenuItem value="August">August</MenuItem>
                            <MenuItem value="September">September</MenuItem>
                            <MenuItem value="October">October</MenuItem>
                            <MenuItem value="November">November</MenuItem>
                            <MenuItem value="December">December</MenuItem>
                          </Select>
                        </FormControl>
                      </MDBox>
                    </>
                  )}
                </MDBox> */}
              </MDBox>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <MDBox
                px={3}
                sx={{ pt: { xs: 0, sm: 0, md: 4, lg: 4 }, pb: { xs: 0, sm: 0, md: 3, lg: 3 } }}
              >
                <MDBox>
                  {filteredArr.length > 0 &&
                    newMonth.length > 0 &&
                    newYear.toString().length === 4 && (
                      <>
                        <MDBox mb={2}>
                          <MDTypography variant="h6">
                            Ambient dose control value : {newHp10ControlReading} (mSv)
                          </MDTypography>
                        </MDBox>
                        <MDBox mb={2}>
                          <MDInput
                            id="deepDoseInput"
                            onFocus={resetHp10InputControlReading}
                            onChange={setNewHp10ControlReadingFunc}
                            inputProps={{ maxLength: 4 }}
                            style={{ width: "150px" }}
                            placeholder="Ambient dose control value..."
                          />
                        </MDBox>
                      </>
                    )}
                  {filteredArr.length > 0 &&
                    newMonth.length > 0 &&
                    newYear.toString().length === 4 &&
                    newHp10ControlReading.length > 0 && (
                      <>
                        <MDBox mb={2}>
                          <MDTypography variant="h6">
                            Directional dose control value : {newHp007ControlReading} (mSv)
                          </MDTypography>
                        </MDBox>
                        <MDBox mb={2}>
                          <MDInput
                            id="shallowDoseInput"
                            onFocus={resetHp007InputControlReading}
                            onChange={setNewHp007ControlReadingFunc}
                            inputProps={{ maxLength: 4 }}
                            style={{ width: "150px" }}
                            placeholder="Directional dose control value..."
                          />
                        </MDBox>
                      </>
                    )}
                </MDBox>
              </MDBox>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <MDBox pb={3} px={3} sx={{ pt: { xs: 0, sm: 0, md: 4, lg: 4 } }}>
                <MDBox>
                  {filteredArr.length > 0 &&
                    newMonth.length > 0 &&
                    newYear.toString().length === 4 &&
                    newHp10ControlReading.length > 0 &&
                    newHp007ControlReading.length > 0 && (
                      <>
                        <MDBox mb={2}>
                          <MDTypography variant="h6">
                            Select file of .xls / .xlsx (actual)
                          </MDTypography>
                        </MDBox>
                        <MDBox mb={2}>
                          <MDInput
                            id="actualFile"
                            type="file"
                            accept=".xls,.xlsx"
                            onChange={onChangeFileActual}
                          />
                        </MDBox>
                      </>
                    )}
                  {filteredArr.length > 0 &&
                    newMonth.length > 0 &&
                    newYear.toString().length === 4 &&
                    newHp10ControlReading.length > 0 &&
                    newHp007ControlReading.length > 0 &&
                    arrExcelActual.length > 0 && (
                      <>
                        <MDBox mb={2}>
                          <MDTypography variant="h6">
                            Select file of .xls / .xlsx (background)
                          </MDTypography>
                        </MDBox>
                        <MDBox mb={2}>
                          <MDInput
                            id="backgroundFile"
                            type="file"
                            accept=".xls,.xlsx"
                            onChange={onChangeFileBackground}
                          />
                        </MDBox>
                      </>
                    )}
                </MDBox>
              </MDBox>
            </Grid>
          </Grid>
        </Card>
      </MDBox>

      {filteredArr.length > 0 &&
        newMonth?.length > 0 &&
        newYear.toString().length === 4 &&
        arrExcelActual.length === 0 && (
          <MDBox pt={6} pb={3}>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Card>
                  <MDBox
                    mx={2}
                    mt={-3}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                  >
                    <MDTypography variant="h6" color="white">
                      Assigned dosimeters for {newMonth} {newYear}
                    </MDTypography>
                  </MDBox>
                  <MDBox pt={3}>
                    <DataTable
                      table={{ columns: collArr, rows: mappedRowsArr }}
                      entriesPerPage
                      isSorted
                      showTotalEntries
                      noEndBorder
                    />
                  </MDBox>
                </Card>
              </Grid>
            </Grid>
          </MDBox>
        )}

      {filteredArr.length > 0 &&
        newMonth?.length > 0 &&
        newYear.toString().length === 4 &&
        arrExcelActual.length > 0 &&
        arrExcelBackground.length === 0 && (
          <MDBox pt={6} pb={3}>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Card>
                  <MDBox
                    mx={2}
                    mt={-3}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                  >
                    <MDTypography variant="h6" color="white">
                      Assigned dosimeters for {newMonth} {newYear}
                    </MDTypography>
                  </MDBox>
                  <MDBox pt={3}>
                    <DataTable
                      table={{ columns: collArrWithActualDose, rows: mappedRowsArrWithActualDose }}
                      entriesPerPage
                      isSorted
                      showTotalEntries
                      noEndBorder
                    />
                  </MDBox>
                </Card>
              </Grid>
            </Grid>
          </MDBox>
        )}

      {filteredArr.length > 0 &&
        newMonth?.length > 0 &&
        newYear.toString().length === 4 &&
        arrExcelActual.length > 0 &&
        arrExcelBackground.length > 0 && (
          <MDBox pt={6} pb={3}>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Card>
                  <MDBox
                    mx={2}
                    mt={-3}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                  >
                    <MDTypography variant="h6" color="white" mb={2}>
                      Assigned dosimeters for {newMonth} {newYear}
                    </MDTypography>
                    <MDButton
                      variant="contained"
                      color="success"
                      onClick={submit}
                      disabled={
                        !row.some((e) => e?.[newYear]?.[newMonth].ambientRaw) ||
                        !row.some((e) => e?.[newYear]?.[newMonth].directionalRaw) ||
                        !row.some((e) => e?.[newYear]?.[newMonth].ambientBackground) ||
                        !row.some((e) => e?.[newYear]?.[newMonth].directionalBackground) ||
                        !row.some((e) => e?.[newYear]?.[newMonth].ambientNet !== null) ||
                        !row.some((e) => e?.[newYear]?.[newMonth].directionalNet !== null)
                      }
                    >
                      Sync to server
                    </MDButton>
                  </MDBox>
                  <MDBox pt={3}>
                    <DataTable
                      table={{
                        columns: collArrWithBackgroundlDose,
                        rows: mappedRowsArrWithBackgroundDose,
                      }}
                      entriesPerPage
                      isSorted
                      showTotalEntries
                      noEndBorder
                    />
                  </MDBox>
                </Card>
              </Grid>
            </Grid>
          </MDBox>
        )}

      {/* {filteredArr.length > 0 && newMonth?.length > 0 && newYear.toString().length === 4 && (
        // newShallowDose.length > 0 &&
        // newDeepDose.length > 0 &&
        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography variant="h6" color="white" mb={2}>
                    Assigned dosimeters for {newMonth} {newYear}
                  </MDTypography>
                  <MDButton
                    variant="contained"
                    color="success"
                    onClick={submit}
                    disabled
                    // disabled={
                    //   !(filteredArr.filter((e) => e[newYear][newMonth].hp10).length > 0)
                    // !filteredArr.every((e) => e[newYear][newMonth].hp007) &&
                    // !filteredArr.every((e) => e[newYear][newMonth].bgHp10) &&
                    // !filteredArr.every((e) => e[newYear][newMonth].bgHp007)
                    // }
                  >
                    Sync to server
                  </MDButton>
                </MDBox>
                <MDBox pt={3}>
                  <DataTable
                    table={{
                      columns: collArrWithCtrlRdgAndActualBg,
                      rows: mappedRowsArrWithCtrlRdgAndActualBg,
                    }}
                    entriesPerPage
                    isSorted
                    showTotalEntries
                    noEndBorder
                  />
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      )} */}
      <Footer />
    </DashboardLayout>
  );
}

export default LocationsAnalysis;
