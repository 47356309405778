// react-router-dom components
// import { Link } from "react-router-dom";

// @mui material components
import OnlineStatusSnackbar from "layouts/onlineStatusSnackbar/onlineStatusSnackbar";
import Card from "@mui/material/Card";
// import Switch from "@mui/material/Switch";
// import Grid from "@mui/material/Grid";
// import MuiLink from "@mui/material/Link";

// @mui icons
// import FacebookIcon from "@mui/icons-material/Facebook";
// import GitHubIcon from "@mui/icons-material/GitHub";
// import GoogleIcon from "@mui/icons-material/Google";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import {
  Switch,
  CircularProgress,
  Backdrop,
  Alert,
  // Collapse,
  // IconButton,
  Snackbar,
  FormControlLabel,
} from "@mui/material";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg.jpg";

// import CloseIcon from "@mui/icons-material/Close";

import { UserAuth } from "../../../context/AuthContext";

function Basic() {
  useEffect(() => {
    document.title = "Welcome to RadDB";
  }, []);
  // const [rememberMe, setRememberMe] = useState(false);

  // const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const [open, setOpen] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => setShowPassword(!showPassword);

  // const [openAlert, setOpenAlert] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen(!open);
  };
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const { signIn, user } = UserAuth();

  const [state, setState] = useState({
    openToast: false,
    vertical: "top",
    horizontal: "center",
  });

  const { vertical, horizontal, openToast } = state;

  const handleToastOpen = () => {
    setState({ openToast: true, vertical: "top", horizontal: "center" });
  };

  const handleToastClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setState({ ...state, openToast: false });
  };

  const [button, setButton] = useState(false);

  const handleSubmit = async (e) => {
    handleToastClose();
    handleToggle();
    setButton(!button);
    e.preventDefault();
    setError("");
    try {
      await signIn(email, password);
      handleClose();
      navigate("/");
    } catch (err) {
      setButton(false);
      handleClose();
      console.log(err.code);
      if (err.code === "auth/invalid-email") {
        setError("Invalid email address. Please try again.");
      } else if (err.code === "auth/wrong-password") {
        setError("Incorrect password. Please try again.");
      } else if (err.code === "auth/user-not-found") {
        setError("User not found. Please check the email address and try again.");
      } else if (err.code === "auth/too-many-requests") {
        setError("Too many requests, please try again later.");
      } else if (err.code === "auth/internal-error") {
        setError("Please enter your password.");
      } else if (err.code === "auth/network-request-failed") {
        setError("Network error. Please check your internet connectivity.");
      } else if (err.code === "auth/missing-password") {
        setError("Please enter your password.");
      }
      handleToastOpen();
    }
  };

  if (user) {
    return <Navigate to="/" />;
  }

  return (
    <BasicLayout image={bgImage}>
      <OnlineStatusSnackbar />
      <Card>
        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
          <CircularProgress color="inherit" />
        </Backdrop>
        {/* <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white">
            Sign in
          </MDTypography>
        </MDBox> */}

        <Snackbar
          autoHideDuration={4000}
          anchorOrigin={{ vertical, horizontal }}
          open={openToast}
          onClose={handleToastClose}
          message="I love snacks"
          key={vertical + horizontal}
        >
          <Alert onClose={handleToastClose} severity="error">
            {error}
          </Alert>
        </Snackbar>

        {/* <Collapse in={openAlert}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpenAlert(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 2, mx: 3, mt: 2 }}
          >
            <MDTypography variant="h6" fontWeight="medium" color="error">
              {error}
            </MDTypography>
          </Alert>
        </Collapse> */}

        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form" onSubmit={handleSubmit}>
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="Username"
                fullWidth
                onChange={(e) => setEmail(e.target.value)}
              />
            </MDBox>

            <MDBox mb={2}>
              <MDInput
                type={showPassword ? "text" : "password"}
                label="Password"
                value={password}
                fullWidth
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
              <FormControlLabel
                control={<Switch checked={showPassword} onChange={handleTogglePassword} />}
                label="Show password"
              />
            </MDBox>
            {/* <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Remember me
              </MDTypography>
            </MDBox> */}
            <MDBox mt={4} mb={1}>
              <MDButton
                disabled={button}
                // onClick={handleToggle}
                type="submit"
                variant="gradient"
                color="info"
                fullWidth
              >
                sign in
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
