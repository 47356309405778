import { Snackbar, Alert } from "@mui/material";

function SuccessfulTransferProfileSnackBar({ showSuccessfulTransferProfileSnackBar, onClose }) {
  return (
    <Snackbar
      open={showSuccessfulTransferProfileSnackBar}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      autoHideDuration={6000}
      onClose={() => onClose(false)}
    >
      <Alert severity="success">Successfully transfering the profile</Alert>
    </Snackbar>
  );
}

export default SuccessfulTransferProfileSnackBar;
