import { useDispatch } from "react-redux";
import { IconButton } from "@mui/material";
import NotListedLocationIcon from "@mui/icons-material/NotListedLocation";
import { useContext } from "react";
import { UserContext } from "context/UserContext";
import { openLocsDialogView } from "../../../../slices/deleteSlice";
import { idDelete, nameDelete } from "../../../../slices/idDeleteSlice";

// import { db, auth } from "../../../firebase-config";

export default function data() {
  const { locations } = useContext(UserContext);
  const dispatch = useDispatch();

  const collArr = [
    { Header: "view", accessor: "view", width: "5%", align: "center" },
    {
      Header: "name",
      accessor: "name",
      width: "30%",
      align: "left",
    },
    { Header: "Location ID 1", accessor: "locationID1", align: "center" },
    { Header: "Dosimeter ID 1", accessor: "dosimeterID1", align: "center" },
    { Header: "Location ID 2", accessor: "locationID2", align: "center" },
    { Header: "Dosimeter ID 2", accessor: "dosimeterID2", align: "center" },
  ];

  const mappedRowsArr = locations.map((e) => ({
    key: e.id,
    view: (
      <IconButton
        color="inherit"
        size="small"
        onClick={() => {
          dispatch(openLocsDialogView());
          dispatch(idDelete(e.id));
          dispatch(nameDelete(e.name));
        }}
      >
        <NotListedLocationIcon fontSize="small" />
      </IconButton>
    ),
    name: e.locationName,
    locationID1: e.locationID1,
    dosimeterID1: e.dosimeterID1,
    locationID2: e.locationID2,
    dosimeterID2: e.dosimeterID2,
  }));

  return {
    columns: collArr,

    rows: mappedRowsArr,
  };
}
