import * as XLSX from "xlsx";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Grid, Card, Backdrop, CircularProgress, Snackbar, Alert } from "@mui/material";
import DataTable from "examples/Tables/DataTable";
import * as React from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { useEffect, useState, useCallback, useContext } from "react";
import { doc, writeBatch } from "firebase/firestore";
import { UserContext } from "context/UserContext";
import MDInput from "components/MDInput";
import { db } from "../../../firebase-config";
import OnlineStatusSnackbar from "../../onlineStatusSnackbar/onlineStatusSnackbar";

function PostAnalysis() {
  useEffect(() => {
    document.title = "Dosimeter Analysis - RadDB";
  }, []);
  const { users } = useContext(UserContext);
  const [arr, setArr] = useState([]);
  const [newShallowDose, setNewShallowDose] = useState([]);
  const [circularOpen, setCircularOpen] = useState(false);

  const onChangeFileActual = useCallback(
    (e) => {
      const [file] = e.target.files;
      const reader = new FileReader();

      reader.onload = (evt) => {
        const jsonObj = [];
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, { type: "binary" });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const dataXLSX = XLSX.utils.sheet_to_csv(ws, { header: 1 }).split("\n");
        // setArr(dataXLSX);

        // Source: https://stackoverflow.com/questions/59016562/parse-csv-records-in-to-an-array-of-objects-in-javascript
        const headers = dataXLSX[0].split(",");
        for (let i = 1; i < dataXLSX.length; i += 1) {
          const dutu = dataXLSX[i].split(",");
          const obj = {};
          for (let j = 0; j < dutu.length; j += 1) {
            obj[headers[j].trim()] = dutu[j].trim();
          }

          jsonObj.push(obj);
        }

        const mapped = jsonObj.filter((element) => element["Serial Number"] !== "");

        setArr(mapped);
      };

      reader.readAsBinaryString(file);
    },
    [arr]
  );

  const [newYear, setNewYear] = useState("");
  const [newMonth, setNewMonth] = useState("");

  const [snackbarOpen, setSnackbarOpen] = useState({
    openSnackBar: false,
    vertical: "top",
    horizontal: "center",
  });

  const [snackbarSuccessOpen, setSnackbarSuccessOpen] = useState({
    openSnackBarSuccess: false,
    vertical: "top",
    horizontal: "center",
  });

  const [newHp007ControlReading, setNewHp007ControlReading] = useState("");

  const setNewHp007ControlReadingFunc = useCallback(
    (e) => {
      setNewHp007ControlReading(e.target.value);
    },
    [newHp007ControlReading]
  );

  const { vertical, horizontal, openSnackBar } = snackbarOpen;

  const { verticalSuccess, horizontalSuccess, openSnackBarSuccess } = snackbarSuccessOpen;

  const filteredArr = users.filter((user) => user?.extDose?.[newYear]?.[newMonth]?.extDoseCode);

  useEffect(() => {
    if (document.getElementById("deepDoseInput")) {
      document.getElementById("deepDoseInput").focus();
    }
  }, [filteredArr.length > 0]);

  useEffect(() => {
    if (document.getElementById("shallowDoseInput")) {
      document.getElementById("shallowDoseInput").focus();
    }
  }, [newYear.toString().length === 4]);

  useEffect(() => {
    if (document.getElementById("actualFile")) {
      document.getElementById("actualFile").focus();
    }
  }, [newHp007ControlReading.length === 4]);

  useEffect(() => {
    for (let i = 0; i < filteredArr.length; i += 1) {
      for (let j = 0; j < arr.length; j += 1) {
        if (arr[j]["Serial Number"] === filteredArr[i].extDose?.[newYear][newMonth].extDoseCode) {
          const x = arr[j]["Deep Dose"];
          filteredArr[i].extDose[newYear][newMonth].extHp007 = parseFloat(parseFloat(x).toFixed(2));
          setNewShallowDose((current) => [
            ...current,
            filteredArr[i].extDose?.[newYear][newMonth].extHp007,
          ]);
        }

        if (arr[j]["Serial Number"] === filteredArr[i].extDose?.[newYear][newMonth].extDoseCode2) {
          const x = arr[j]["Deep Dose"];
          filteredArr[i].extDose[newYear][newMonth].extHp0072 = parseFloat(
            parseFloat(x).toFixed(2)
          );
          setNewShallowDose((current) => [
            ...current,
            filteredArr[i].extDose?.[newYear][newMonth].extHp0072,
          ]);
        }
      }
    }
  }, [arr]);

  const row = [...filteredArr];

  const handleSnackbarOpen = () => {
    setSnackbarOpen({
      openSnackBar: true,
      vertical: "top",
      horizontal: "center",
    });
  };

  const handleSnackbarClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen({
      ...snackbarOpen,
      openSnackBar: false,
    });
  };

  const handleSnackbarSuccessOpen = () => {
    setSnackbarSuccessOpen({
      openSnackBarSuccess: true,
      verticalSuccess: "top",
      horizontalSuccess: "center",
    });
  };

  const handleSnackbarSuccessClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarSuccessOpen({
      ...snackbarSuccessOpen,
      openSnackBarSuccess: false,
    });
  };

  const collArr = [
    {
      Header: "name",
      accessor: "name",
      width: "45%",
      align: "left",
    },
    { Header: "ic number", accessor: "icNum", align: "center" },
    { Header: "ring no.", accessor: "ringNo", align: "center" },
    { Header: "dosimeter code", accessor: "badgeNo", align: "center" },
    { Header: "Background Hp0.07 (mSv)", accessor: "bgHp007", align: "center" },
    { Header: "2nd ring no.", accessor: "ringNo2", align: "center" },
    { Header: "2nd dosimeter code", accessor: "badgeNo2", align: "center" },
    { Header: "2nd Background Hp0.07 (mSv)", accessor: "bgHp0072", align: "center" },
  ];

  const mappedRowsArr = filteredArr.map((e) => ({
    name: e.name,
    icNum: e.icNum,
    ringNo: e.extDose[newYear][newMonth].extRingNo,
    badgeNo: e?.extDose[newYear][newMonth].extDoseCode,
    bgHp007: ((e?.extDose[newYear][newMonth].extBgHp007 * 1000) / 1000).toFixed(3),
    ringNo2: e.extDose[newYear][newMonth].extRingNo2 || "N/A",
    badgeNo2: e?.extDose[newYear][newMonth].extDoseCode2 || "N/A",
    bgHp0072: e?.extDose[newYear][newMonth].extBgHp0072
      ? ((e?.extDose[newYear][newMonth].extBgHp0072 * 1000) / 1000).toFixed(3)
      : "N/A",
  }));

  const collArrWithCtrlRdgAndActualBg = [
    {
      Header: "name",
      accessor: "name",
      width: "35%",
      align: "left",
    },
    { Header: "ic number", accessor: "icNum", align: "center" },
    { Header: "ring no.", accessor: "ringNo", align: "center" },
    { Header: "dosimeter code", accessor: "badgeNo", align: "center" },
    { Header: "actual reading", accessor: "actualHp007", align: "center" },
    { Header: "Background Hp0.07 (mSv)", accessor: "bgHp007", align: "center" },
    { Header: "Hp0.07", accessor: "hp007", align: "center" },
    { Header: "2nd ring no.", accessor: "ringNo2", align: "center" },
    { Header: "2nd dosimeter code", accessor: "badgeNo2", align: "center" },
    { Header: "2nd actual reading", accessor: "actualHp0072", align: "center" },
    { Header: "2nd Background Hp0.07 (mSv)", accessor: "bgHp0072", align: "center" },
    { Header: "2nd Hp0.07", accessor: "hp0072", align: "center" },
    { Header: "average Hp0.07", accessor: "average", align: "center" },
  ];

  const hp007 = (e) =>
    parseFloat(
      Math.round(
        (e?.extDose[newYear][newMonth].extHp007 -
          newHp007ControlReading -
          e?.extDose[newYear][newMonth].extBgHp007) *
          100
      ) / 100
    ).toFixed(2);

  const hp0072 = (e) => {
    if (e?.extDose[newYear][newMonth].extHp0072) {
      return parseFloat(
        Math.round(
          (e?.extDose[newYear][newMonth].extHp0072 -
            newHp007ControlReading -
            e?.extDose[newYear][newMonth].extBgHp0072) *
            100
        ) / 100
      ).toFixed(2);
    }

    return null;
  };

  const average = (e) => {
    if (hp0072(e)) {
      return (
        Math.round((((parseFloat(hp007(e)) + parseFloat(hp0072(e))) * 100) / 2).toFixed(2)) / 100
      ).toFixed(2);
    }

    return (Math.round(hp007(e) * 100) / 100).toFixed(2);
  };

  const mappedRowsArrWithCtrlRdgAndActualBg = row.map((e) => ({
    name: e.name,
    icNum: e.icNum,
    badgeNo: e?.extDose[newYear][newMonth].extDoseCode,
    ringNo: e.extDose[newYear][newMonth].extRingNo,
    actualHp007: e?.extDose[newYear][newMonth].extHp007?.toFixed(2) || "N/A",
    actualHp0072: e?.extDose[newYear][newMonth].extHp0072?.toFixed(2) || "N/A",
    bgHp007: ((e?.extDose[newYear][newMonth].extBgHp007 * 1000) / 1000).toFixed(3) || "N/A",
    ringNo2: e.extDose[newYear][newMonth].extRingNo2 || "N/A",
    badgeNo2: e?.extDose[newYear][newMonth].extDoseCode2 || "N/A",
    bgHp0072: e?.extDose[newYear][newMonth].extHp0072
      ? ((e?.extDose[newYear][newMonth].extBgHp0072 * 1000) / 1000).toFixed(3)
      : "N/A",
    hp007: hp007(e),
    hp0072: e?.extDose[newYear][newMonth].extHp0072 ? hp0072(e) : "N/A",
    average: average(e) < 0.05 ? 0.0 : average(e),
  }));

  const resetHp007InputControlReading = (e) => {
    e.target.value = "";
    setNewHp007ControlReading("");
  };

  useEffect(() => {
    if (document.getElementById("month-select")) {
      document.getElementById("month-select").focus();
    }
  }, [newYear.length === 4]);

  useEffect(() => {
    if (filteredArr.length === 0 && newMonth?.length !== 0) {
      handleSnackbarOpen();
    }

    if (filteredArr.length > 0 && newMonth?.length !== 0) {
      handleSnackbarClose();
    }
  }, [filteredArr.length > 0, newMonth]);

  const handleCircularOpen = () => {
    setCircularOpen(true);
  };

  const handleCircularClose = () => {
    setCircularOpen(false);
  };

  const submit = async (e) => {
    handleCircularOpen();
    e.preventDefault();

    const hp007s = (f) =>
      parseFloat(
        (
          Math.round(
            (f?.extDose[newYear][newMonth].extHp007 -
              newHp007ControlReading -
              f?.extDose[newYear][newMonth].extBgHp007) *
              100
          ) / 100
        ).toFixed(2)
      );

    const hp0072s = (f) => {
      if (f?.extDose[newYear][newMonth].extHp0072) {
        return parseFloat(
          (
            Math.round(
              (f?.extDose[newYear][newMonth].extHp0072 -
                newHp007ControlReading -
                f?.extDose[newYear][newMonth].extBgHp0072) *
                100
            ) / 100
          ).toFixed(2)
        );
      }

      return null;
    };

    const averages = (f) => {
      if (hp0072(f)) {
        return Math.round((((hp007s(f) + hp0072s(f)) * 100) / 2).toFixed(2)) / 100;
      }

      return Math.round(hp007s(f) * 100) / 100;
    };

    const batch = writeBatch(db);
    for (let i = 0; i < row.length; i += 1) {
      batch.update(doc(db, "users", row[i]?.id), {
        // https://dev.to/milesbd/updating-nested-fields-in-firestore-with-dot-notation-53hp
        [`extDose.${newYear}.${newMonth}`]: {
          extRingNo: row[i]?.extDose[newYear][newMonth]?.extRingNo,
          extRingNo2: row[i]?.extDose[newYear][newMonth]?.extRingNo2 || null,
          extDoseCode: row[i]?.extDose[newYear][newMonth]?.extDoseCode,
          extDoseCode2: row[i]?.extDose[newYear][newMonth]?.extDoseCode2 || null,
          extRawHp007: parseFloat(row[i]?.extDose[newYear][newMonth]?.extHp007),
          extRawHp0072: parseFloat(row[i]?.extDose[newYear][newMonth]?.extHp0072) || null,
          extBgHp007: parseFloat(row[i]?.extDose[newYear][newMonth]?.extBgHp007),
          extBgHp0072: parseFloat(row[i]?.extDose[newYear][newMonth]?.extBgHp0072) || null,
          extCtrlValueHp007: parseFloat(newHp007ControlReading),
          extHp007: hp007s(row[i]),
          extHp0072: hp0072s(row[i]),
          average: averages(row[i]) < 0.05 ? 0.0 : averages(row[i]),
        },
      });
    }

    await batch.commit().then(() => {
      handleCircularClose();
      handleSnackbarSuccessOpen();
      setNewYear("");
      setNewMonth("");
    });
  };

  const handleDateChange = (date) => {
    if (date) {
      const { $M, $y } = date;
      const monthMap = {
        0: "January",
        1: "February",
        2: "March",
        3: "April",
        4: "May",
        5: "June",
        6: "July",
        7: "August",
        8: "September",
        9: "October",
        10: "November",
        11: "December",
      };

      setNewMonth(monthMap[$M]);

      setNewYear($y);
    }
  };

  return (
    <DashboardLayout>
      <OnlineStatusSnackbar />
      {filteredArr.length === 0 && newMonth?.length !== 0 && newYear.toString().length === 4 && (
        <Snackbar
          key={vertical + horizontal}
          open={openSnackBar}
          autoHideDuration={6000}
          anchorOrigin={{ vertical, horizontal }}
        >
          <Alert severity="error" onClose={handleSnackbarClose} sx={{ width: "100%" }}>
            No users assigned for {newMonth} {newYear}. Please go to Dosimeter Assignment tab to
            assign dosimeters.
          </Alert>
        </Snackbar>
      )}

      <Snackbar
        key={verticalSuccess + horizontalSuccess}
        open={openSnackBarSuccess}
        autoHideDuration={6000}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert severity="success" onClose={handleSnackbarSuccessClose} sx={{ width: "100%" }}>
          Successfully synced to server
        </Alert>
      </Snackbar>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={circularOpen}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <DashboardNavbar />

      <MDBox py={3}>
        <Card>
          <Grid container spacing={0}>
            <Grid item xs={12} md={4} lg={4}>
              <MDBox pt={4} px={3} sx={{ pb: { xs: 0, sm: 0, md: 3, lg: 3 } }}>
                <MDBox>
                  <MDBox mb={2}>
                    <MDTypography variant="h6">
                      Please select month and year of analysis
                    </MDTypography>
                  </MDBox>
                  <MDBox mb={2}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Month and Year"
                        views={["month", "year"]}
                        onChange={handleDateChange}
                      />
                    </LocalizationProvider>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <MDBox
                px={3}
                sx={{ pt: { xs: 0, sm: 0, md: 4, lg: 4 }, pb: { xs: 0, sm: 0, md: 3, lg: 3 } }}
              >
                <MDBox>
                  {filteredArr.length > 0 &&
                    newMonth.length > 0 &&
                    newYear.toString().length === 4 && (
                      <>
                        <MDBox mb={2}>
                          <MDTypography variant="h6">
                            Hp0.07 control value : {newHp007ControlReading} (mSv)
                          </MDTypography>
                        </MDBox>
                        <MDBox mb={2}>
                          <MDInput
                            id="shallowDoseInput"
                            onFocus={resetHp007InputControlReading}
                            onChange={setNewHp007ControlReadingFunc}
                            inputProps={{ maxLength: 4 }}
                            style={{ width: "150px" }}
                            placeholder="Hp0.07 control value..."
                          />
                        </MDBox>
                      </>
                    )}
                </MDBox>
              </MDBox>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <MDBox pb={3} px={3} sx={{ pt: { xs: 0, sm: 0, md: 4, lg: 4 } }}>
                <MDBox>
                  {filteredArr.length > 0 &&
                    newMonth.length > 0 &&
                    newYear.toString().length === 4 &&
                    newHp007ControlReading.length > 0 && (
                      <>
                        <MDBox mb={2}>
                          <MDTypography variant="h6">
                            Select file of .xls / .xlsx (actual)
                          </MDTypography>
                        </MDBox>
                        <MDBox mb={2}>
                          <MDInput
                            id="actualFile"
                            type="file"
                            accept=".xls,.xlsx"
                            onChange={onChangeFileActual}
                          />
                        </MDBox>
                      </>
                    )}
                </MDBox>
              </MDBox>
            </Grid>
          </Grid>
        </Card>
      </MDBox>

      {filteredArr.length > 0 &&
        newMonth?.length > 0 &&
        newYear.toString().length === 4 &&
        newShallowDose.length === 0 &&
        arr.length === 0 && (
          <MDBox pt={6} pb={3}>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Card>
                  <MDBox
                    mx={2}
                    mt={-3}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                  >
                    <MDTypography variant="h6" color="white">
                      Assigned dosimeters for {newMonth} {newYear}
                    </MDTypography>
                  </MDBox>
                  <MDBox pt={3}>
                    <DataTable
                      table={{ columns: collArr, rows: mappedRowsArr }}
                      entriesPerPage
                      isSorted
                      showTotalEntries
                      noEndBorder
                    />
                  </MDBox>
                </Card>
              </Grid>
            </Grid>
          </MDBox>
        )}

      {filteredArr.length > 0 &&
        newMonth?.length > 0 &&
        newYear.toString().length === 4 &&
        arr.length > 0 && (
          <MDBox pt={6} pb={3}>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Card>
                  <MDBox
                    mx={2}
                    mt={-3}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                  >
                    <MDTypography variant="h6" color="white" mb={2}>
                      Assigned dosimeters for {newMonth} {newYear}
                    </MDTypography>
                    <MDButton
                      variant="contained"
                      color="success"
                      onClick={submit}
                      disabled={
                        !(
                          filteredArr.filter((e) => e.extDose?.[newYear][newMonth].extHp007)
                            .length > 0
                        )
                      }
                    >
                      Sync to server
                    </MDButton>
                  </MDBox>
                  <MDBox pt={3}>
                    <DataTable
                      table={{
                        columns: collArrWithCtrlRdgAndActualBg,
                        rows: mappedRowsArrWithCtrlRdgAndActualBg,
                      }}
                      entriesPerPage
                      isSorted
                      showTotalEntries
                      noEndBorder
                    />
                  </MDBox>
                </Card>
              </Grid>
            </Grid>
          </MDBox>
        )}
      <Footer />
    </DashboardLayout>
  );
}

export default PostAnalysis;
