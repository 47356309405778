import { createContext, useState, useEffect, useMemo } from "react";
import { collection, query, where, onSnapshot, orderBy } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import { db, auth } from "../firebase-config";

export const UserContext = createContext();

export function UserProvider({ children }) {
  const [unfilteredUsers, setUnfilteredUsers] = useState([]);
  const [users, setUsers] = useState([]);
  const [currentUser, setCurrentUser] = useState("");
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    const unsubscribeAuth = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is logged in
        if (user.email === "nuclearmedikn@moh.gov.my") {
          setCurrentUser("Institut Kanser Negara");
        } else if (user.email === "nuclearmedhkl@moh.gov.my") {
          setCurrentUser("Hospital Kuala Lumpur");
        } else if (user.email === "demo@raddosedb.com") {
          setCurrentUser("Demo Profile");
        } else if (user.email === "nuclearmedhpp@moh.gov.my") {
          setCurrentUser("Hospital Pulau Pinang");
        } else if (user.email === "nuclearmedhsa@moh.gov.my") {
          setCurrentUser("Hospital Sultanah Aminah");
        } else if (user.email === "nuclearmedhus@moh.gov.my") {
          setCurrentUser("Hospital Umum Sarawak");
        } else if (user.email === "nuclearmedhwkks@moh.gov.my") {
          setCurrentUser("Hospital Wanita & Kanak-Kanak Sabah");
        } else if (user.email === "aimifirdhausshafie@gmail.com") {
          setCurrentUser("Limbo");
        }
      } else {
        // User is logged out
        setCurrentUser("");
        setUsers([]); // Reset the users state to an empty array
      }
    });

    return () => unsubscribeAuth(); // Unsubscribe from the auth state changes
  }, []);

  useEffect(() => {
    const q = query(
      collection(db, "users"),
      where("hosp", "==", currentUser),
      orderBy("timeStampReg", "desc"),
      orderBy("name")
    );
    const unsubscribeUsers = onSnapshot(q, (snapshot) =>
      setUsers(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
    );

    const p = query(
      collection(db, "locations"),
      where("hosp", "==", currentUser),
      orderBy("timeStampReg", "desc"),
      orderBy("locationName")
    );

    const unsubscribeLocations = onSnapshot(p, (snapshot) =>
      setLocations(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
    );

    return () => {
      unsubscribeUsers(); // Unsubscribe from the users snapshot
      unsubscribeLocations();
    };
  }, [currentUser]);

  useEffect(() => {
    const q = query(collection(db, "users"), orderBy("timeStampReg", "desc"), orderBy("name"));
    const unsubscribeUsers = onSnapshot(q, (snapshot) =>
      setUnfilteredUsers(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
    );

    return () => unsubscribeUsers(); // Unsubscribe from the users snapshot
  }, [currentUser]);

  const memoedUsers = useMemo(
    () => ({
      users,
      unfilteredUsers,
      locations,
    }),
    [users, unfilteredUsers, locations]
  );

  return <UserContext.Provider value={memoedUsers}>{children}</UserContext.Provider>;
}
