/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// import { useState } from "react";

// react-router components
// import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Container from "@mui/material/Container";
// import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";
// import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
// import DefaultNavbarLink from "examples/Navbars/DefaultNavbar/DefaultNavbarLink";
// import DefaultNavbarMobile from "examples/Navbars/DefaultNavbar/DefaultNavbarMobile";

// Material Dashboard 2 React base styles
// import breakpoints from "assets/theme/base/breakpoints";

// Material Dashboard 2 React context
import { useMaterialUIController } from "context";
// import bgImage from "assets/images/logo-light.svg";
import brandDark from "assets/images/logo-dark.svg";
import brandWhite from "assets/images/logo-light.svg";

function DefaultNavbar({ transparent, light }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  // const [mobileNavbar, setMobileNavbar] = useState(false);
  // const [mobileView, setMobileView] = useState(false);

  // const openMobileNavbar = ({ currentTarget }) => setMobileNavbar(currentTarget.parentNode);
  // const closeMobileNavbar = () => setMobileNavbar(false);

  // useEffect(() => {
  //   // A function that sets the display state for the DefaultNavbarMobile.
  //   function displayMobileNavbar() {
  //     if (window.innerWidth < breakpoints.values.lg) {
  //       setMobileView(true);
  //       setMobileNavbar(false);
  //     } else {
  //       setMobileView(false);
  //       setMobileNavbar(false);
  //     }
  //   }

  //   /**
  //    The event listener that's calling the displayMobileNavbar function when
  //    resizing the window.
  //   */
  //   window.addEventListener("resize", displayMobileNavbar);

  //   // Call the displayMobileNavbar function to set the state with the initial value.
  //   displayMobileNavbar();

  //   // Remove event listener on cleanup
  //   return () => window.removeEventListener("resize", displayMobileNavbar);
  // }, []);

  return (
    <Container>
      <MDBox
        py={1}
        px={{ xs: 4, sm: transparent ? 2 : 3, lg: transparent ? 0 : 2 }}
        my={3}
        // mx={3}
        // width="calc(100% - 48px)"
        borderRadius="lg"
        width="300px"
        shadow={transparent ? "none" : "md"}
        color={light ? "white" : "dark"}
        display="flex"
        justifyContent="center"
        alignItems="center"
        position="absolute"
        left="calc(50% - 150px)"
        zIndex={3}
        sx={({
          palette: { transparent: transparentColor, white, background },
          functions: { rgba },
        }) => ({
          backgroundColor: transparent
            ? transparentColor.main
            : rgba(darkMode ? background.sidenav : white.main, 0.8),
          backdropFilter: transparent ? "none" : `saturate(200%) blur(30px)`,
        })}
      >
        <MDBox
          display="flex"
          alignItems="center"
          component="a"
          href="https://raddosedb.com"
          target="_blank"
          py={transparent ? 1.5 : 0.75}
          lineHeight={1}
        >
          <MDBox
            component="img"
            src={darkMode ? brandWhite : brandDark}
            alt="Brand"
            width="15rem"
          />
          {/* <MDTypography
            sx={{ ml: 0.5 }}
            variant="button"
            fontWeight="bold"
            color={light ? "white" : "dark"}
          >
            adiation Dose Database
          </MDTypography> */}
        </MDBox>

        {/* <MDBox
          display={{ xs: "inline-block", lg: "none" }}
          lineHeight={0}
          py={1.5}
          pl={1.5}
          color="inherit"
          sx={{ cursor: "pointer" }}
          // onClick={openMobileNavbar}
        >
           <Icon fontSize="default">{mobileNavbar ? "close" : "menu"}</Icon> 
        </MDBox> */}
      </MDBox>
      {/* {mobileView && <DefaultNavbarMobile open={mobileNavbar} close={closeMobileNavbar} />} */}
    </Container>
  );
}

// Setting default values for the props of DefaultNavbar
DefaultNavbar.defaultProps = {
  transparent: false,
  light: false,
};

// Typechecking props for the DefaultNavbar
DefaultNavbar.propTypes = {
  transparent: PropTypes.bool,
  light: PropTypes.bool,
};

export default DefaultNavbar;
