import { Grid, Card, Snackbar, Alert, Slide } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import DataTable from "examples/Tables/DataTable";
import * as React from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { useEffect, useState, useContext } from "react";
import { Font } from "@react-pdf/renderer";
import { UserContext } from "context/UserContext";
import PDFViewerDialog from "./components/PDFViewer";
import OnlineStatusSnackbar from "../../onlineStatusSnackbar/onlineStatusSnackbar";

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

function AnalysisResults() {
  useEffect(() => {
    document.title = "Analysis Results - RadDB";
  }, []);
  const { users } = useContext(UserContext);
  const [newYear, setNewYear] = useState("");
  const [newMonth, setNewMonth] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState({
    openSnackBar: false,
    vertical: "top",
    horizontal: "center",
  });

  const [snackbarSuccessOpen, setSnackbarSuccessOpen] = useState({
    openSnackBarSuccess: true,
    vertical: "top",
    horizontal: "center",
  });

  const { vertical, horizontal, openSnackBar } = snackbarOpen;

  const { verticalSuccess, horizontalSuccess, openSnackBarSuccess } = snackbarSuccessOpen;

  const filteredArr = Array.from(users)
    .filter((user) => user?.extDose?.[newYear]?.[newMonth]?.extDoseCode)
    .sort((a, b) => {
      // Compare units
      const unitComparison = a.unit.localeCompare(b.unit);
      if (unitComparison !== 0) {
        return unitComparison;
      }

      // Compare professions
      const professionComparison = a.job.localeCompare(b.job);
      if (professionComparison !== 0) {
        return professionComparison;
      }

      // Compare names
      return a.name.localeCompare(b.name);
    });

  const handleSnackbarOpen = () => {
    setSnackbarOpen({
      openSnackBar: true,
      vertical: "top",
      horizontal: "center",
    });
  };

  const handleSnackbarClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen({
      ...snackbarOpen,
      openSnackBar: false,
    });
  };

  const handleSnackbarSuccessClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarSuccessOpen({
      ...snackbarSuccessOpen,
      openSnackBarSuccess: false,
    });
  };

  const handleSnackbarSuccessOpen = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarSuccessOpen({
      ...snackbarSuccessOpen,
      openSnackBarSuccess: true,
    });
  };

  const collArrWithCtrlRdgAndActualBg = [
    {
      Header: "name",
      accessor: "name",
      width: "35%",
      align: "left",
    },
    { Header: "ic number", accessor: "icNum", align: "center" },
    { Header: "ring no.", accessor: "ringNo", align: "center" },
    { Header: "dosimeter code", accessor: "badgeNo", align: "center" },
    { Header: "actual reading", accessor: "actualHp007", align: "center" },
    { Header: "Background Hp0.07 (mSv)", accessor: "bgHp007", align: "center" },
    { Header: "Hp0.07", accessor: "hp007", align: "center" },
    { Header: "2nd ring no.", accessor: "ringNo2", align: "center" },
    { Header: "2nd dosimeter code", accessor: "badgeNo2", align: "center" },
    { Header: "2nd actual reading", accessor: "actualHp0072", align: "center" },
    { Header: "2nd Background Hp0.07 (mSv)", accessor: "bgHp0072", align: "center" },
    { Header: "2nd Hp0.07", accessor: "hp0072", align: "center" },
    { Header: "average Hp0.07", accessor: "average", align: "center" },
  ];

  const mappedRowsArrWithCtrlRdgAndActualBg = filteredArr.map((e) => ({
    name: e.name,
    icNum: e.icNum,
    badgeNo: e?.extDose[newYear][newMonth].extDoseCode,
    ringNo: e.extDose[newYear][newMonth].extRingNo,
    actualHp007: e?.extDose[newYear][newMonth].extRawHp007?.toFixed(2),
    actualHp0072: e?.extDose[newYear][newMonth].extRawHp0072?.toFixed(2) || "N/A",
    bgHp007: Math.round(e?.extDose[newYear][newMonth].extBgHp007 * 1000) / 1000,
    hp007: e?.extDose[newYear][newMonth]?.extHp007?.toFixed(2),
    ringNo2: e.extDose[newYear][newMonth].extRingNo2 || "N/A",
    badgeNo2: e?.extDose[newYear][newMonth].extDoseCode2 || "N/A",
    bgHp0072: Math.round(e?.extDose[newYear][newMonth].extBgHp0072 * 1000) / 1000 || "N/A",
    hp0072: e?.extDose[newYear][newMonth]?.extHp0072?.toFixed(2) || "N/A",
    average: e?.extDose[newYear][newMonth]?.average?.toFixed(2),
  }));

  useEffect(() => {
    if (filteredArr.length === 0 && newMonth?.length !== 0) {
      handleSnackbarOpen();
    }

    if (filteredArr.length > 0 && newMonth?.length !== 0) {
      handleSnackbarClose();
    }

    if (
      filteredArr.length > 0 &&
      newMonth?.length !== 0 &&
      !filteredArr[0]?.extDose[newYear][newMonth].hp007
    ) {
      handleSnackbarSuccessOpen();
    }
  }, [filteredArr.length > 0, newMonth]);

  const [openDialog, setOpenDialog] = useState(false);

  const pdfClicked = () => {
    setOpenDialog(true);
  };

  const closeDialog = () => {
    setOpenDialog(false);
  };

  Font.register({
    family: "Oswald",
    src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
  });

  const handleDateChange = (date) => {
    if (date) {
      const { $M, $y } = date;
      const monthMap = {
        0: "January",
        1: "February",
        2: "March",
        3: "April",
        4: "May",
        5: "June",
        6: "July",
        7: "August",
        8: "September",
        9: "October",
        10: "November",
        11: "December",
      };

      setNewMonth(monthMap[$M]);

      setNewYear($y);
    }
  };

  return (
    <DashboardLayout>
      <OnlineStatusSnackbar />
      {filteredArr.length === 0 && newMonth?.length !== 0 && newYear?.toString().length === 4 && (
        <Snackbar
          key={vertical + horizontal}
          open={openSnackBar}
          autoHideDuration={6000}
          anchorOrigin={{ vertical, horizontal }}
        >
          <Alert severity="error" onClose={handleSnackbarClose} sx={{ width: "100%" }}>
            No users have been assigned dosimeters for the month of {newMonth} {newYear}. Please
            navigate to the Dosimeter Assignment tab to assign dosimeters to the appropriate users.
          </Alert>
        </Snackbar>
      )}

      {filteredArr.length !== 0 &&
        newMonth?.length !== 0 &&
        !filteredArr.some((e) => e?.extDose[newYear][newMonth].average) &&
        newYear?.toString().length === 4 && (
          <Snackbar
            key={verticalSuccess + horizontalSuccess}
            open={openSnackBarSuccess}
            autoHideDuration={6000}
            anchorOrigin={{ vertical, horizontal }}
          >
            <Alert severity="warning" onClose={handleSnackbarSuccessClose} sx={{ width: "100%" }}>
              The dosimeter readings for {newMonth} {newYear} have not yet been analyzed. Please
              navigate to the Dosimeter Analysis tab to complete the analysis.
            </Alert>
          </Snackbar>
        )}

      <PDFViewerDialog
        openDialog={openDialog}
        closeDialog={closeDialog}
        Transition={Transition}
        newMonth={newMonth}
        newYear={newYear}
        filteredArr={filteredArr}
        filteredUsersDept={users[0]?.dept}
        filteredUsersHosp={users[0]?.hosp}
      />

      <DashboardNavbar />

      <MDBox py={3}>
        <Card>
          <Grid container spacing={0}>
            <Grid item xs={12} md={6} lg={6}>
              <MDBox pt={4} px={3} sx={{ pb: { xs: 0, sm: 0, md: 3, lg: 3 } }}>
                <MDBox>
                  <MDBox mb={2}>
                    <MDTypography variant="h6">
                      Please select month and year of results
                    </MDTypography>
                  </MDBox>
                  <MDBox mb={2}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Month and Year"
                        views={["month", "year"]}
                        onChange={handleDateChange}
                      />
                    </LocalizationProvider>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Grid>
          </Grid>
        </Card>
      </MDBox>

      {filteredArr.length > 0 &&
        newMonth?.length > 0 &&
        filteredArr.some((e) => e?.extDose[newYear][newMonth].average) && (
          <MDBox pt={6} pb={3}>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Card>
                  <MDBox
                    mx={2}
                    mt={-3}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                  >
                    <MDTypography variant="h6" color="white" mb={2}>
                      Analysis results of {newMonth} {newYear}
                    </MDTypography>
                    <MDTypography variant="subtitle1" color="white" mb={2}>
                      Hp0.07 control value :{" "}
                      {filteredArr[0]?.extDose[newYear][newMonth].extCtrlValueHp007.toFixed(2)} mSv
                    </MDTypography>
                    <MDBox display="flex" alignItems="center">
                      <MDButton onClick={pdfClicked} variant="contained" color="success">
                        Generate PDF
                      </MDButton>
                    </MDBox>
                  </MDBox>
                  <MDBox pt={3}>
                    <DataTable
                      table={{
                        columns: collArrWithCtrlRdgAndActualBg,
                        rows: mappedRowsArrWithCtrlRdgAndActualBg,
                      }}
                      entriesPerPage
                      isSorted
                      showTotalEntries
                      noEndBorder
                    />
                  </MDBox>
                </Card>
              </Grid>
            </Grid>
          </MDBox>
        )}
      <Footer />
    </DashboardLayout>
  );
}

export default AnalysisResults;
