import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import * as React from "react";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
// import DefaultLineChart from "examples/Charts/LineCharts/DefaultLineChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
// import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Data
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import maximumHp10Data from "layouts/dashboard/data/maximumHp10Data";
// import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";

import MDTypography from "components/MDTypography";

// Dashboard components
import Projects from "layouts/dashboard/components/Projects";
// import OrdersOverview from "layouts/dashboard/components/OrdersOverview";

import { useEffect, useMemo, useContext, useState, useRef } from "react";
import { Skeleton, Card, Icon, Divider, Tooltip } from "@mui/material";
import { UserContext } from "context/UserContext";
// import maximumCumulativeHp10Data from "./data/maximumCumulativeHp10Data";
import PieChart from "examples/Charts/PieChart";
import { useMaterialUIController } from "context";
import professionsFunction from "./data/professions";
import OnlineStatusSnackbar from "../onlineStatusSnackbar/onlineStatusSnackbar";

function Dashboard() {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const { users, locations } = useContext(UserContext);
  const [now, setNow] = useState(new Date().getTime());
  const intervalIdRef = useRef(null);
  const [yearMaxHp10, setYearMaxHp10] = useState(new Date().getFullYear());
  // const [yearMaxCumuHp10, setYearMaxCumuHp10] = useState(new Date().getFullYear());
  // const [yearAnalysedUsers, setYearAnalysedUsers] = useState(new Date().getFullYear());
  // console.log(now);
  // const { tasks } = reportsLineChartData;
  // const [co57reading, setCo57reading] = useState("");
  // const [sources, setSources] = useState([]);
  // const usersCollectionRef = collection(db, "users");
  // const sourcesCollectionRef = collection(db, "radioactivesources");
  const chartData = reportsBarChartData(yearMaxHp10);
  const chartDataMaxHp10 = maximumHp10Data(yearMaxHp10);
  const professionData = professionsFunction();
  // const chartDataMaxCumulativeHp10 = maximumCumulativeHp10Data(yearMaxHp10);
  // const [dayArray, setDayArray] = useState([]);
  // const [readingCo, setReadingCo] = useState([]);

  // const latestActCalc = () => {
  //   const initialDate = new Date("2022-12-01T08:00:00").getTime();
  //   const initialAct = 36;

  //   const lapsedDay = (new Date().getTime() - initialDate) / 1000 / 24 / 3600;

  //   const day = Array.from({ length: Math.round(lapsedDay) + 50 }, (_, i) => i);

  //   setDayArray([...day]);

  //   const emptyArr = [];
  //   for (let i = initialDate; i < new Date().getTime(); i += 3600 * 24) {
  //     const latestAct =
  //       initialAct * Math.exp(-(Math.log(2) / 271.8 / 24 / 3600) * ((i - initialDate) / 1000));

  //     emptyArr.push(latestAct);
  //   }

  //   setReadingCo([...emptyArr]);
  // };

  useEffect(() => {
    document.title = "Dashboard - RadDB";

    intervalIdRef.current = setInterval(() => setNow(new Date().getTime()), 1000);

    return () => clearInterval(intervalIdRef.current);
  }, []);

  const memoizedChartDataMaxHp10 = useMemo(
    () => chartDataMaxHp10,
    [JSON.stringify(users), yearMaxHp10]
  );

  const memoizedProfessionsFunction = useMemo(
    () => professionData,
    [JSON.stringify(users), darkMode]
  );
  // const memoizedChartDataMaxCumulativeHp10 = useMemo(
  //   () => chartDataMaxCumulativeHp10,
  //   [JSON.stringify(users), yearMaxHp10]
  // );
  const memoizedChartData = useMemo(() => chartData, [JSON.stringify(users), yearMaxHp10]);
  const radiationWorkersNum = users.length;
  const activeRadiationWorkersNum = users.filter((e) => e.active).length;

  const expiredArr = users.filter((e) => new Date(e.nextCheckUp.seconds * 1000) < new Date());

  // const mappedSourcesDate = sources.map((e) => new Date(e.nextExchange.seconds * 1000).getDate());

  // const mappedSourcesMonth = sources.map((e) =>
  //   new Date(e.nextExchange.seconds * 1000).toLocaleString("en-US", { month: "short" })
  // );

  // const mappedSourcesYear = sources.map((e) =>
  //   new Date(e.nextExchange.seconds * 1000).getFullYear()
  // );

  // function padTo2Digits(num) {
  //   return String(num).padStart(2, "0");
  // }

  // const hourAndMinutes = padTo2Digits(
  //   `${padTo2Digits(
  //     sources.map((e) => new Date(e.nextExchange.seconds * 1000).getHours())
  //   )}:${padTo2Digits(sources.map((e) => new Date(e.nextExchange.seconds * 1000).getMinutes()))}`
  // );

  const latestDate = Math.max(...users.map((e) => e?.timeStampReg?.seconds * 1000));

  const latestRegisterDate = new Date(latestDate).getDate();

  const latestRegisterMonth = new Date(latestDate).toLocaleString("en-US", { month: "short" });

  const latestRegisterYear = new Date(latestDate).getFullYear();

  const nextCheckUp = users.filter((e) => e?.nextCheckUp?.seconds * 1000 > now);

  const nearestNextCheckUp = Math.min(...nextCheckUp.map((e) => e?.nextCheckUp?.seconds * 1000));

  const arrayOfNextCheckUp = users.filter(
    (e) => e?.nextCheckUp?.seconds * 1000 === nearestNextCheckUp
  );

  const dayNextCheckup = Math.floor((nearestNextCheckUp - now) / 86400 / 1000) + 1;

  // const initialAct = 2542;

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     const roundedResults = sources.map(
  //       (e) =>
  //         initialAct *
  //         Math.E **
  //           (-(Math.log(2) / 271.8 / 24 / 3600) *
  //             (new Date().getTime() / 1000 - e.nextExchange.seconds))
  //     );
  //     setCo57reading(Number(roundedResults).toFixed(3));
  //   }, 1000);

  //   return () => clearInterval(interval);
  // }, [sources]);

  return (
    <DashboardLayout>
      <OnlineStatusSnackbar />
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox>
              {/* <ComplexStatisticsCard
                color="dark"
                icon="hail"
                title="Total Radiation Workers"
                count={
                  radiationWorkersNum !== 0 ? (
                    radiationWorkersNum
                  ) : (
                    <Skeleton variant="text" height={33} />
                  )
                }
                percentage={{
                  color: "success",
                  amount: `${latestRegisterDate} ${latestRegisterMonth} ${latestRegisterYear}`,
                  label: ": latest registration",
                }}
              /> */}
              <Card sx={{ pt: "4px" }}>
                <MDBox display="flex" justifyContent="space-between" pt={1} px={2}>
                  <MDBox
                    variant="gradient"
                    bgColor="dark"
                    color="white"
                    coloredShadow="dark"
                    borderRadius="xl"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width="4rem"
                    height="4rem"
                    mt={-3.5}
                  >
                    <Icon fontSize="medium" color="inherit">
                      hail
                    </Icon>
                  </MDBox>
                  <MDBox
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-end"
                    textAlign="right"
                    lineHeight={1.25}
                  >
                    <MDTypography variant="button" fontWeight="light" color="text">
                      Total Active Workers
                    </MDTypography>
                    {(() => {
                      if (radiationWorkersNum === 0)
                        return <Skeleton variant="text" height={33} width={90} />;

                      return (
                        <MDTypography variant="h4">
                          {activeRadiationWorkersNum} / {radiationWorkersNum}
                        </MDTypography>
                      );
                    })()}
                  </MDBox>
                </MDBox>
                <Divider />
                <MDBox pb={2} px={2}>
                  <MDTypography component="p" variant="button" color="text" display="flex">
                    {(() => {
                      if (radiationWorkersNum === 0)
                        return <Skeleton variant="text" height={22} width={84} />;

                      return (
                        <MDTypography
                          component="span"
                          variant="button"
                          fontWeight="bold"
                          color="success"
                        >
                          {latestRegisterDate} {latestRegisterMonth} {latestRegisterYear}
                        </MDTypography>
                      );
                    })()}
                    &nbsp;: latest registration
                  </MDTypography>
                </MDBox>
              </Card>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox sx={{ mt: { xs: 1.5, sm: 1.5, md: 0, lg: 0 } }}>
              <Card sx={{ pt: "4px" }}>
                <MDBox display="flex" justifyContent="space-between" pt={1} px={2}>
                  <MDBox
                    variant="gradient"
                    bgColor="info"
                    color="white"
                    coloredShadow="info"
                    borderRadius="xl"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width="4rem"
                    height="4rem"
                    mt={-3.5}
                  >
                    <Icon fontSize="medium" color="inherit">
                      warning
                    </Icon>
                  </MDBox>
                  <MDBox
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-end"
                    textAlign="right"
                    lineHeight={1.25}
                  >
                    <MDTypography variant="button" fontWeight="light" color="text">
                      MedCheckup Expired
                    </MDTypography>
                    {(() => {
                      if (radiationWorkersNum === 0)
                        return <Skeleton variant="text" height={33} width={30} />;

                      if (radiationWorkersNum !== 0 && expiredArr.length > 0)
                        return (
                          <Tooltip
                            // https://stackoverflow.com/questions/56844501/customize-material-ui-tooltip
                            componentsProps={{
                              tooltip: {
                                sx: {
                                  textAlign: "start",
                                  maxWidth: "none",
                                },
                              },
                            }}
                            title={expiredArr.map((e, i) => (
                              <span id="tooltip" key={e.icNum} style={{ whitespace: "pre-line" }}>
                                {i + 1}. {e.name}
                                <br />
                              </span>
                            ))}
                            enterTouchDelay={0}
                            leaveTouchDelay={0}
                          >
                            <MDTypography variant="h4">{expiredArr.length}</MDTypography>
                          </Tooltip>
                        );

                      return <MDTypography variant="h4">0</MDTypography>;
                    })()}
                  </MDBox>
                </MDBox>
                <Divider />
                <MDBox pb={2} px={2}>
                  <MDTypography component="p" variant="button" color="text" display="flex">
                    {(() => {
                      if (radiationWorkersNum === 0)
                        return <Skeleton variant="text" height={22} width={45} />;

                      if (radiationWorkersNum !== 0 && expiredArr.length === 0)
                        return (
                          <MDTypography component="span" variant="button">
                            Congratulations! 🥳 Here&#39;s a cookie 🍪
                          </MDTypography>
                        );

                      return (
                        <span>
                          <MDTypography
                            component="span"
                            variant="button"
                            fontWeight="bold"
                            color="success"
                          >
                            {Math.round((expiredArr.length / radiationWorkersNum) * 100 * 100) /
                              100}
                            %
                          </MDTypography>
                          <MDTypography
                            component="span"
                            variant="button"
                            // fontWeight="bold"
                            color="text"
                          >
                            {" "}
                            of total radiation workers
                          </MDTypography>
                        </span>
                      );
                    })()}
                  </MDTypography>
                </MDBox>
              </Card>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox sx={{ mt: { xs: 1.5, sm: 1.5, md: 1, lg: 0 } }}>
              <Card sx={{ pt: "4px" }}>
                <MDBox display="flex" justifyContent="space-between" pt={1} px={2}>
                  <MDBox
                    variant="gradient"
                    bgColor="primary"
                    color="white"
                    coloredShadow="primary"
                    borderRadius="xl"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width="4rem"
                    height="4rem"
                    mt={-3.5}
                  >
                    <Icon fontSize="medium" color="inherit">
                      monitor_heart
                    </Icon>
                  </MDBox>
                  <MDBox
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-end"
                    textAlign="right"
                    lineHeight={1.25}
                  >
                    <MDTypography variant="button" fontWeight="light" color="text">
                      Next MedCheckup in
                    </MDTypography>
                    {(() => {
                      if (radiationWorkersNum === 0)
                        return <Skeleton variant="text" height={33} width={70} />;

                      if (radiationWorkersNum !== 0 && dayNextCheckup === 1)
                        return (
                          <Tooltip
                            title={`${new Date(nearestNextCheckUp).getDate()}/${
                              new Date(nearestNextCheckUp).getMonth() + 1
                            }/${new Date(nearestNextCheckUp).getFullYear()}`}
                            enterTouchDelay={0}
                          >
                            <MDTypography variant="h4">{dayNextCheckup} day</MDTypography>
                          </Tooltip>
                        );

                      return (
                        <Tooltip
                          title={
                            expiredArr.length === users.length
                              ? "To infinity and beyond 🚀"
                              : `${new Date(nearestNextCheckUp).getDate()}/${
                                  new Date(nearestNextCheckUp).getMonth() + 1
                                }/${new Date(nearestNextCheckUp).getFullYear()}`
                          }
                          enterTouchDelay={0}
                        >
                          <MDTypography variant="h4">{dayNextCheckup} days</MDTypography>
                        </Tooltip>
                      );
                    })()}
                  </MDBox>
                </MDBox>
                <Divider />
                <MDBox pb={2} px={2}>
                  <MDTypography noWrap component="p" variant="button" color="text" display="flex">
                    {/* {arrayOfNextCheckUp.length === 1 ? "Worker" : "Workers"} : &nbsp; */}
                    {(() => {
                      if (radiationWorkersNum === 0)
                        return <Skeleton variant="text" height={22} width={180} />;

                      if (radiationWorkersNum !== 0 && arrayOfNextCheckUp.length > 1)
                        return (
                          <span>
                            <MDTypography
                              component="span"
                              variant="button"
                              fontWeight="bold"
                              color="success"
                            >
                              {arrayOfNextCheckUp[0]?.name}
                            </MDTypography>
                            <Tooltip
                              componentsProps={{
                                tooltip: {
                                  sx: {
                                    textAlign: "start",
                                    maxWidth: "none",
                                  },
                                },
                              }}
                              title={
                                <>
                                  {arrayOfNextCheckUp
                                    .slice(1) // Exclude the first name
                                    .map((e, index) => (
                                      <span id="tooltip" key={e.icNum}>
                                        {index + 2}. {e.name}
                                        {index < arrayOfNextCheckUp.length - 2 ? <br /> : null}
                                      </span>
                                    ))}
                                </>
                              }
                              enterTouchDelay={0}
                              leaveTouchDelay={0}
                            >
                              <MDTypography
                                component="span"
                                variant="button"
                                // fontWeight="bold"
                                color="text"
                              >
                                {" "}
                                + {arrayOfNextCheckUp.length - 1}
                              </MDTypography>
                            </Tooltip>
                          </span>
                        );

                      if (radiationWorkersNum !== 0 && expiredArr.length === users.length)
                        return (
                          <span>
                            <MDTypography
                              component="span"
                              variant="button"
                              // fontWeight="bold"
                              color="error"
                            >
                              Queue empty, please take note! 🚨
                            </MDTypography>
                          </span>
                        );

                      return (
                        <MDTypography
                          component="span"
                          variant="button"
                          fontWeight="bold"
                          color="success"
                        >
                          {arrayOfNextCheckUp[0]?.name}
                        </MDTypography>
                      );
                    })()}
                  </MDTypography>
                </MDBox>
              </Card>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox sx={{ mt: { xs: 1.5, sm: 1.5, md: 1, lg: 0 } }}>
              <Card sx={{ pt: "4px" }}>
                <MDBox display="flex" justifyContent="space-between" pt={1} px={2}>
                  <MDBox
                    variant="gradient"
                    bgColor="warning"
                    color="white"
                    coloredShadow="primary"
                    borderRadius="xl"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width="4rem"
                    height="4rem"
                    mt={-3.5}
                  >
                    <Icon fontSize="medium" color="inherit">
                      location_searching
                    </Icon>
                  </MDBox>
                  <MDBox
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-end"
                    textAlign="right"
                    lineHeight={1.25}
                  >
                    <MDTypography variant="button" fontWeight="light" color="text">
                      {locations.length === 1 ? "Location assigned" : "Locations assigned"}
                    </MDTypography>
                    {(() => {
                      if (locations.length === 0)
                        return <Skeleton variant="text" height={33} width={70} />;

                      return <MDTypography variant="h4">{locations.length}</MDTypography>;
                    })()}
                  </MDBox>
                </MDBox>
                <Divider />
                <MDBox pb={2} px={2}>
                  <MDTypography noWrap component="p" variant="button" color="text" display="flex">
                    {locations.length === 1 ? "Location" : "Locations"} : &nbsp;
                    {(() => {
                      if (locations.length === 0)
                        return <Skeleton variant="text" height={22} width={180} />;

                      return (
                        <MDTypography
                          component="span"
                          variant="button"
                          fontWeight="bold"
                          color="success"
                        >
                          {locations[0]?.locationName}
                        </MDTypography>
                      );
                    })()}
                  </MDTypography>
                </MDBox>
              </Card>
            </MDBox>
          </Grid>
          {/* <Grid item xs={12} md={6} lg={3}>
            <MDBox sx={{ mb: { xs: 0, sm: 0, md: 0, lg: 0 } }}>
              <Card sx={{ pt: "4px" }}>
                <MDBox display="flex" justifyContent="space-between" pt={1} px={2}>
                  <MDBox
                    variant="gradient"
                    bgColor="success"
                    color="white"
                    coloredShadow="success"
                    borderRadius="xl"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width="4rem"
                    height="4rem"
                    mt={-3.5}
                  >
                    <Icon fontSize="medium" color="inherit">
                      event_available
                    </Icon>
                  </MDBox>
                  <MDBox
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-end"
                    textAlign="right"
                    lineHeight={1.25}
                  >
                    <MDTypography variant="button" fontWeight="light" color="text">
                      Realtime Co-57 activity
                    </MDTypography>
                    {(() => {
                      if (radiationWorkersNum === 0)
                        return <Skeleton variant="text" height={33} width={70} />;

                      return <MDTypography variant="h4">{co57reading} Ci</MDTypography>;
                    })()}
                  </MDBox>
                </MDBox>
                <Divider />
                <MDBox pb={2} px={2}>
                  <MDTypography noWrap component="p" variant="button" color="text" display="flex">
                    A<sup>o</sup>&nbsp; : &nbsp;{initialAct} Ci @&nbsp;
                    {(() => {
                      if (co57reading)
                        return (
                          <MDTypography
                            component="span"
                            variant="button"
                            fontWeight="bold"
                            color="success"
                          >
                            {mappedSourcesDate} {mappedSourcesMonth} {mappedSourcesYear}{" "}
                            {hourAndMinutes}
                          </MDTypography>
                        );

                      return <Skeleton variant="text" height={22} width={180} />;
                    })()}
                  </MDTypography>
                </MDBox>
              </Card>
            </MDBox>
          </Grid> */}
          {/* <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="event_available"
                title="Co-57 Activity"
                count={`${co57reading} Ci`}
                percentage={{
                  color: "success",
                  amount: `${mappedSourcesDate} ${mappedSourcesMonth} ${mappedSourcesYear}`,
                  label: ": replacement date",
                }}
              />
            </MDBox>
          </Grid> */}
        </Grid>
        <MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={6}>
              <MDBox sx={{ mt: { xs: 5.5, sm: 5.5, md: 5, lg: 4.5 } }}>
                <ReportsLineChart
                  color="dark"
                  title="Maximum Hp10 Recorded (mSv)"
                  description="Both cumulative and monthly"
                  chart={memoizedChartDataMaxHp10}
                  handleYearChange={(value) => setYearMaxHp10(value?.$y)}
                  value={yearMaxHp10}
                  useDate
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <MDBox sx={{ mt: { xs: 2.5, sm: 2.5, md: 5, lg: 4.5 } }}>
                <ReportsBarChart
                  color="secondary"
                  title="Assigned & Analysed Dosimeters"
                  description={`Throughout the Year ${yearMaxHp10}`}
                  chart={memoizedChartData}
                  handleYearChange={(value) => setYearMaxHp10(value?.$y)}
                  value={yearMaxHp10}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={6}>
              <MDBox sx={{ mt: { xs: 4.5, sm: 4.5, md: 4, lg: 3.5 } }}>
                <PieChart
                  title="Professions Distribution"
                  description="Current Active Workers"
                  icon={{ component: "groups", color: "success" }}
                  chart={memoizedProfessionsFunction}
                  users={users}
                />
              </MDBox>
            </Grid>
            {/* <Grid item xs={12} md={6} lg={6}>
              <MDBox sx={{ mb: 0, mt: { xs: 3, sm: 3, md: 4.5, lg: 4.5 } }}>
                <ReportsBarChart
                  color="secondary"
                  title="Analysed Users"
                  description="Throughout the Year"
                  chart={memoizedChartData}
                  handleYearChange={(value) => setYearMaxHp10(value?.$y)}
                  value={yearMaxHp10}
                />
              </MDBox>
            </Grid> */}
            <Grid item xs={12} md={6} lg={6}>
              <MDBox sx={{ mt: { xs: -0.5, sm: 0, md: 2, lg: 1.5 } }}>
                <Projects />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>

        {/* <Grid item xs={12} md={6} lg={4}>
              <MDBox sx={{ mb: { xs: 3, sm: 3, md: 0, lg: 0 } }}>
                <ReportsLineChart
                  color="success"
                  title="radiation workers number"
                  description={
                    <>
                      (<strong>+15%</strong>) increase.
                    </>
                  }
                  date="updated 4 min ago"
                  chart={sales}
                />
              </MDBox>
            </Grid> */}
        {/* </Grid><Grid item xs={12} md={6} lg={4}> */}
        {/* <Grid item xs={12} md={12} lg={12}>
              <MDBox mb={0}>
                <ReportsLineChart
                  color="warning"
                  title="Co-57 current activity"
                  description="Since last replacement"
                  date="just updated"
                  chart={{
                    labels: ["Day 0", "Day 74", "Day 148", "Day 222", "Day 296"],
                    datasets: { label: "Activity (\u03BCCi)", data: [100, 100, 100, 100, 100] },
                  }}
                />
              </MDBox>
            </Grid> 
          </Grid>
        </MDBox> */}
        {/* <MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={8}>
              <Projects />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <OrdersOverview />
            </Grid>
          </Grid>
        </MDBox> */}
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
