import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";

import MDButton from "components/MDButton";

export default function DialogConfirmation({
  openConfirmationDialog,
  newName,
  handleCloseConfirmationDialog,
  dispatchCloseDialogView,
}) {
  return (
    <Dialog
      open={openConfirmationDialog}
      maxWidth="sm"
      fullWidth
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Are you sure you want to delete the profile of {newName}?
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Deleted data can&apos;t be recovered
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <MDButton
          autoFocus
          variant="outlined"
          color="warning"
          onClick={handleCloseConfirmationDialog}
        >
          Cancel
        </MDButton>
        <MDButton variant="outlined" color="error" onClick={dispatchCloseDialogView}>
          Just delete
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}
