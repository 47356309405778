import { useNavigate } from "react-router-dom";
import { Card, Grid, Backdrop, CircularProgress } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { addDoc, collection, Timestamp } from "firebase/firestore";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useContext, useEffect, useState } from "react";
import Footer from "examples/Footer";
import { useDispatch, useSelector } from "react-redux";
import { circularProgressOpen, circularProgressClose } from "slices/idDeleteSlice";
import { UserContext } from "context/UserContext";
import OnlineStatusSnackbar from "../../onlineStatusSnackbar/onlineStatusSnackbar";
import { db, auth } from "../../../firebase-config";

function EnvRegister() {
  const { locations } = useContext(UserContext);
  const currentUser = auth?.currentUser?.email;
  const usersCollectionRef = collection(db, "locations");
  useEffect(() => {
    document.title = "Locations Assignment - RadDB";
  }, []);
  const dispatch = useDispatch();
  const { circularProgressStatus } = useSelector((state) => state.idDelete);
  const navigate = useNavigate();

  const [dosimeterID1, setDosimeterID1] = useState("");
  const [dosimeterID2, setDosimeterID2] = useState("");
  const [locationID1, setLocationID1] = useState("");
  const [locationID2, setLocationID2] = useState("");
  const [locationName, setLocationName] = useState("");
  const [errorLocID1, setErrorLocID1] = useState("");
  const [errorLocID2, setErrorLocID2] = useState("");
  const [errorDosID1, setErrorDosID1] = useState("");
  const [errorDosID2, setErrorDosID2] = useState("");

  const onLocationID1Change = (e) => {
    const newValue = e.target.value;

    if (
      locations.filter((f) => f.locationID1.toLowerCase() === newValue.toLowerCase()).length > 0 ||
      locations.filter((f) => f.locationID2.toLowerCase() === newValue.toLowerCase()).length > 0
    ) {
      setErrorLocID1("Location ID already existed in database");
      setLocationID1(newValue);
    } else if (locationID2.toLowerCase() === newValue.toLowerCase() && newValue.length > 0) {
      setErrorLocID1("Location ID already assigned to Location ID 2");
      setLocationID1(newValue);
    } else if (
      locations.filter((f) => f.locationID1.toLowerCase() === locationID1.toLowerCase()).length ===
        0 &&
      locations.filter((f) => f.locationID1.toLowerCase() === locationID2.toLowerCase()).length ===
        0 &&
      locations.filter((f) => f.locationID2.toLowerCase() === locationID2.toLowerCase()).length ===
        0 &&
      locations.filter((f) => f.locationID2.toLowerCase() === locationID1.toLowerCase()).length ===
        0 &&
      locationID2.toLowerCase() !== newValue.toLowerCase()
    ) {
      setErrorLocID1("");
      setErrorLocID2("");
      setLocationID1(newValue);
    } else {
      setErrorLocID1("");
      setLocationID1(newValue);
    }
  };

  const onLocationID2Change = (e) => {
    const newValue = e.target.value;

    if (
      locations.filter((f) => f.locationID1.toLowerCase() === newValue.toLowerCase()).length > 0 ||
      locations.filter((f) => f.locationID2.toLowerCase() === newValue.toLowerCase()).length > 0
    ) {
      setErrorLocID2("Location ID already existed in database");
      setLocationID2(newValue);
    } else if (locationID1.toLowerCase() === newValue.toLowerCase() && newValue.length > 0) {
      setErrorLocID2("Location ID already assigned to Location ID 1");
      setLocationID2(newValue);
    } else if (
      locations.filter((f) => f.locationID1.toLowerCase() === locationID1.toLowerCase()).length ===
        0 &&
      locations.filter((f) => f.locationID1.toLowerCase() === locationID2.toLowerCase()).length ===
        0 &&
      locations.filter((f) => f.locationID2.toLowerCase() === locationID2.toLowerCase()).length ===
        0 &&
      locations.filter((f) => f.locationID2.toLowerCase() === locationID1.toLowerCase()).length ===
        0 &&
      locationID1.toLowerCase() !== newValue.toLowerCase()
    ) {
      setErrorLocID1("");
      setErrorLocID2("");
      setLocationID2(newValue);
    } else {
      setErrorLocID2("");
      setLocationID2(newValue);
    }
  };

  const onDosimeterID1Change = (e) => {
    const newValue = e.target.value;

    if (newValue.length < 11 && newValue.length > 0) {
      setErrorDosID1("Dosimeter ID should be 11 characters");
      setDosimeterID1(newValue);
    } else if (
      locations.filter((f) => f.dosimeterID1.toLowerCase() === newValue.toLowerCase()).length > 0 ||
      locations.filter((f) => f.dosimeterID2.toLowerCase() === newValue.toLowerCase()).length > 0
    ) {
      setErrorDosID1("Dosimeter ID already existed in database");
      setDosimeterID1(newValue);
    } else if (dosimeterID2.toLowerCase() === newValue.toLowerCase() && newValue.length > 0) {
      setErrorDosID1("Dosimeter ID already assigned to Dosimeter ID 2");
      setDosimeterID1(newValue);
    } else if (
      locations.filter((f) => f.dosimeterID1.toLowerCase() === dosimeterID1.toLowerCase())
        .length === 0 &&
      locations.filter((f) => f.dosimeterID1.toLowerCase() === dosimeterID2.toLowerCase())
        .length === 0 &&
      locations.filter((f) => f.dosimeterID2.toLowerCase() === dosimeterID2.toLowerCase())
        .length === 0 &&
      locations.filter((f) => f.dosimeterID2.toLowerCase() === dosimeterID1.toLowerCase())
        .length === 0 &&
      dosimeterID2.toLowerCase() !== newValue.toLowerCase()
    ) {
      setErrorDosID1("");
      setErrorDosID2("");
      setDosimeterID1(newValue);
    } else {
      setErrorDosID1("");
      setDosimeterID1(newValue);
    }
  };

  const onDosimeterID2Change = (e) => {
    const newValue = e.target.value;

    if (newValue.length < 11 && newValue.length > 0) {
      setErrorDosID2("Dosimeter ID should be 11 characters");
      setDosimeterID2(newValue);
    } else if (
      locations.filter((f) => f.dosimeterID1.toLowerCase() === newValue.toLowerCase()).length > 0 ||
      locations.filter((f) => f.dosimeterID2.toLowerCase() === newValue.toLowerCase()).length > 0
    ) {
      setErrorDosID2("Dosimeter ID already existed in database");
      setDosimeterID2(newValue);
    } else if (dosimeterID1.toLowerCase() === newValue.toLowerCase() && newValue.length > 0) {
      setErrorDosID2("Dosimeter ID already assigned to Dosimeter ID 1");
      setDosimeterID2(newValue);
    } else if (
      locations.filter((f) => f.dosimeterID1.toLowerCase() === dosimeterID1.toLowerCase())
        .length === 0 &&
      locations.filter((f) => f.dosimeterID1.toLowerCase() === dosimeterID2.toLowerCase())
        .length === 0 &&
      locations.filter((f) => f.dosimeterID2.toLowerCase() === dosimeterID2.toLowerCase())
        .length === 0 &&
      locations.filter((f) => f.dosimeterID2.toLowerCase() === dosimeterID1.toLowerCase())
        .length === 0 &&
      dosimeterID2.toLowerCase() !== newValue.toLowerCase()
    ) {
      setErrorDosID1("");
      setErrorDosID2("");
      setDosimeterID2(newValue);
    } else {
      setErrorDosID2("");
      setDosimeterID2(newValue);
    }
  };

  // const [openAlert, setOpenAlert] = useState(false);
  // const [state, setState] = useState({
  //   openToast: false,
  //   vertical: "top",
  //   horizontal: "center",
  // });

  // const { vertical, horizontal, openToast } = state;

  // const handleToastOpen = () => {
  //   setState({ openToast: true, vertical: "top", horizontal: "center" });
  // };

  // const handleToastClose = () => {
  //   setState({ ...state, openToast: false });
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // setError("");
    try {
      await addDoc(usersCollectionRef, {
        timeStampReg: Timestamp.fromDate(new Date()),
        dosimeterID1,
        dosimeterID2,
        locationID1,
        locationID2,
        locationName,
        hosp: (() => {
          if (currentUser === "nuclearmedhkl@moh.gov.my") {
            return "Hospital Kuala Lumpur";
          }

          if (currentUser === "nuclearmedikn@moh.gov.my") {
            return "Institut Kanser Negara";
          }

          if (currentUser === "demo@raddosedb.com") {
            return "Demo Profile";
          }

          if (currentUser === "nuclearmedhpp@moh.gov.my") {
            return "Hospital Pulau Pinang";
          }

          if (currentUser === "nuclearmedhsa@moh.gov.my") {
            return "Hospital Sultanah Aminah";
          }

          if (currentUser === "nuclearmedhus@moh.gov.my") {
            return "Hospital Umum Sarawak";
          }

          if (currentUser === "nuclearmedhwkks@moh.gov.my") {
            return "Hospital Wanita & Kanak-Kanak Sabah";
          }

          return "Limbo";
        })(),
      });
      navigate("/environmental-dosimeter/locations-list");
      dispatch(circularProgressClose());
    } catch (err) {
      // setError(err.message);
      // handleToastOpen();
    }
  };

  // const [button, setButton] = useState(true);

  // const toggleButton = () => {
  //   setButton(!button);
  // };

  const height = { height: 44 };

  return (
    <DashboardLayout>
      <OnlineStatusSnackbar />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={circularProgressStatus}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {/* <Snackbar
        autoHideDuration={4000}
        anchorOrigin={{ vertical, horizontal }}
        open={openToast}
        onClose={handleToastClose}
        message="I love snacks"
        key={vertical + horizontal}
      >
        <Alert onClose={handleToastClose} severity="error">
          <MDTypography variant="h6" fontWeight="medium" color="error">
            {error}
          </MDTypography>
        </Alert>
      </Snackbar> */}

      {/* <Dialog open={duplicateIc && newIcNum.length === 12}>
        <>
          <DialogTitle>
            The IC number {newIcNum} belongs to the user{" "}
            {users.filter((e) => e.icNum === newIcNum)[0]?.name} from{" "}
            {users.filter((e) => e.icNum === newIcNum)[0]?.hosp}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>Please ensure the entered IC number is correct</DialogContentText>
          </DialogContent>
          <DialogActions>
            <MDButton
              autoFocus
              variant="outlined"
              onClick={() => setDuplicateIc(false)}
              color="error"
            >
              Okay
            </MDButton>
          </DialogActions>
        </>
      </Dialog> */}
      {/* <Collapse in={openAlert}>
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => setOpenAlert(false)}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2, mx: 3, mt: 2 }}
        >
          <MDTypography variant="h6" fontWeight="medium" color="error">
            {error}
          </MDTypography>
        </Alert>
      </Collapse> */}
      <DashboardNavbar />
      <MDBox py={3}>
        <Card component="form" role="form" onSubmit={handleSubmit}>
          <Grid container spacing={0}>
            <Grid item xs={12} md={6} lg={6}>
              <MDBox pt={4} px={3}>
                <MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      autoFocus
                      type="text"
                      value={locationID1}
                      required
                      style={height}
                      helperText={errorLocID1}
                      error={!!errorLocID1}
                      onChange={onLocationID1Change}
                      label="Location ID 1"
                      fullWidth
                    />
                  </MDBox>

                  <MDBox mb={2}>
                    <MDInput
                      id="name"
                      required
                      style={height}
                      helperText={errorDosID1}
                      error={!!errorDosID1}
                      value={dosimeterID1}
                      inputProps={{ maxLength: 11 }}
                      type="text"
                      label="Dosimeter ID 1"
                      fullWidth
                      onChange={onDosimeterID1Change}
                    >
                      <MDTypography />
                    </MDInput>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Grid>

            <Grid item xs={12} md={6} lg={6}>
              <MDBox px={3} sx={{ pt: { xs: 0, sm: 0, md: 4, lg: 4 } }}>
                <MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      required
                      style={height}
                      helperText={errorLocID2}
                      error={!!errorLocID2}
                      value={locationID2}
                      type="text"
                      label="Location ID 2"
                      fullWidth
                      onChange={onLocationID2Change}
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      required
                      style={height}
                      error={!!errorDosID2}
                      helperText={errorDosID2}
                      value={dosimeterID2}
                      inputProps={{ maxLength: 11 }}
                      type="text"
                      label="Dosimeter ID 2"
                      fullWidth
                      onChange={onDosimeterID2Change}
                    />
                  </MDBox>
                </MDBox>
              </MDBox>
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <MDBox pb={1} px={3}>
                <MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      required
                      style={height}
                      type="text"
                      label="Location name"
                      fullWidth
                      onChange={(e) => setLocationName(e.target.value)}
                    />
                  </MDBox>
                </MDBox>
              </MDBox>
            </Grid>
          </Grid>

          <>
            {/* <MDBox display="flex" alignItems="center" ml={3}>
              <Checkbox sx={{ ml: -1 }} onClick={toggleButton} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                // sx={{ cursor: "pointer", userSelect: "none" }}
              >
                I agree that the data provided is correct
              </MDTypography>
            </MDBox> */}
            <MDBox mt={3} mb={3} ml={3} mr={3}>
              <MDButton
                variant="gradient"
                color="info"
                fullWidth
                disabled={
                  errorDosID1 !== "" ||
                  errorDosID2 !== "" ||
                  errorLocID1 !== "" ||
                  errorLocID2 !== "" ||
                  locationID1 === "" ||
                  locationID2 === "" ||
                  dosimeterID1 === "" ||
                  dosimeterID2 === "" ||
                  locationName === ""
                }
                type="submit"
                onClick={() => {
                  dispatch(circularProgressOpen());
                  // if (
                  //   dosimeterID1 === "" ||
                  //   dosimeterID2 === "" ||
                  //   locationID1 === "" ||
                  //   locationID2 === "" ||
                  //   locationName === "" ||
                  //   error
                  // ) {
                  //   dispatch(circularProgressClose());
                  // }
                }}
              >
                Register
              </MDButton>
            </MDBox>
          </>
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default EnvRegister;
