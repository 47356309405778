import { Dialog, AppBar, Toolbar, IconButton } from "@mui/material";
import { Document, Page, Text, View, Image, PDFViewer } from "@react-pdf/renderer";
import CloseIcon from "@mui/icons-material/Close";
import styles from "../styles";
import logoKKM from "../../../../assets/images/logoKKM.png";
import logoRadDB from "../../../../assets/images/raddb.png";

export default function PDFViewerDialog({
  openDialog,
  closeDialog,
  newMonth,
  newYear,
  Transition,
  filteredArr,
  filteredUsersDept,
  filteredUsersHosp,
}) {
  return (
    <Dialog open={openDialog} onClose={closeDialog} TransitionComponent={Transition} fullScreen>
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton edge="end" color="error" onClick={closeDialog}>
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <PDFViewer height="100%" width="100%">
        <Document>
          <Page orientation="landscape" style={styles.body} size="A4">
            <Text fixed style={styles.headerRight}>
              Powered by RadDB @ raddosedb.com
            </Text>
            <View style={styles.headerContainer}>
              <View style={styles.logoContainer}>
                <Image style={styles.logoRadDB} src={logoRadDB} />
                <Image style={styles.logoKKM} src={logoKKM} />
              </View>
              <View style={styles.textContainer}>
                <Text style={styles.title}>
                  Extremities Dose Monitoring Analysis Monthly Report
                </Text>
                <Text style={styles.title}>Nuclear Medicine Department</Text>
                <Text style={styles.title}>Kuala Lumpur Hospital (HKL)</Text>
                <Text style={styles.subtitle}>
                  Dosimeter analysis results of {newMonth} {newYear}
                </Text>
              </View>
            </View>

            <View style={styles.tableContainer}>
              <View fixed style={styles.container}>
                <Text style={styles.no}>No.</Text>
                <Text style={styles.unit}>Unit</Text>
                <Text style={styles.profession}>Profession</Text>
                <Text style={styles.grade}>Grade</Text>
                <Text style={styles.name}>Name</Text>
                <Text style={styles.ringNo}>Ring no.</Text>
                <Text style={styles.dose}>Hp0.07 (mSv)</Text>
                <Text style={styles.ringNo}>2nd ring no.</Text>
                <Text style={styles.dose}>Hp0.07 (mSv)</Text>
                <Text style={styles.dose}>Average Hp0.07 (mSv)</Text>
                <Text style={styles.dose}>Cumu. Hp0.07 (mSv)</Text>
                {/* <Text style={styles.dose}>Cumu. Hp0.07 (mSv)</Text> */}
                {/* <Text style={styles.signature}>Signature</Text>
                <Text style={styles.signature}>Date</Text> */}
              </View>
              {filteredArr.map((e, i) => (
                <View style={styles.row} key={e.id}>
                  <Text style={styles.noRow}>{i + 1}</Text>
                  <Text style={styles.unitRow}>{e.unit}</Text>
                  <Text style={styles.professionRow}>{e.job}</Text>
                  <Text style={styles.gradeRow}>{e.grade}</Text>
                  <Text style={styles.nameRow}>{e.name}</Text>
                  <Text style={styles.ringNoRow}>{e.extDose[newYear][newMonth]?.extRingNo}</Text>
                  <Text style={styles.doseRow}>
                    {e?.extDose[newYear][newMonth]?.extHp007?.toFixed(2)}
                  </Text>
                  <Text style={styles.ringNoRow}>
                    {e.extDose[newYear][newMonth]?.extRingNo2 || "N/A"}
                  </Text>
                  <Text style={styles.doseRow}>
                    {e.extDose[newYear][newMonth]?.extRingNo2
                      ? e?.extDose[newYear][newMonth]?.extHp0072?.toFixed(2)
                      : "N/A"}
                  </Text>
                  <Text style={styles.doseRow}>
                    {e?.extDose[newYear][newMonth]?.average?.toFixed(2)}
                  </Text>
                  <Text style={styles.doseRow}>
                    {(() => {
                      const january = e?.extDose[newYear].January?.average;
                      const february = e?.extDose[newYear].February?.average;
                      const march = e?.extDose[newYear].March?.average;
                      const april = e?.extDose[newYear].April?.average;
                      const may = e?.extDose[newYear].May?.average;
                      const june = e?.extDose[newYear].June?.average;
                      const july = e?.extDose[newYear].July?.average;
                      const august = e?.extDose[newYear].August?.average;
                      const september = e?.extDose[newYear].September?.average;
                      const october = e?.extDose[newYear].October?.average;
                      const november = e?.extDose[newYear].November?.average;
                      const december = e?.extDose[newYear].December?.average;

                      if (newMonth === "February") {
                        return (january || 0) + (february || 0) === 0
                          ? "0.00"
                          : ((january || 0) + (february || 0)).toFixed(2);
                      }

                      if (newMonth === "March") {
                        return (january || 0) + (february || 0) + (march || 0) === 0
                          ? "0.00"
                          : ((january || 0) + (february || 0) + (march || 0)).toFixed(2);
                      }

                      if (newMonth === "April") {
                        return (january || 0) + (february || 0) + (march || 0) + (april || 0) === 0
                          ? "0.00"
                          : (
                              (january || 0) +
                              (february || 0) +
                              (march || 0) +
                              (april || 0)
                            ).toFixed(2);
                      }

                      if (newMonth === "May") {
                        return (january || 0) +
                          (february || 0) +
                          (march || 0) +
                          (april || 0) +
                          (may || 0) ===
                          0
                          ? "0.00"
                          : (
                              (january || 0) +
                              (february || 0) +
                              (march || 0) +
                              (april || 0) +
                              (may || 0)
                            ).toFixed(2);
                      }

                      if (newMonth === "June") {
                        return (january || 0) +
                          (february || 0) +
                          (march || 0) +
                          (april || 0) +
                          (may || 0) +
                          (june || 0) ===
                          0
                          ? "0.00"
                          : (
                              (january || 0) +
                              (february || 0) +
                              (march || 0) +
                              (april || 0) +
                              (may || 0) +
                              (june || 0)
                            ).toFixed(2);
                      }

                      if (newMonth === "July") {
                        return (january || 0) +
                          (february || 0) +
                          (march || 0) +
                          (april || 0) +
                          (may || 0) +
                          (june || 0) +
                          (july || 0) ===
                          0
                          ? "0.00"
                          : (
                              (january || 0) +
                              (february || 0) +
                              (march || 0) +
                              (april || 0) +
                              (may || 0) +
                              (june || 0) +
                              (july || 0)
                            ).toFixed(2);
                      }

                      if (newMonth === "August") {
                        return (january || 0) +
                          (february || 0) +
                          (march || 0) +
                          (april || 0) +
                          (may || 0) +
                          (june || 0) +
                          (july || 0) +
                          (august || 0) ===
                          0
                          ? "0.00"
                          : (
                              (january || 0) +
                              (february || 0) +
                              (march || 0) +
                              (april || 0) +
                              (may || 0) +
                              (june || 0) +
                              (july || 0) +
                              (august || 0)
                            ).toFixed(2);
                      }

                      if (newMonth === "September") {
                        return (january || 0) +
                          (february || 0) +
                          (march || 0) +
                          (april || 0) +
                          (may || 0) +
                          (june || 0) +
                          (july || 0) +
                          (august || 0) +
                          (september || 0) ===
                          0
                          ? "0.00"
                          : (
                              (january || 0) +
                              (february || 0) +
                              (march || 0) +
                              (april || 0) +
                              (may || 0) +
                              (june || 0) +
                              (july || 0) +
                              (august || 0) +
                              (september || 0)
                            ).toFixed(2);
                      }

                      if (newMonth === "October") {
                        return (january || 0) +
                          (february || 0) +
                          (march || 0) +
                          (april || 0) +
                          (may || 0) +
                          (june || 0) +
                          (july || 0) +
                          (august || 0) +
                          (september || 0) +
                          (october || 0) ===
                          0
                          ? "0.00"
                          : (
                              (january || 0) +
                              (february || 0) +
                              (march || 0) +
                              (april || 0) +
                              (may || 0) +
                              (june || 0) +
                              (july || 0) +
                              (august || 0) +
                              (september || 0) +
                              (october || 0)
                            ).toFixed(2);
                      }

                      if (newMonth === "November") {
                        return (january || 0) +
                          (february || 0) +
                          (march || 0) +
                          (april || 0) +
                          (may || 0) +
                          (june || 0) +
                          (july || 0) +
                          (august || 0) +
                          (september || 0) +
                          (october || 0) +
                          (november || 0) ===
                          0
                          ? "0.00"
                          : (
                              (january || 0) +
                              (february || 0) +
                              (march || 0) +
                              (april || 0) +
                              (may || 0) +
                              (june || 0) +
                              (july || 0) +
                              (august || 0) +
                              (september || 0) +
                              (october || 0) +
                              (november || 0)
                            ).toFixed(2);
                      }

                      if (newMonth === "December") {
                        return (january || 0) +
                          (february || 0) +
                          (march || 0) +
                          (april || 0) +
                          (may || 0) +
                          (june || 0) +
                          (july || 0) +
                          (august || 0) +
                          (september || 0) +
                          (october || 0) +
                          (november || 0) +
                          (december || 0) ===
                          0
                          ? "0.00"
                          : (
                              (january || 0) +
                              (february || 0) +
                              (march || 0) +
                              (april || 0) +
                              (may || 0) +
                              (june || 0) +
                              (july || 0) +
                              (august || 0) +
                              (september || 0) +
                              (october || 0) +
                              (november || 0) +
                              (december || 0)
                            ).toFixed(2);
                      }

                      return (january || 0) === 0 ? "0.00" : (january || 0).toFixed(2);
                    })()}
                  </Text>
                  {/* <Text style={styles.signatureRow}>{e?.signature}</Text>
                  <Text style={styles.signatureRow}>{e?.date}</Text> */}
                </View>
              ))}
            </View>

            <Text style={{ marginTop: 7 }}>Notes :</Text>
            <Text style={{ marginTop: 5 }}>
              **Shallow dose equivalent is defined as the proposed dose equivalent at a depth of
              0.07 mm in ICRU tissue and is denoted by Hp(0.07).
            </Text>
            <Text style={{ marginTop: 5 }}>
              In ICRP 60, shallow dose equivalent is referred to as individual dose equivalent,
              superficial.
            </Text>
            <Text style={{ marginTop: 5 }}>
              1. The yearly limited dose for a worker&apos;s extremities exposure is 500
              millisievert (500 mSv) according to the Atomic Energy Lincensing Act 1984, (Basic
              Safety Radiation Protection) Regulations 2010.
            </Text>
            <Text style={{ marginTop: 5 }}>
              2. The operational limitation for an individual is set at 150 mSv per year, ensuring
              that the yearly limited dose of 500 mSv is not exceeded.
            </Text>
            <Text style={{ marginTop: 5 }}>
              3. In cases where the limit of 500 mSv is exceeded, the licensee must report this to
              the Ministry of Health (MOH).
            </Text>
            <Text style={{ marginTop: 5 }}>
              4. The results are applicable only to the tested nanoDot™ dosimeters.
            </Text>
            <Text style={{ marginTop: 5 }}>
              5. The minimum detection limit is 0.05 mSv (any value less than 0.05 mSv will be
              considered as 0.00 mSv).
            </Text>
            <Text style={{ marginTop: 5 }}>
              Copyright of this certificate is owned by the issuing laboratory and may be reproduced
              other than in full except with the prior written approval of the Head of the issuing
              laboratory.
            </Text>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
                marginTop: 20,
                marginLeft: 25,
              }}
            >
              <Text>Prepared by :</Text>
              {/* <Text>Approved by :</Text> */}
            </View>
            <Text
              style={styles.pageNumber}
              render={({ pageNumber, totalPages }) => `Page ${pageNumber} of ${totalPages}`}
              fixed
            />
            <Text style={styles.footerLeft} fixed>
              {filteredUsersDept}, {filteredUsersHosp}
            </Text>
            <Text style={styles.footerRight} fixed>
              {newMonth} {newYear}
            </Text>
          </Page>
        </Document>
      </PDFViewer>
    </Dialog>
  );
}
