import { useContext } from "react";
import { UserContext } from "context/UserContext";
import colors from "assets/theme/base/colors";

export default function individualExtremities(icNum, newYear) {
  const { users } = useContext(UserContext);

  const year = newYear;

  const filteredUser = users.filter((user) => user?.icNum === icNum);
  const jan = filteredUser[0]?.extDose?.[year]?.January?.average;
  const feb = filteredUser[0]?.extDose?.[year]?.February?.average;
  const mac = filteredUser[0]?.extDose?.[year]?.March?.average;
  const apr = filteredUser[0]?.extDose?.[year]?.April?.average;
  const may = filteredUser[0]?.extDose?.[year]?.May?.average;
  const jun = filteredUser[0]?.extDose?.[year]?.June?.average;
  const jul = filteredUser[0]?.extDose?.[year]?.July?.average;
  const aug = filteredUser[0]?.extDose?.[year]?.August?.average;
  const sep = filteredUser[0]?.extDose?.[year]?.September?.average;
  const oct = filteredUser[0]?.extDose?.[year]?.October?.average;
  const nov = filteredUser[0]?.extDose?.[year]?.November?.average;
  const dec = filteredUser[0]?.extDose?.[year]?.December?.average;

  const janC =
    (Number.isNaN(jan) ||
      !(
        (jan === undefined || jan === null) &&
        (feb === undefined || feb === null) &&
        (mac === undefined || mac === null) &&
        (apr === undefined || apr === null) &&
        (may === undefined || may === null) &&
        (jun === undefined || jun === null) &&
        (jul === undefined || jul === null) &&
        (aug === undefined || aug === null) &&
        (sep === undefined || sep === null) &&
        (oct === undefined || oct === null) &&
        (nov === undefined || nov === null) &&
        (dec === undefined || dec === null)
      )) &&
    filteredUser[0]?.extDose?.[year]?.January?.average >= 0
      ? jan || 0
      : null;

  const febC =
    Number.isNaN(feb) ||
    (!(
      (feb === undefined || feb === null) &&
      (mac === undefined || mac === null) &&
      (apr === undefined || apr === null) &&
      (may === undefined || may === null) &&
      (jun === undefined || jun === null) &&
      (jul === undefined || jul === null) &&
      (aug === undefined || aug === null) &&
      (sep === undefined || sep === null) &&
      (oct === undefined || oct === null) &&
      (nov === undefined || nov === null) &&
      (dec === undefined || dec === null)
    ) &&
      filteredUser[0]?.extDose?.[year]?.February?.average >= 0)
      ? (feb || 0) + (jan || 0)
      : null;

  const macC =
    Number.isNaN(mac) ||
    (!(
      (mac === undefined || mac === null) &&
      (apr === undefined || apr === null) &&
      (may === undefined || may === null) &&
      (jun === undefined || jun === null) &&
      (jul === undefined || jul === null) &&
      (aug === undefined || aug === null) &&
      (sep === undefined || sep === null) &&
      (oct === undefined || oct === null) &&
      (nov === undefined || nov === null) &&
      (dec === undefined || dec === null)
    ) &&
      filteredUser[0]?.extDose?.[year]?.March?.average >= 0)
      ? (mac || 0) + (feb || 0) + (jan || 0)
      : null;

  const aprC =
    (Number.isNaN(apr) ||
      !(
        (apr === undefined || apr === null) &&
        (may === undefined || may === null) &&
        (jun === undefined || jun === null) &&
        (jul === undefined || jul === null) &&
        (aug === undefined || aug === null) &&
        (sep === undefined || sep === null) &&
        (oct === undefined || oct === null) &&
        (nov === undefined || nov === null) &&
        (dec === undefined || dec === null)
      )) &&
    filteredUser[0]?.extDose?.[year]?.April?.average >= 0
      ? (apr || 0) + (mac || 0) + (feb || 0) + (jan || 0)
      : null;

  const mayC =
    (Number.isNaN(may) ||
      !(
        (may === undefined || may === null) &&
        (jun === undefined || jun === null) &&
        (jul === undefined || jul === null) &&
        (aug === undefined || aug === null) &&
        (sep === undefined || sep === null) &&
        (oct === undefined || oct === null) &&
        (nov === undefined || nov === null) &&
        (dec === undefined || dec === null)
      )) &&
    filteredUser[0]?.extDose?.[year]?.May?.average >= 0
      ? (may || 0) + (apr || 0) + (mac || 0) + (feb || 0) + (jan || 0)
      : null;

  const junC =
    (Number.isNaN(jun) ||
      !(
        (jun === undefined || jun === null) &&
        (jul === undefined || jul === null) &&
        (aug === undefined || aug === null) &&
        (sep === undefined || sep === null) &&
        (oct === undefined || oct === null) &&
        (nov === undefined || nov === null) &&
        (dec === undefined || dec === null)
      )) &&
    filteredUser[0]?.extDose?.[year]?.June?.average >= 0
      ? (jun || 0) + (may || 0) + (apr || 0) + (mac || 0) + (feb || 0) + (jan || 0)
      : null;

  const julC =
    (Number.isNaN(jul) ||
      !(
        (jul === undefined || jul === null) &&
        (aug === undefined || aug === null) &&
        (sep === undefined || sep === null) &&
        (oct === undefined || oct === null) &&
        (nov === undefined || nov === null) &&
        (dec === undefined || dec === null)
      )) &&
    filteredUser[0]?.extDose?.[year]?.July?.average >= 0
      ? (jul || 0) + (jun || 0) + (may || 0) + (apr || 0) + (mac || 0) + (feb || 0) + (jan || 0)
      : null;

  const augC =
    (Number.isNaN(aug) ||
      !(
        (aug === undefined || aug === null) &&
        (sep === undefined || sep === null) &&
        (oct === undefined || oct === null) &&
        (nov === undefined || nov === null) &&
        (dec === undefined || dec === null)
      )) &&
    filteredUser[0]?.extDose?.[year]?.August?.average >= 0
      ? (aug || 0) +
        (jul || 0) +
        (jun || 0) +
        (may || 0) +
        (apr || 0) +
        (mac || 0) +
        (feb || 0) +
        (jan || 0)
      : null;

  const sepC =
    (Number.isNaN(sep) ||
      !(
        (sep === undefined || sep === null) &&
        (oct === undefined || oct === null) &&
        (nov === undefined || nov === null) &&
        (dec === undefined || dec === null)
      )) &&
    filteredUser[0]?.extDose?.[year]?.September?.average >= 0
      ? (sep || 0) +
        (aug || 0) +
        (jul || 0) +
        (jun || 0) +
        (may || 0) +
        (apr || 0) +
        (mac || 0) +
        (feb || 0) +
        (jan || 0)
      : null;

  const octC =
    (Number.isNaN(oct) ||
      !(
        (oct === undefined || oct === null) &&
        (nov === undefined || nov === null) &&
        (dec === undefined || dec === null)
      )) &&
    filteredUser[0]?.extDose?.[year]?.October?.average >= 0
      ? (oct || 0) +
        (sep || 0) +
        (aug || 0) +
        (jul || 0) +
        (jun || 0) +
        (may || 0) +
        (apr || 0) +
        (mac || 0) +
        (feb || 0) +
        (jan || 0)
      : null;

  const novC =
    (Number.isNaN(nov) ||
      !((nov === undefined || nov === null) && (dec === undefined || dec === null))) &&
    filteredUser[0]?.extDose?.[year]?.November?.average >= 0
      ? (nov || 0) +
        (oct || 0) +
        (sep || 0) +
        (aug || 0) +
        (jul || 0) +
        (jun || 0) +
        (may || 0) +
        (apr || 0) +
        (mac || 0) +
        (feb || 0) +
        (jan || 0)
      : null;

  const decC =
    (Number.isNaN(dec) || !(dec === undefined || dec === null)) &&
    filteredUser[0]?.extDose?.[year]?.December?.average >= 0
      ? (dec || 0) +
        (nov || 0) +
        (oct || 0) +
        (sep || 0) +
        (aug || 0) +
        (jul || 0) +
        (jun || 0) +
        (may || 0) +
        (apr || 0) +
        (mac || 0) +
        (feb || 0) +
        (jan || 0)
      : null;

  const chartDataMaxExtremities = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    datasets: [
      {
        label: "Cumulative Hp0.07 dose (mSv)",
        data: [janC, febC, macC, aprC, mayC, junC, julC, augC, sepC, octC, novC, decC],
        borderColor: colors.primary.main,
        pointBackgroundColor: colors.primary.main,
      },
      {
        label: "Monthly Hp0.07 dose (mSv)",
        data: [jan, feb, mac, apr, may, jun, jul, aug, sep, oct, nov, dec],
        borderColor: colors.info.main,
        pointBackgroundColor: colors.info.main,
      },
    ],
  };

  return chartDataMaxExtremities;
}
