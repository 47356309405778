import { useNavigate } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";
import {
  Card,
  Grid,
  MenuItem,
  Snackbar,
  Alert,
  Collapse,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  Backdrop,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormControlLabel,
  Switch,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { onSnapshot, addDoc, collection, Timestamp } from "firebase/firestore";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import Footer from "examples/Footer";
import { useDispatch, useSelector } from "react-redux";
import { circularProgressOpen, circularProgressClose } from "slices/idDeleteSlice";
import OnlineStatusSnackbar from "../onlineStatusSnackbar/onlineStatusSnackbar";
import { db, auth } from "../../firebase-config";

function Register() {
  const [useNanodot1, setUseNanodot1] = useState(false);
  const [useNanodot2, setUseNanodot2] = useState(false);
  const currentUser = auth?.currentUser?.email;
  const usersCollectionRef = collection(db, "users");
  const [users, setUsers] = useState([]);
  useEffect(() => {
    document.title = "Register - RadDB";

    const unsubscribe = onSnapshot(usersCollectionRef, (snapshot) =>
      setUsers(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
    );

    return () => unsubscribe();
  }, []);
  const dispatch = useDispatch();
  const { circularProgressStatus } = useSelector((state) => state.idDelete);
  const navigate = useNavigate();

  const [newName, setNewName] = useState("");
  const [emails, setEmails] = useState(null);

  const [employDate, setEmployDate] = useState(new Date().setHours(0, 0, 0, 0));
  const emplDateAdd = new Date(new Date(employDate).setHours(0, 0, 0, 0));
  const eTimeStamp = Timestamp.fromDate(emplDateAdd);

  const [nextMedCheck, setNextMedCheck] = useState(new Date().setHours(0, 0, 0, 0));
  const medCheckDateAdd = new Date(new Date(nextMedCheck).setHours(0, 0, 0, 0));
  const medCheckTimeStamp = Timestamp.fromDate(medCheckDateAdd);
  // console.log(medCheckDateAdd);
  const [newIcNum, setNewIcNum] = useState("");
  const [newAddress, setNewAddress] = useState(null);

  const [openAlert, setOpenAlert] = useState(false);
  const [state, setState] = useState({
    openToast: false,
    vertical: "top",
    horizontal: "center",
  });

  const { vertical, horizontal, openToast } = state;

  const handleToastOpen = () => {
    setState({ openToast: true, vertical: "top", horizontal: "center" });
  };

  const handleToastClose = () => {
    setState({ ...state, openToast: false });
  };

  const [error, setError] = useState("");
  const [newSex, setNewSex] = useState("");
  const [newStatus, setNewStatus] = useState(true);
  const [newDept, setNewDept] = useState("");
  const [newJob, setNewJob] = useState("");
  const [newUnit, setNewUnit] = useState("");
  const [newGrade, setNewGrade] = useState("");
  const [newHealthScrNo, setNewHealthScrNo] = useState(null);
  const [newTelNo, setNewTelNo] = useState(null);
  const [duplicateIc, setDuplicateIc] = useState(true);

  const onChangeIcNum = (e) => {
    const newValue = e.target.value;

    if (newValue.match(/[^0-9]/)) {
      setError("Insert Number Only!");
      setNewSex("");
    } else if (!newValue.match(/[^0-9]/) && newValue.length > 0 && newValue.length < 12) {
      setError("IC number must have 12 digits");
      setNewSex("");
      setNewIcNum(newValue);
    } else if (!newValue.match(/[^0-9]/) && newValue.length === 0) {
      setError("");
      setNewSex("");
      setNewIcNum(newValue);
    } else if (!newValue.match(/[^0-9]/) && newValue.length === 12 && newValue % 2 === 0) {
      setError("");
      setNewIcNum(newValue);
      setNewSex("Female");
    } else if (!newValue.match(/[^0-9]/) && newValue.length === 12 && newValue % 2 !== 0) {
      setError("");
      setNewIcNum(newValue);
      setNewSex("Male");
    }

    if (newValue.length === 12 && users.find((user) => user?.icNum === newValue)) {
      setDuplicateIc(true);
    } else if (newValue.length === 12 && users.find((user) => user?.icNum !== newValue)) {
      setDuplicateIc(false);
    }
  };

  useEffect(() => {
    if (document.getElementById("name")) {
      document.getElementById("name").focus();
    }
  }, [newIcNum.length === 12]);

  const resetInputIC = (e) => {
    e.target.value = "";
    setNewIcNum("");
  };

  const regexYear = newIcNum.match(/(..)........../);
  const regexMonth = newIcNum.match(/..(..)......../);
  const regexDay = newIcNum.match(/....(..)....../);

  const yearOfBirth = () => {
    const fullYear = new Date().getFullYear().toString();

    const regexshortYear = /..(..)/;

    const shortYear = fullYear.match(regexshortYear);

    if (regexYear?.["1"] < 100 && regexYear?.["1"] > shortYear?.["1"]) {
      return `19${regexYear?.["1"]}`;
    }

    return `20${regexYear?.["1"]}`;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
      await addDoc(usersCollectionRef, {
        address: newAddress,
        timeStampReg: Timestamp.fromDate(new Date()),
        name: newName,
        emplDate: eTimeStamp,
        email: emails,
        nextCheckUp: medCheckTimeStamp,
        icNum: newIcNum,
        sex: newSex,
        active: newStatus,
        hosp: (() => {
          if (currentUser === "nuclearmedhkl@moh.gov.my") {
            return "Hospital Kuala Lumpur";
          }

          if (currentUser === "nuclearmedikn@moh.gov.my") {
            return "Institut Kanser Negara";
          }

          if (currentUser === "demo@raddosedb.com") {
            return "Demo Profile";
          }

          if (currentUser === "nuclearmedhpp@moh.gov.my") {
            return "Hospital Pulau Pinang";
          }

          if (currentUser === "nuclearmedhsa@moh.gov.my") {
            return "Hospital Sultanah Aminah";
          }

          if (currentUser === "nuclearmedhus@moh.gov.my") {
            return "Hospital Umum Sarawak";
          }

          if (currentUser === "nuclearmedhwkks@moh.gov.my") {
            return "Hospital Wanita & Kanak-Kanak Sabah";
          }

          return "Limbo";
        })(),
        dept: newDept,
        unit: newUnit,
        tel: newTelNo,
        job: newJob,
        grade: newGrade,
        healthScrNo: newHealthScrNo,
        birthday: Timestamp.fromDate(
          new Date(yearOfBirth(), regexMonth?.["1"] - 1, regexDay?.["1"])
        ),
        useNanodot1,
        useNanodot2,
      });
      navigate("/radiation-workers/profiles-table");
      dispatch(circularProgressClose());
    } catch (err) {
      setError(err.message);
      handleToastOpen();
    }
  };

  const [button, setButton] = useState(true);

  const toggleButton = () => {
    setButton(!button);
  };

  const height = { height: 44 };

  useEffect(() => {
    document.title = "Register - RadDB";
  }, []);

  const handleStatusChange = (e) => {
    setNewStatus(e.target.checked);
  };

  return (
    <DashboardLayout>
      <OnlineStatusSnackbar />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={circularProgressStatus}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Snackbar
        autoHideDuration={4000}
        anchorOrigin={{ vertical, horizontal }}
        open={openToast}
        onClose={handleToastClose}
        message="I love snacks"
        key={vertical + horizontal}
      >
        <Alert onClose={handleToastClose} severity="error">
          <MDTypography variant="h6" fontWeight="medium" color="error">
            {error}
          </MDTypography>
        </Alert>
      </Snackbar>

      <Dialog open={duplicateIc && newIcNum.length === 12}>
        <>
          <DialogTitle>
            The IC number {newIcNum} belongs to the user{" "}
            {users.filter((e) => e.icNum === newIcNum)[0]?.name} from{" "}
            {users.filter((e) => e.icNum === newIcNum)[0]?.hosp}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>Please ensure the entered IC number is correct</DialogContentText>
          </DialogContent>
          <DialogActions>
            <MDButton
              autoFocus
              variant="outlined"
              onClick={() => setDuplicateIc(false)}
              color="error"
            >
              Okay
            </MDButton>
          </DialogActions>
        </>
      </Dialog>
      <Collapse in={openAlert}>
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpenAlert(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2, mx: 3, mt: 2 }}
        >
          <MDTypography variant="h6" fontWeight="medium" color="error">
            {error}
          </MDTypography>
        </Alert>
      </Collapse>
      <DashboardNavbar />
      <MDBox py={3}>
        <Card component="form" role="form" onSubmit={handleSubmit}>
          <Grid container spacing={0}>
            <Grid item xs={12} md={6} lg={6}>
              <MDBox pt={4} px={3} sx={{ pb: { xs: 0, sm: 0, md: 3, lg: 3 } }}>
                <MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      autoFocus
                      type="text"
                      required
                      style={height}
                      onChange={onChangeIcNum}
                      onFocus={resetInputIC}
                      label="Malaysian IC Number"
                      helperText={error}
                      error={!!error}
                      fullWidth
                      inputProps={{ maxLength: 12 }}
                    />
                  </MDBox>
                  {!duplicateIc && newIcNum.length === 12 && (
                    <>
                      <MDBox mb={2}>
                        <MDInput
                          id="name"
                          required
                          style={height}
                          type="text"
                          label="Name"
                          fullWidth
                          onChange={(e) => {
                            setNewName(e.target.value);
                          }}
                        >
                          <MDTypography />
                        </MDInput>
                      </MDBox>

                      <MDBox mb={2}>
                        <MDInput
                          style={height}
                          InputLabelProps={{ shrink: true }}
                          type="date"
                          label="Date of Birth"
                          value={`${yearOfBirth()}-${regexMonth?.["1"]}-${regexDay?.["1"]}`}
                          fullWidth
                          inputProps={{
                            readOnly: true,
                          }}
                          placeholder="Auto-generated from IC number..."
                        />
                      </MDBox>

                      <MDBox mb={2}>
                        <MDInput
                          style={height}
                          InputLabelProps={{ shrink: true }}
                          type="text"
                          label="Sex"
                          fullWidth
                          value={newSex}
                          inputProps={{
                            readOnly: true,
                          }}
                          placeholder="Auto-generated from IC number..."
                        />
                      </MDBox>

                      <MDBox mb={2}>
                        <MDInput
                          style={height}
                          type="email"
                          label="E-mail"
                          fullWidth
                          onChange={(e) => {
                            setEmails(e.target.value);
                          }}
                        >
                          <MDTypography />
                        </MDInput>
                      </MDBox>

                      <MDBox mb={2}>
                        <MDInput
                          type="text"
                          label="Address"
                          onChange={(e) => setNewAddress(e.target.value)}
                          multiline
                          rows={4}
                          fullWidth
                        />
                      </MDBox>

                      <MDBox mb={2}>
                        <MDInput
                          style={height}
                          type="text"
                          label="Telephone number"
                          onChange={(e) => setNewTelNo(e.target.value)}
                          fullWidth
                        />
                      </MDBox>
                    </>
                  )}
                </MDBox>
              </MDBox>
            </Grid>

            {!duplicateIc && newIcNum.length === 12 && (
              <Grid item xs={12} md={6} lg={6}>
                <MDBox pb={3} px={3} sx={{ pt: { xs: 0, sm: 0, md: 4, lg: 4 } }}>
                  <MDBox>
                    <MDBox mb={2}>
                      <MDInput
                        style={height}
                        InputLabelProps={{ shrink: true }}
                        type="date"
                        label="Report Duty Date"
                        fullWidth
                        onChange={(e) => setEmployDate(e.target.value)}
                      />
                    </MDBox>
                    <MDBox mb={2}>
                      <MDInput
                        style={height}
                        InputLabelProps={{ shrink: true }}
                        type="date"
                        label="Next Medical Checkup"
                        fullWidth
                        onChange={(e) => setNextMedCheck(e.target.value)}
                      />
                    </MDBox>
                    <MDBox mb={2}>
                      <FormControl required fullWidth>
                        <InputLabel id="job-select-label">Profession</InputLabel>
                        <Select
                          labelId="job-select-label"
                          id="job-select"
                          value={newJob}
                          label="Profession"
                          onChange={(e) => setNewJob(e.target.value)}
                          style={height}
                        >
                          <MenuItem key="0" value="">
                            <em>Please Select</em>
                          </MenuItem>
                          <MenuItem key="1" value="Pakar Perubatan Nuklear">
                            Pakar Perubatan Nuklear
                          </MenuItem>
                          <MenuItem key="2" value="Pegawai Perubatan">
                            Pegawai Perubatan
                          </MenuItem>
                          <MenuItem key="3" value="Pegawai Sains (Fizik)">
                            Pegawai Sains (Fizik)
                          </MenuItem>
                          <MenuItem key="4" value="Pegawai Farmasi">
                            Pegawai Farmasi
                          </MenuItem>
                          <MenuItem key="5" value="Penolong Pegawai Perubatan">
                            Penolong Pegawai Perubatan
                          </MenuItem>
                          <MenuItem key="6" value="Juru X-ray Diagnostik">
                            Juru X-ray Diagnostik
                          </MenuItem>
                          <MenuItem key="7" value="Juru Teknologi Makmal Perubatan">
                            Juru Teknologi Makmal Perubatan
                          </MenuItem>
                          <MenuItem key="8" value="Jururawat">
                            Jururawat
                          </MenuItem>
                          <MenuItem key="9" value="Pembantu Perawatan Kesihatan">
                            Pembantu Perawatan Kesihatan
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </MDBox>
                    <MDBox mb={2}>
                      <FormControl required fullWidth>
                        <InputLabel id="grade-select-label">Grade</InputLabel>
                        <Select
                          labelId="grade-select-label"
                          id="grade-select"
                          value={newGrade}
                          label="Grade"
                          onChange={(e) => setNewGrade(e.target.value)}
                          style={height}
                        >
                          {(() => {
                            if (
                              newJob === "Pakar Perubatan Nuklear" ||
                              newJob === "Pegawai Perubatan"
                            ) {
                              return [
                                <MenuItem key="1" value="JUSA C">
                                  JUSA C
                                </MenuItem>,
                                <MenuItem key="2" value="UD58">
                                  UD58
                                </MenuItem>,
                                <MenuItem key="3" value="UD56">
                                  UD56
                                </MenuItem>,
                                <MenuItem key="4" value="UD54">
                                  UD54
                                </MenuItem>,
                                <MenuItem key="5" value="UD52">
                                  UD52
                                </MenuItem>,
                                <MenuItem key="6" value="UD48">
                                  UD48
                                </MenuItem>,
                                <MenuItem key="7" value="UD44">
                                  UD44
                                </MenuItem>,
                                <MenuItem key="8" value="UD41">
                                  UD41
                                </MenuItem>,
                              ];
                            }

                            if (newJob === "Pegawai Sains (Fizik)") {
                              return [
                                <MenuItem key="0" value="KHAS C">
                                  KHAS C
                                </MenuItem>,
                                <MenuItem key="1" value="C52">
                                  C52
                                </MenuItem>,
                                <MenuItem key="2" value="C48">
                                  C48
                                </MenuItem>,
                                <MenuItem key="3" value="C44">
                                  C44
                                </MenuItem>,
                                <MenuItem key="4" value="C41">
                                  C41
                                </MenuItem>,
                              ];
                            }

                            if (newJob === "Pegawai Farmasi") {
                              return [
                                <MenuItem key="0" value="UF54">
                                  UF54
                                </MenuItem>,
                                <MenuItem key="1" value="UF52">
                                  UF52
                                </MenuItem>,
                                <MenuItem key="2" value="UF48">
                                  UF48
                                </MenuItem>,
                                <MenuItem key="3" value="UF44">
                                  UF44
                                </MenuItem>,
                                <MenuItem key="4" value="UF41">
                                  UF41
                                </MenuItem>,
                              ];
                            }

                            if (newJob === "Penolong Pegawai Perubatan") {
                              return [
                                <MenuItem key="0" value="U41">
                                  U41
                                </MenuItem>,
                                <MenuItem key="1" value="U36">
                                  U36
                                </MenuItem>,
                                <MenuItem key="2" value="U32">
                                  U32
                                </MenuItem>,
                                <MenuItem key="3" value="U29">
                                  U29
                                </MenuItem>,
                              ];
                            }

                            if (newJob === "Juru X-ray Diagnostik") {
                              return [
                                <MenuItem key="0" value="U32">
                                  U32
                                </MenuItem>,
                                <MenuItem key="1" value="U29">
                                  U29
                                </MenuItem>,
                              ];
                            }

                            if (newJob === "Juru Teknologi Makmal Perubatan") {
                              return [
                                <MenuItem key="0" value="U29">
                                  U29
                                </MenuItem>,
                              ];
                            }

                            if (newJob === "Jururawat") {
                              return [
                                <MenuItem key="0" value="U36">
                                  U36
                                </MenuItem>,
                                <MenuItem key="1" value="U32">
                                  U32
                                </MenuItem>,
                                <MenuItem key="2" value="U32 (KUP)">
                                  U32 (KUP)
                                </MenuItem>,
                                <MenuItem key="3" value="U29">
                                  U29
                                </MenuItem>,
                              ];
                            }

                            if (newJob === "Pembantu Perawatan Kesihatan") {
                              return [
                                <MenuItem key="0" value="U14">
                                  U14
                                </MenuItem>,
                                <MenuItem key="1" value="U11">
                                  U11
                                </MenuItem>,
                              ];
                            }

                            return (
                              <MenuItem key="0" value="">
                                <em>Please Select Profession First</em>
                              </MenuItem>
                            );
                          })()}
                        </Select>
                      </FormControl>
                    </MDBox>
                    <MDBox mb={2}>
                      <FormControl required fullWidth>
                        <InputLabel id="department-select-label">Department</InputLabel>
                        <Select
                          labelId="department-select-label"
                          id="department-select"
                          value={newDept}
                          label="Department"
                          onChange={(e) => setNewDept(e.target.value)}
                          style={height}
                        >
                          <MenuItem key="0" value="">
                            <em>Please Select</em>
                          </MenuItem>
                          <MenuItem key="1" value="Jabatan Perubatan Nuklear">
                            Jabatan Perubatan Nuklear
                          </MenuItem>
                          <MenuItem key="2" value="Jabatan Radioterapi dan Onkologi">
                            Jabatan Radioterapi dan Onkologi
                          </MenuItem>
                          <MenuItem key="3" value="Jabatan Radiologi">
                            Jabatan Radiologi
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </MDBox>
                    <MDBox mb={2}>
                      <FormControl required fullWidth>
                        <InputLabel id="unit-select-label">Unit</InputLabel>
                        <Select
                          labelId="unit-select-label"
                          id="unit-select"
                          value={newUnit}
                          label="Unit"
                          onChange={(e) => setNewUnit(e.target.value)}
                          style={height}
                        >
                          {newDept === "Jabatan Perubatan Nuklear" ? (
                            [
                              <MenuItem key="0" value="">
                                <em>Please Select</em>
                              </MenuItem>,
                              <MenuItem key="1" value="Fizik">
                                Fizik
                              </MenuItem>,
                              <MenuItem key="2" value="Klinik">
                                Klinik
                              </MenuItem>,
                              <MenuItem key="3" value="Farmasi">
                                Farmasi
                              </MenuItem>,
                              <MenuItem key="4" value="Skan">
                                Skan
                              </MenuItem>,
                              <MenuItem key="5" value="Wad Iodin">
                                Wad Iodin
                              </MenuItem>,
                            ]
                          ) : (
                            <MenuItem key="6" value="">
                              <em>This department doesn&apos;t have list yet</em>
                            </MenuItem>
                          )}
                        </Select>
                      </FormControl>
                    </MDBox>
                    <MDBox mb={2}>
                      <MDInput
                        style={{ height: 45 }}
                        type="text"
                        label="Medical Examination Record Number"
                        fullWidth
                        onChange={(e) => setNewHealthScrNo(e.target.value)}
                      />
                    </MDBox>
                    <MDBox mb={2}>
                      <Checkbox
                        sx={{ ml: -1 }}
                        onClick={() => {
                          setUseNanodot1(!useNanodot1);
                          setUseNanodot2(false);
                        }}
                      />
                      <MDTypography variant="button" fontWeight="regular" color="text">
                        Use nanoDot™ dosimeter?
                      </MDTypography>
                    </MDBox>
                    <MDBox mb={2}>
                      <Checkbox
                        sx={{ ml: -1 }}
                        onClick={() => setUseNanodot2(!useNanodot2)}
                        disabled={!useNanodot1}
                        checked={useNanodot2}
                      />
                      <MDTypography variant="button" fontWeight="regular" color="text">
                        Use 2nd nanoDot™ dosimeter?
                      </MDTypography>
                    </MDBox>
                    <MDBox mb={2}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={newStatus}
                            onChange={handleStatusChange}
                            color="primary"
                          />
                        }
                        label={
                          newStatus ? (
                            <MDBox display="flex" flexDirection="row">
                              <MDTypography variant="button" fontWeight="regular" color="text">
                                Status :&nbsp;
                              </MDTypography>
                              <MDTypography variant="button" fontWeight="regular" color="success">
                                Active
                              </MDTypography>
                            </MDBox>
                          ) : (
                            <MDBox display="flex" flexDirection="row">
                              <MDTypography variant="button" fontWeight="regular" color="text">
                                Status :&nbsp;
                              </MDTypography>
                              <MDTypography variant="button" fontWeight="regular" color="error">
                                Inactive
                              </MDTypography>
                            </MDBox>
                          )
                        }
                        labelPlacement="end"
                        color="error"
                      />
                    </MDBox>
                  </MDBox>
                </MDBox>
              </Grid>
            )}
          </Grid>

          {!duplicateIc && newIcNum.length === 12 && (
            <>
              <MDBox display="flex" alignItems="center" ml={3}>
                <Checkbox sx={{ ml: -1 }} onClick={toggleButton} />
                <MDTypography
                  variant="button"
                  fontWeight="regular"
                  color="text"
                  // sx={{ cursor: "pointer", userSelect: "none" }}
                >
                  I agree that the data provided is correct
                </MDTypography>
              </MDBox>
              <MDBox mt={3} mb={3} ml={3} mr={3}>
                <MDButton
                  variant="gradient"
                  color="info"
                  fullWidth
                  disabled={button}
                  type="submit"
                  onClick={() => {
                    dispatch(circularProgressOpen());
                    if (
                      newName?.length === 0 ||
                      newIcNum?.length === 0 ||
                      newJob?.length === 0 ||
                      newGrade?.length === 0 ||
                      newDept?.length === 0 ||
                      newUnit?.length === 0 ||
                      newHealthScrNo?.length === 0
                    ) {
                      dispatch(circularProgressClose());
                    }
                  }}
                >
                  Register
                </MDButton>
              </MDBox>
            </>
          )}
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Register;
