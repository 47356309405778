import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import authorsTableData from "layouts/tables/data/authorsTableData";
// import MDButton from "components/MDButton";
// import { Link } from "react-router-dom";
import { CircularProgress, Backdrop, Grid, Card } from "@mui/material";
import React, { useEffect, useState, useCallback, useMemo, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { doc, updateDoc, Timestamp, deleteDoc } from "firebase/firestore";
import { UserContext } from "context/UserContext";
import SuccessfulTransferProfileSnackBar from "components/SnackBar/SuccessfulTransferProfileSnackBar";
import DialogProfileView from "./components/DialogProfileView";
import DialogConfirmation from "./components/DialogConfirmation";
import DialogProfileEdit from "./components/DialogProfileEdit";
import OnlineStatusSnackbar from "../onlineStatusSnackbar/onlineStatusSnackbar";
import { db } from "../../firebase-config";
import { closeDialogView } from "../../slices/deleteSlice";

function TableComponent() {
  useEffect(() => {
    document.title = "Profiles Table - RadDB";
  }, []);

  const { columns, rows } = authorsTableData();

  return (
    <DataTable
      search={{ fields: ["searchable"] }}
      table={{ columns, rows }}
      entriesPerPage={{ defaultValue: 100 }}
      isSorted
      showTotalEntries
      noEndBorder
      canSearch
    />
  );
}

const TableComponentMemo = React.memo(TableComponent);

function Tables() {
  const { users } = useContext(UserContext);
  const dispatch = useDispatch();
  const { openDialogView } = useSelector((state) => state.delete);
  const { id } = useSelector((state) => state.idDelete);
  const [showSuccessfulTransferProfileSnackBar, setShowSuccessfulTransferProfileSnackBar] =
    useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

  const handleClickConfirmationOpen = () => {
    setOpenConfirmationDialog(true);
  };

  const handleCloseConfirmationDialog = () => {
    setOpenConfirmationDialog(false);
  };

  const [openDialogEdit, setOpenDialogEdit] = useState(false);

  const handleClickEditDialog = () => {
    setOpenDialogEdit(true);
  };

  const [backdropStatus, setBackdropStatus] = useState(false);

  const onClickBackDropStatus = () => {
    setBackdropStatus(true);
  };

  const closeBackDropStatus = () => {
    setBackdropStatus(false);
  };

  const height = { height: 44 };

  const specificUser = users.filter((e) => e.id === id);

  // https://www.youtube.com/watch?v=uVPtYLGPL80&t=405s&ab_channel=Logicism
  const handleDelete = useCallback(async (idFB) => {
    const docRef = doc(db, "users", idFB);
    await deleteDoc(docRef);
  }, []);

  const initialStates = {
    newName: specificUser[0]?.name,
    newEmail: specificUser[0]?.email,
    newAddress: specificUser[0]?.address,
    newEmployDate: specificUser[0]?.emplDate?.seconds * 1000,
    newMedCheckUp: specificUser[0]?.nextCheckUp?.seconds * 1000,
    newJob: specificUser[0]?.job,
    newGrade: specificUser[0]?.grade,
    newDept: specificUser[0]?.dept,
    newUnit: specificUser[0]?.unit,
    newHealthScrNo: specificUser[0]?.healthScrNo,
    newStatus: specificUser[0]?.active,
    newTelNo: specificUser[0]?.tel,
    newHosp: specificUser[0]?.hosp,
    useNanodot1: specificUser[0]?.useNanodot1,
    useNanodot2: specificUser[0]?.useNanodot2,
  };

  const immutableState = { ...initialStates };
  const memoedInitialStates = useMemo(() => immutableState, [specificUser[0]?.name]);
  const [state, setState] = useState(memoedInitialStates);

  const handleCloseEditDialog = () => {
    setState(memoedInitialStates);
    setOpenDialogEdit(false);
  };

  useEffect(() => {
    setState(immutableState);
  }, [specificUser[0]?.icNum, openDialogEdit, openDialogView, users]);

  const birthdayDate = new Date(specificUser[0]?.birthday?.seconds * 1000);

  const birthdate = [
    birthdayDate.getFullYear(),
    `0${birthdayDate.getMonth() + 1}`.slice(-2),
    `0${birthdayDate.getDate()}`.slice(-2),
  ].join("-");

  const reportDutyDate = new Date(state.newEmployDate);

  const reportDutyDateValue = [
    reportDutyDate.getFullYear(),
    `0${reportDutyDate.getMonth() + 1}`.slice(-2),
    `0${reportDutyDate.getDate()}`.slice(-2),
  ].join("-");

  const nextCheckUpDate = new Date(state.newMedCheckUp);

  const nextCheckUpDateValue = [
    nextCheckUpDate.getFullYear(),
    `0${nextCheckUpDate.getMonth() + 1}`.slice(-2),
    `0${nextCheckUpDate.getDate()}`.slice(-2),
  ].join("-");

  const submit = async (e) => {
    e.preventDefault();
    onClickBackDropStatus();
    await updateDoc(doc(db, "users", id), {
      name: state.newName,
      email: state.newEmail,
      address: state.newAddress,
      tel: state.newTelNo,
      emplDate: Timestamp.fromDate(new Date(new Date(state.newEmployDate).setHours(0, 0, 0, 0))),
      nextCheckUp: Timestamp.fromDate(new Date(new Date(state.newMedCheckUp).setHours(0, 0, 0, 0))),
      job: state.newJob,
      grade: state.newGrade,
      dept: state.newDept,
      unit: state.newUnit,
      healthScrNo: state.newHealthScrNo,
      active: state.newStatus,
      hosp: state.newHosp,
      useNanodot1: typeof state?.useNanodot1 === "undefined" ? false : state.useNanodot1,
      useNanodot2: typeof state?.useNanodot2 === "undefined" ? false : state.useNanodot2,
    }).then(() => {
      if (state.newHosp !== initialStates.newHosp) {
        setShowSuccessfulTransferProfileSnackBar(true);
        dispatch(closeDialogView());
      }
      handleCloseEditDialog();
      closeBackDropStatus();
    });
  };

  return (
    <DashboardLayout>
      <OnlineStatusSnackbar />
      <SuccessfulTransferProfileSnackBar
        showSuccessfulTransferProfileSnackBar={showSuccessfulTransferProfileSnackBar}
        onClose={() => setShowSuccessfulTransferProfileSnackBar(false)}
      />
      <DialogProfileView
        openDialogView={openDialogView && specificUser.length !== 0}
        active={specificUser[0]?.active}
        handleClickEditDialog={handleClickEditDialog}
        handleDelete={handleDelete}
        dispatchCloseDialogView={() => {
          dispatch(closeDialogView());
        }}
        newName={state.newName}
        newGrade={specificUser[0]?.grade}
        icNum={specificUser[0]?.icNum}
        newUnit={state.newUnit}
        newDept={state.newDept}
        job={specificUser[0]?.job}
        birthday={specificUser[0]?.birthday.seconds * 1000}
        address={specificUser[0]?.address}
        tel={specificUser[0]?.tel}
        email={specificUser[0]?.email}
        employDate={specificUser[0]?.emplDate.seconds * 1000}
        checkUpDate={specificUser[0]?.nextCheckUp.seconds * 1000}
        sex={specificUser[0]?.sex}
        useNanodot1={state.useNanodot1}
        useNanodot2={state.useNanodot2}
        healthScrNo={specificUser[0]?.healthScrNo}
      />

      <DialogConfirmation
        openConfirmationDialog={openConfirmationDialog && specificUser.length !== 0}
        newName={state.newName}
        handleCloseConfirmationDialog={handleCloseConfirmationDialog}
        dispatchCloseDialogView={() => {
          handleCloseConfirmationDialog();
          dispatch(closeDialogView());
          handleCloseEditDialog();
          handleDelete(id);
        }}
      />

      <DialogProfileEdit
        openDialogEdit={openDialogEdit && specificUser.length !== 0}
        submit={submit}
        height={height}
        handleNameChange={(e) => setState({ ...state, newName: e.target.value })}
        newName={state.newName || ""}
        icNum={specificUser[0]?.icNum}
        birthdate={birthdate}
        sex={specificUser[0]?.sex}
        newEmail={state.newEmail || ""}
        handleEmailChange={(e) => {
          setState({ ...state, newEmail: e.target.value });
        }}
        newAddress={state.newAddress || ""}
        handleAddressChange={(e) => setState({ ...state, newAddress: e.target.value })}
        newTelNo={state.newTelNo || ""}
        handleNewTelNo={(e) => setState({ ...state, newTelNo: e.target.value })}
        reportDutyDateValue={reportDutyDateValue}
        handleReportDutyDateValueChange={(e) =>
          setState({ ...state, newEmployDate: e.target.value })
        }
        nextCheckUpDateValue={nextCheckUpDateValue}
        handleNextCheckUpDateValueChange={(e) =>
          setState({ ...state, newMedCheckUp: e.target.value })
        }
        newJob={state.newJob || ""}
        handleNewJobChange={(e) => setState({ ...state, newJob: e.target.value })}
        newGrade={state.newGrade || ""}
        handleNewGradeChange={(e) => setState({ ...state, newGrade: e.target.value })}
        newDept={state.newDept || ""}
        handleNewDeptChange={(e) => setState({ ...state, newDept: e.target.value })}
        newUnit={state.newUnit || ""}
        handleNewUnitChange={(e) => setState({ ...state, newUnit: e.target.value })}
        newHealthScrNo={state.newHealthScrNo || ""}
        handleNewHealthScrNoChange={(e) => setState({ ...state, newHealthScrNo: e.target.value })}
        newStatus={state.newStatus}
        handleNewStatusChange={(e) => setState({ ...state, newStatus: e.target.checked })}
        handleClickConfirmationOpen={handleClickConfirmationOpen}
        handleCloseEditDialog={() => handleCloseEditDialog()}
        handleWorkplaceChange={(e) => setState({ ...state, newHosp: e.target.value })}
        workplace={state.newHosp || ""}
        useNanodot1={state?.useNanodot1}
        useNanodot2={state?.useNanodot2}
        handleUseNanodot1={() =>
          setState((prevState) => ({
            ...prevState,
            useNanodot1: !prevState.useNanodot1,
            useNanodot2: false,
          }))
        }
        handleUsenanodot2={() =>
          setState((prevState) => ({
            ...prevState,
            useNanodot2: !prevState.useNanodot2,
          }))
        }
      />

      <DashboardNavbar />
      {/* <MDBox pt={3} pb={3} sx={{ display: "flex", justifyContent: "start" }}>
        <MDButton
          size="small"
          color="info"
          variant="gradient"
          component={Link}
          to="/radiation-workers/register"
        >
          <Icon>create</Icon>&nbsp; Register
        </MDButton>
      </MDBox> */}
      <MDBox pt={6} pb={3} position="relative">
        <Backdrop
          sx={{
            borderRadius: 5,
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
            position: "absolute",

            width: "100%",
            height: "100%",
          }}
          open={!users.length}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 9999,
          }}
          open={backdropStatus}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card sx={{ marginTop: 8 }}>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Radiation Workers
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <TableComponentMemo />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Tables;
