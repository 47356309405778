import {
  Dialog,
  Card,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
  FormControlLabel,
  Switch,
  ListSubheader,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { UserAuth } from "context/AuthContext";

export default function DialogProfileEdit({
  openDialogEdit,
  submit,
  height,
  handleNameChange,
  newName,
  icNum,
  birthdate,
  sex,
  newEmail,
  handleEmailChange,
  newAddress,
  handleAddressChange,
  newTelNo,
  handleNewTelNo,
  reportDutyDateValue,
  handleReportDutyDateValueChange,
  nextCheckUpDateValue,
  handleNextCheckUpDateValueChange,
  newJob,
  handleNewJobChange,
  newGrade,
  handleNewGradeChange,
  newDept,
  handleNewDeptChange,
  newUnit,
  handleNewUnitChange,
  newHealthScrNo,
  handleNewHealthScrNoChange,
  newStatus,
  handleNewStatusChange,
  handleClickConfirmationOpen,
  handleCloseEditDialog,
  workplace,
  handleWorkplaceChange,
  useNanodot1,
  useNanodot2,
  handleUseNanodot1,
  handleUsenanodot2,
}) {
  const { user } = UserAuth();
  return (
    <Dialog
      open={openDialogEdit}
      maxWidth="md"
      fullWidth
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Card component="form" role="form" onSubmit={submit}>
        <Grid container spacing={0}>
          <Grid item xs={12} md={6} lg={6}>
            <MDBox pt={4} px={3} sx={{ pb: { xs: 0, sm: 0, md: 3, lg: 3 } }}>
              <MDBox>
                <MDBox mb={2}>
                  <MDInput
                    style={height}
                    type="text"
                    label="Name"
                    fullWidth
                    onChange={handleNameChange}
                    value={newName}
                  >
                    <MDTypography />
                  </MDInput>
                </MDBox>

                <MDBox mb={2}>
                  <MDInput
                    type="text"
                    style={height}
                    label="Malaysian IC Number"
                    fullWidth
                    inputProps={{ maxLength: 12, readOnly: true }}
                    value={icNum}
                  />
                </MDBox>
                <MDBox mb={2}>
                  <MDInput
                    style={height}
                    InputLabelProps={{ shrink: true }}
                    type="date"
                    label="Date of Birth"
                    fullWidth
                    inputProps={{
                      readOnly: true,
                    }}
                    value={birthdate}
                  />
                </MDBox>
                <MDBox mb={2}>
                  <MDInput
                    style={height}
                    InputLabelProps={{ shrink: true }}
                    type="text"
                    label="Sex"
                    fullWidth
                    inputProps={{
                      readOnly: true,
                    }}
                    value={sex}
                  />
                </MDBox>
                <MDBox mb={2}>
                  <MDInput
                    style={height}
                    value={newEmail}
                    type="email"
                    label="E-mail"
                    fullWidth
                    onChange={handleEmailChange}
                  >
                    <MDTypography />
                  </MDInput>
                </MDBox>
                <MDBox mb={2}>
                  <MDInput
                    value={newAddress}
                    type="text"
                    label="Address"
                    onChange={handleAddressChange}
                    multiline
                    rows={4}
                    fullWidth
                  />
                </MDBox>
                <MDBox mb={2}>
                  <MDInput
                    style={height}
                    type="text"
                    value={newTelNo}
                    label="Telephone number"
                    onChange={handleNewTelNo}
                    fullWidth
                  />
                </MDBox>
              </MDBox>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <MDBox pb={3} px={3} sx={{ pt: { xs: 0, sm: 0, md: 4, lg: 4 } }}>
              <MDBox>
                <MDBox mb={2}>
                  <MDInput
                    style={height}
                    InputLabelProps={{ shrink: true }}
                    type="date"
                    label="Report Duty Date"
                    fullWidth
                    value={reportDutyDateValue}
                    onChange={handleReportDutyDateValueChange}
                  />
                </MDBox>
                <MDBox mb={2}>
                  <MDInput
                    style={height}
                    InputLabelProps={{ shrink: true }}
                    type="date"
                    label="Next Medical Checkup"
                    fullWidth
                    value={nextCheckUpDateValue}
                    onChange={handleNextCheckUpDateValueChange}
                  />
                </MDBox>
                <MDBox mb={2}>
                  <FormControl required fullWidth>
                    <InputLabel id="job-select-label">Profession</InputLabel>
                    <Select
                      labelId="job-select-label"
                      id="job-select"
                      value={newJob}
                      label="Profession"
                      onChange={handleNewJobChange}
                      style={height}
                    >
                      {/* <MenuItem key="0" value="">
                        <em>Please Select</em>
                      </MenuItem> */}
                      <MenuItem key="1" value="Pakar Perubatan Nuklear">
                        Pakar Perubatan Nuklear
                      </MenuItem>
                      <MenuItem key="2" value="Pegawai Perubatan">
                        Pegawai Perubatan
                      </MenuItem>
                      <MenuItem key="3" value="Pegawai Sains (Fizik)">
                        Pegawai Sains (Fizik)
                      </MenuItem>
                      <MenuItem key="4" value="Pegawai Farmasi">
                        Pegawai Farmasi
                      </MenuItem>
                      <MenuItem key="5" value="Penolong Pegawai Perubatan">
                        Penolong Pegawai Perubatan
                      </MenuItem>
                      <MenuItem key="6" value="Juru X-ray Diagnostik">
                        Juru X-ray Diagnostik
                      </MenuItem>
                      <MenuItem key="7" value="Juru Teknologi Makmal Perubatan">
                        Juru Teknologi Makmal Perubatan
                      </MenuItem>
                      <MenuItem key="8" value="Jururawat">
                        Jururawat
                      </MenuItem>
                      <MenuItem key="9" value="Pembantu Perawatan Kesihatan">
                        Pembantu Perawatan Kesihatan
                      </MenuItem>
                    </Select>
                  </FormControl>
                </MDBox>
                <MDBox mb={2}>
                  <FormControl required fullWidth>
                    <InputLabel id="grade-select-label">Grade</InputLabel>
                    <Select
                      labelId="grade-select-label"
                      id="grade-select"
                      value={newGrade}
                      label="Grade"
                      onChange={handleNewGradeChange}
                      style={height}
                    >
                      {(() => {
                        if (
                          newJob === "Pakar Perubatan Nuklear" ||
                          newJob === "Pegawai Perubatan"
                        ) {
                          return [
                            <MenuItem key="jusa-c" value="JUSA C">
                              JUSA C
                            </MenuItem>,
                            <MenuItem key="ud58" value="UD58">
                              UD58
                            </MenuItem>,
                            <MenuItem key="ud56" value="UD56">
                              UD56
                            </MenuItem>,
                            <MenuItem key="ud54" value="UD54">
                              UD54
                            </MenuItem>,
                            <MenuItem key="ud52" value="UD52">
                              UD52
                            </MenuItem>,
                            <MenuItem key="ud48" value="UD48">
                              UD48
                            </MenuItem>,
                            <MenuItem key="ud44" value="UD44">
                              UD44
                            </MenuItem>,
                            <MenuItem key="ud41" value="UD41">
                              UD41
                            </MenuItem>,
                          ];
                        }

                        if (newJob === "Pegawai Sains (Fizik)") {
                          return [
                            <MenuItem key="khas-c" value="KHAS C">
                              KHAS C
                            </MenuItem>,
                            <MenuItem key="c52" value="C52">
                              C52
                            </MenuItem>,
                            <MenuItem key="c48" value="C48">
                              C48
                            </MenuItem>,
                            <MenuItem key="c44" value="C44">
                              C44
                            </MenuItem>,
                            <MenuItem key="c41" value="C41">
                              C41
                            </MenuItem>,
                          ];
                        }

                        if (newJob === "Pegawai Farmasi") {
                          return [
                            <MenuItem key="uf54" value="UF54">
                              UF54
                            </MenuItem>,
                            <MenuItem key="uf52" value="UF52">
                              UF52
                            </MenuItem>,
                            <MenuItem key="uf48" value="UF48">
                              UF48
                            </MenuItem>,
                            <MenuItem key="uf44" value="UF44">
                              UF44
                            </MenuItem>,
                            <MenuItem key="uf41" value="UF41">
                              UF41
                            </MenuItem>,
                          ];
                        }

                        if (newJob === "Penolong Pegawai Perubatan") {
                          return [
                            <MenuItem key="u41" value="U41">
                              U41
                            </MenuItem>,
                            <MenuItem key="u36" value="U36">
                              U36
                            </MenuItem>,
                            <MenuItem key="u32" value="U32">
                              U32
                            </MenuItem>,
                            <MenuItem key="u29" value="U29">
                              U29
                            </MenuItem>,
                          ];
                        }

                        if (newJob === "Juru X-ray Diagnostik") {
                          return [
                            <MenuItem key="u32" value="U32">
                              U32
                            </MenuItem>,
                            <MenuItem key="u29" value="U29">
                              U29
                            </MenuItem>,
                          ];
                        }

                        if (newJob === "Juru Teknologi Makmal Perubatan") {
                          return [
                            <MenuItem key="u29" value="U29">
                              U29
                            </MenuItem>,
                          ];
                        }

                        if (newJob === "Jururawat") {
                          return [
                            <MenuItem key="u36" value="U36">
                              U36
                            </MenuItem>,
                            <MenuItem key="u32" value="U32">
                              U32
                            </MenuItem>,
                            <MenuItem key="u32-kup" value="U32 (KUP)">
                              U32 (KUP)
                            </MenuItem>,
                            <MenuItem key="u29" value="U29">
                              U29
                            </MenuItem>,
                          ];
                        }

                        if (newJob === "Pembantu Perawatan Kesihatan") {
                          return [
                            <MenuItem key="u14" value="U14">
                              U14
                            </MenuItem>,
                            <MenuItem key="u11" value="U11">
                              U11
                            </MenuItem>,
                          ];
                        }

                        return (
                          <MenuItem value="">
                            <em>Please Select Profession First</em>
                          </MenuItem>
                        );
                      })()}
                    </Select>
                  </FormControl>
                </MDBox>
                <MDBox mb={2}>
                  <FormControl required fullWidth>
                    <InputLabel id="department-select-label">Department</InputLabel>
                    <Select
                      labelId="department-select-label"
                      id="department-select"
                      value={newDept}
                      label="Department"
                      onChange={handleNewDeptChange}
                      style={height}
                    >
                      <MenuItem value="">
                        <em>Please Select</em>
                      </MenuItem>
                      <MenuItem key="jabatan-perubatan-nuklear" value="Jabatan Perubatan Nuklear">
                        Jabatan Perubatan Nuklear
                      </MenuItem>
                      <MenuItem key="jabatanRadioterapi" value="Jabatan Radioterapi dan Onkologi">
                        Jabatan Radioterapi dan Onkologi
                      </MenuItem>
                      <MenuItem key="jabatanRadiologi" value="Jabatan Radiologi">
                        Jabatan Radiologi
                      </MenuItem>
                    </Select>
                  </FormControl>
                </MDBox>
                <MDBox mb={2}>
                  <FormControl required fullWidth>
                    <InputLabel id="unit-select-label">Unit</InputLabel>
                    <Select
                      labelId="unit-select-label"
                      id="unit-select"
                      value={newUnit}
                      label="Unit"
                      onChange={handleNewUnitChange}
                      style={height}
                    >
                      {newDept === "Jabatan Perubatan Nuklear" ? (
                        [
                          <MenuItem key="select" value="">
                            <em>Please Select</em>
                          </MenuItem>,
                          <MenuItem key="fizik" value="Fizik">
                            Fizik
                          </MenuItem>,
                          <MenuItem key="klinik" value="Klinik">
                            Klinik
                          </MenuItem>,
                          <MenuItem key="farmasi" value="Farmasi">
                            Farmasi
                          </MenuItem>,
                          <MenuItem key="skan" value="Skan">
                            Skan
                          </MenuItem>,
                          <MenuItem key="wad" value="Wad Iodin">
                            Wad Iodin
                          </MenuItem>,
                        ]
                      ) : (
                        <MenuItem value="">
                          <em>This department doesn&apos;t have list yet</em>
                        </MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </MDBox>
                <MDBox mb={2}>
                  <MDInput
                    style={{ height: 45 }}
                    type="text"
                    label="Medical Examination Record Number"
                    fullWidth
                    value={newHealthScrNo}
                    onChange={handleNewHealthScrNoChange}
                  />
                </MDBox>
                <MDBox mb={2}>
                  <Checkbox
                    sx={{ ml: -1 }}
                    onClick={handleUseNanodot1}
                    checked={typeof useNanodot1 === "undefined" ? false : useNanodot1}
                  />
                  <MDTypography variant="button" fontWeight="regular" color="text">
                    Use nanoDot™ dosimeter?
                  </MDTypography>
                </MDBox>
                <MDBox mb={2}>
                  <Checkbox
                    sx={{ ml: -1 }}
                    onClick={handleUsenanodot2}
                    disabled={!useNanodot1}
                    checked={typeof useNanodot2 === "undefined" ? false : useNanodot2}
                  />
                  <MDTypography variant="button" fontWeight="regular" color="text">
                    Use 2nd nanoDot™ dosimeter?
                  </MDTypography>
                </MDBox>
                <MDBox mb={2}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={newStatus}
                        onChange={handleNewStatusChange}
                        color="primary"
                      />
                    }
                    label={
                      newStatus ? (
                        <MDBox display="flex" flexDirection="row">
                          <MDTypography variant="button" fontWeight="regular" color="text">
                            Status :&nbsp;
                          </MDTypography>
                          <MDTypography variant="button" fontWeight="regular" color="success">
                            Active
                          </MDTypography>
                        </MDBox>
                      ) : (
                        <MDBox display="flex" flexDirection="row">
                          <MDTypography variant="button" fontWeight="regular" color="text">
                            Status :&nbsp;
                          </MDTypography>
                          <MDTypography variant="button" fontWeight="regular" color="error">
                            Inactive
                          </MDTypography>
                        </MDBox>
                      )
                    }
                    labelPlacement="end"
                    color="error"
                  />
                </MDBox>
              </MDBox>
            </MDBox>
          </Grid>
        </Grid>
        <DialogActions style={{ display: "flex", justifyContent: "space-between" }}>
          <MDBox>
            {(user.email === "aimifirdhausshafie@gmail.com" ||
              user.email === "demo@raddosedb.com") && (
              <MDButton variant="outlined" color="error" onClick={handleClickConfirmationOpen}>
                Delete
              </MDButton>
            )}
            {user.email !== "demo@raddosedb.com" && (
              <FormControl sx={{ ml: 1, minWidth: 200 }} error>
                <InputLabel htmlFor="grouped-select">Transfer Profile</InputLabel>
                <Select
                  style={height}
                  // InputLabelProps={{ shrink: true }}
                  value={workplace}
                  id="grouped-select"
                  label="Transfer Profile"
                  fullWidth
                  onChange={handleWorkplaceChange}
                >
                  <ListSubheader>Listed Hospitals</ListSubheader>
                  <MenuItem value="Hospital Kuala Lumpur">Hospital Kuala Lumpur</MenuItem>
                  <MenuItem value="Institut Kanser Negara">Institut Kanser Negara</MenuItem>
                  <MenuItem value="Hospital Pulau Pinang">Hospital Pulau Pinang</MenuItem>
                  <MenuItem value="Hospital Sultanah Aminah">Hospital Sultanah Aminah</MenuItem>
                  <MenuItem value="Hospital Umum Sarawak">Hospital Umum Sarawak</MenuItem>
                  <MenuItem value="Hospital Wanita & Kanak-Kanak Sabah">
                    Hospital Wanita & Kanak-Kanak Sabah
                  </MenuItem>
                  <ListSubheader>Unlisted Hospitals</ListSubheader>
                  <MenuItem value="Limbo">Limbo</MenuItem>
                </Select>
              </FormControl>
            )}
          </MDBox>
          <MDBox display="flex" flexDirection={{ xs: "column", sm: "row" }}>
            <MDButton
              sx={{ mb: { xs: 1, sm: 0 } }}
              variant="outlined"
              color="warning"
              onClick={handleCloseEditDialog}
            >
              Cancel
            </MDButton>
            <MDButton
              sx={{ ml: { xs: 0, sm: "8px" } }}
              type="submit"
              variant="outlined"
              color="success"
              onClick={submit}
            >
              Update
            </MDButton>
          </MDBox>
        </DialogActions>
      </Card>
    </Dialog>
  );
}
