import { createContext, useContext, useEffect, useState, useMemo } from "react";
import { onAuthStateChanged, signInWithEmailAndPassword, signOut } from "firebase/auth";
// import { auth } from "../firebase-config";

import * as firebase from "../firebase-config"; // import firebase object

const { auth } = firebase; // destructure auth from firebase object

const UserContext = createContext();

export function AuthContextProvider({ children }) {
  const [user, setUser] = useState({});

  const signIn = (email, password) => signInWithEmailAndPassword(auth, email, password);

  const logout = () => signOut(auth);

  const memoValue = useMemo(() => ({ signIn, user, logout }), [signIn, user, logout]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });

    return () => unsubscribe();
  }, []);

  return <UserContext.Provider value={memoValue}>{children}</UserContext.Provider>;
}

export function UserAuth() {
  return useContext(UserContext);
}
