import { useContext } from "react";
import { UserContext } from "context/UserContext";
import colors from "assets/theme/base/colors";

export default function individualHp10(id, newYear) {
  const { locations } = useContext(UserContext);

  const year = newYear;

  const filteredLocation = locations.filter((loc) => loc?.id === id);

  const janA = filteredLocation[0]?.[year]?.January?.ambientNet;
  const febA = filteredLocation[0]?.[year]?.February?.ambientNet;
  const macA = filteredLocation[0]?.[year]?.March?.ambientNet;
  const aprA = filteredLocation[0]?.[year]?.April?.ambientNet;
  const mayA = filteredLocation[0]?.[year]?.May?.ambientNet;
  const junA = filteredLocation[0]?.[year]?.June?.ambientNet;
  const julA = filteredLocation[0]?.[year]?.July?.ambientNet;
  const augA = filteredLocation[0]?.[year]?.August?.ambientNet;
  const sepA = filteredLocation[0]?.[year]?.September?.ambientNet;
  const octA = filteredLocation[0]?.[year]?.October?.ambientNet;
  const novA = filteredLocation[0]?.[year]?.November?.ambientNet;
  const decA = filteredLocation[0]?.[year]?.December?.ambientNet;

  const janAC = filteredLocation[0]?.[year]?.January ? janA || 0 : null;
  const febAC = filteredLocation[0]?.[year]?.February ? (janA || 0) + (febA || 0) : null;
  const macAC = filteredLocation[0]?.[year]?.March ? (macA || 0) + (febA || 0) + (janA || 0) : null;
  const aprAC = filteredLocation[0]?.[year]?.April
    ? (aprA || 0) + (macA || 0) + (febA || 0) + (janA || 0)
    : null;
  const mayAC = filteredLocation[0]?.[year]?.May
    ? (mayA || 0) + (aprA || 0) + (macA || 0) + (febA || 0) + (janA || 0)
    : null;
  const junAC = filteredLocation[0]?.[year]?.June
    ? (junA || 0) + (mayA || 0) + (aprA || 0) + (macA || 0) + (febA || 0) + (janA || 0)
    : null;
  const julAC = filteredLocation[0]?.[year]?.July
    ? (julA || 0) +
      (junA || 0) +
      (mayA || 0) +
      (aprA || 0) +
      (macA || 0) +
      (febA || 0) +
      (janA || 0)
    : null;
  const augAC = filteredLocation[0]?.[year]?.August
    ? (augA || 0) +
      (julA || 0) +
      (junA || 0) +
      (mayA || 0) +
      (aprA || 0) +
      (macA || 0) +
      (febA || 0) +
      (janA || 0)
    : null;
  const sepAC = filteredLocation[0]?.[year]?.September
    ? (sepA || 0) +
      (augA || 0) +
      (julA || 0) +
      (junA || 0) +
      (mayA || 0) +
      (aprA || 0) +
      (macA || 0) +
      (febA || 0) +
      (janA || 0)
    : null;
  const octAC = filteredLocation[0]?.[year]?.October
    ? (octA || 0) +
      (sepA || 0) +
      (augA || 0) +
      (julA || 0) +
      (junA || 0) +
      (mayA || 0) +
      (aprA || 0) +
      (macA || 0) +
      (febA || 0) +
      (janA || 0)
    : null;
  const novAC = filteredLocation[0]?.[year]?.November
    ? (novA || 0) +
      (octA || 0) +
      (sepA || 0) +
      (augA || 0) +
      (julA || 0) +
      (junA || 0) +
      (mayA || 0) +
      (aprA || 0) +
      (macA || 0) +
      (febA || 0) +
      (janA || 0)
    : null;
  const decAC = filteredLocation[0]?.[year]?.December
    ? (decA || 0) +
      (novA || 0) +
      (octA || 0) +
      (sepA || 0) +
      (augA || 0) +
      (julA || 0) +
      (junA || 0) +
      (mayA || 0) +
      (aprA || 0) +
      (macA || 0) +
      (febA || 0) +
      (janA || 0)
    : null;

  const janD = filteredLocation[0]?.[year]?.January?.directionalNet;
  const febD = filteredLocation[0]?.[year]?.February?.directionalNet;
  const macD = filteredLocation[0]?.[year]?.March?.directionalNet;
  const aprD = filteredLocation[0]?.[year]?.April?.directionalNet;
  const mayD = filteredLocation[0]?.[year]?.May?.directionalNet;
  const junD = filteredLocation[0]?.[year]?.June?.directionalNet;
  const julD = filteredLocation[0]?.[year]?.July?.directionalNet;
  const augD = filteredLocation[0]?.[year]?.August?.directionalNet;
  const sepD = filteredLocation[0]?.[year]?.September?.directionalNet;
  const octD = filteredLocation[0]?.[year]?.October?.directionalNet;
  const novD = filteredLocation[0]?.[year]?.November?.directionalNet;
  const decD = filteredLocation[0]?.[year]?.December?.directionalNet;

  const janDC = filteredLocation[0]?.[year]?.January ? janD || 0 : null;
  const febDC = filteredLocation[0]?.[year]?.February ? (janD || 0) + (febD || 0) : null;
  const macDC = filteredLocation[0]?.[year]?.March ? (macD || 0) + (febD || 0) + (janD || 0) : null;
  const aprDC = filteredLocation[0]?.[year]?.April
    ? (aprA || 0) + (macD || 0) + (febD || 0) + (janD || 0)
    : null;
  const mayDC = filteredLocation[0]?.[year]?.May
    ? (mayA || 0) + (aprA || 0) + (macD || 0) + (febD || 0) + (janD || 0)
    : null;
  const junDC = filteredLocation[0]?.[year]?.June
    ? (junA || 0) + (mayA || 0) + (aprA || 0) + (macD || 0) + (febD || 0) + (janD || 0)
    : null;
  const julDC = filteredLocation[0]?.[year]?.July
    ? (julA || 0) +
      (junA || 0) +
      (mayA || 0) +
      (aprA || 0) +
      (macD || 0) +
      (febD || 0) +
      (janD || 0)
    : null;
  const augDC = filteredLocation[0]?.[year]?.August
    ? (augA || 0) +
      (julA || 0) +
      (junA || 0) +
      (mayA || 0) +
      (aprA || 0) +
      (macD || 0) +
      (febD || 0) +
      (janD || 0)
    : null;
  const sepDC = filteredLocation[0]?.[year]?.September
    ? (sepA || 0) +
      (augA || 0) +
      (julA || 0) +
      (junA || 0) +
      (mayA || 0) +
      (aprA || 0) +
      (macD || 0) +
      (febD || 0) +
      (janD || 0)
    : null;
  const octDC = filteredLocation[0]?.[year]?.October
    ? (octA || 0) +
      (sepA || 0) +
      (augA || 0) +
      (julA || 0) +
      (junA || 0) +
      (mayA || 0) +
      (aprA || 0) +
      (macD || 0) +
      (febD || 0) +
      (janD || 0)
    : null;
  const novDC = filteredLocation[0]?.[year]?.November
    ? (novA || 0) +
      (octA || 0) +
      (sepA || 0) +
      (augA || 0) +
      (julA || 0) +
      (junA || 0) +
      (mayA || 0) +
      (aprA || 0) +
      (macD || 0) +
      (febD || 0) +
      (janD || 0)
    : null;
  const decDC = filteredLocation[0]?.[year]?.December
    ? (decA || 0) +
      (novA || 0) +
      (octA || 0) +
      (sepA || 0) +
      (augA || 0) +
      (julA || 0) +
      (junA || 0) +
      (mayA || 0) +
      (aprA || 0) +
      (macD || 0) +
      (febD || 0) +
      (janD || 0)
    : null;

  const chartDataMaxHp10 = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    datasets: [
      {
        label: "Cumulative ambient dose (mSv)",
        data: [janAC, febAC, macAC, aprAC, mayAC, junAC, julAC, augAC, sepAC, octAC, novAC, decAC],
        borderColor: colors.warning.main, // original : "rgba(255, 255, 255, .8)"
        pointBackgroundColor: colors.warning.main,
      },
      {
        label: "Monthly ambient dose (mSv)",
        data: [janA, febA, macA, aprA, mayA, junA, julA, augA, sepA, octA, novA, decA],
        borderColor: colors.info.main, // original : "rgba(255, 255, 255, .8)"
        pointBackgroundColor: colors.info.main,
      },
      {
        label: "Cumulative directional dose (mSv)",
        data: [janDC, febDC, macDC, aprDC, mayDC, junDC, julDC, augDC, sepDC, octDC, novDC, decDC],
        borderColor: colors.secondary.main, // original : "rgba(255, 255, 255, .8)"
        pointBackgroundColor: colors.secondary.main,
      },
      {
        label: "Monthly directional dose (mSv)",
        data: [janD, febD, macD, aprD, mayD, junD, julD, augD, sepD, octD, novD, decD],
        borderColor: colors.error.main, // original : "rgba(255, 255, 255, .8)"
        pointBackgroundColor: colors.error.main,
      },
    ],
  };

  return chartDataMaxHp10;
}
