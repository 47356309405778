/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

export const extremityDosimeter = createSlice({
  name: "extremityDosimeter",
  initialState: {
    id: "",
    name: "",
    analysedUsers: [],
  },
  reducers: {
    id: (state, action) => {
      state.id = action.payload;
    },
    name: (state, action) => {
      state.name = action.payload;
    },
    analysed: (state, action) => ({
      analysedUsers: [action.payload, ...state.analysedUsers],
    }),
    resetAnalysed: (state) => {
      state.analysedUsers = [];
    },
    deleteAnalysed: (state, action) => {
      state.analysedUsers = state.analysedUsers.filter((e) => e.id !== action.payload);
    },
  },
});

export const { id, name, analysed, resetAnalysed, deleteAnalysed } = extremityDosimeter.actions;

export default extremityDosimeter.reducer;
