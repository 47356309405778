import * as XLSX from "xlsx";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  Grid,
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
  Backdrop,
  CircularProgress,
  Snackbar,
  Alert,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/DeleteForever";
import { useSelector, useDispatch } from "react-redux";
import DataTable from "examples/Tables/DataTable";
import * as React from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { useEffect, useState, useCallback, useContext } from "react";
import { doc, writeBatch } from "firebase/firestore";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { UserContext } from "context/UserContext";
import { db } from "../../../firebase-config";
import { analysed, resetAnalysed, deleteAnalysed } from "../../../slices/extremityDosimeterSlice";
import OnlineStatusSnackbar from "../../onlineStatusSnackbar/onlineStatusSnackbar";

function Analysis() {
  useEffect(() => {
    document.title = "Dosimeter Assignment - RadDB";
  }, []);
  const [error, setError] = useState("");
  const { users } = useContext(UserContext);
  const dispatch = useDispatch();
  const { analysedUsers } = useSelector((state) => state.extremityDosimeter);
  const [arrBg, setArrBg] = useState([]);
  const [openSwitchDialog, setOpenSwitchDialog] = useState(false);
  const [noNanodotDialog, setNoNanodotDialog] = useState(false);
  const [ringNoDialog, setRingNoDialog] = useState(false);
  const [ringNo2Dialog, setRingNo2Dialog] = useState(false);
  const [ringNo, setRingNo] = useState("");
  const [ringNo2, setRingNo2] = useState("");
  const [newYear, setNewYear] = useState("");
  const [newMonth, setNewMonth] = useState("");
  const [newIcNum, setNewIcNum] = useState("");
  const [assignDialogOpen, setAssignDialogOpen] = useState(false);
  const [assignDialog2Open, setAssignDialog2Open] = useState(false);
  const [newDoseCode, setNewDoseCode] = useState("");
  const [newDoseCode2, setNewDoseCode2] = useState("");
  const [circularOpen, setCircularOpen] = useState(false);
  const [duplicationDialogOpen, setDuplicationDialogOpen] = useState(false);
  const [duplicationRingNoDialogOpen, setDuplicationRingNoDialogOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState({
    openSnackBar: false,
    vertical: "top",
    horizontal: "center",
  });
  const { vertical, horizontal, openSnackBar } = snackbarOpen;

  const indexOfUserWithIcNum = users.findIndex((e) => e?.icNum === newIcNum);

  const useNanodot1Only =
    users[indexOfUserWithIcNum]?.useNanodot1 && !users[indexOfUserWithIcNum]?.useNanodot2;

  const useNanodot1and2 =
    users[indexOfUserWithIcNum]?.useNanodot1 && users[indexOfUserWithIcNum]?.useNanodot2;

  const onChangeFileBackground = useCallback(
    (e) => {
      const [file] = e.target.files;
      const reader = new FileReader();

      reader.onload = (evt) => {
        const jsonObj = [];
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, { type: "binary" });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const dataXLSX = XLSX.utils.sheet_to_csv(ws, { header: 1 }).split("\n");

        // Source: https://stackoverflow.com/questions/59016562/parse-csv-records-in-to-an-array-of-objects-in-javascript
        const headers = dataXLSX[0].split(",");
        for (let i = 1; i < dataXLSX.length; i += 1) {
          const dutu = dataXLSX[i].split(",");
          const obj = {};
          for (let j = 0; j < dutu.length; j += 1) {
            obj[headers[j].trim()] = dutu[j].trim();
          }

          jsonObj.push(obj);
        }

        const mapped = jsonObj.filter((element) => element["Serial Number"] !== "");

        setArrBg(mapped);
      };

      reader.readAsBinaryString(file);
    },
    [arrBg]
  );

  useEffect(() => {
    if (document.getElementById("bgFile")) {
      document.getElementById("bgFile").focus();
    }
  }, [newMonth]);

  useEffect(() => {
    if (document.getElementById("month-select")) {
      document.getElementById("month-select").focus();
    }
  }, [newYear.length === 4]);

  useEffect(() => {
    if (document.getElementById("ic-num-input")) {
      document.getElementById("ic-num-input").focus();
    }
  }, [arrBg.length > 0, ringNo2Dialog]);

  useEffect(() => {
    if (document.getElementById("assign-dialog-2")) {
      document.getElementById("assign-dialog-2").focus();
    }
  }, [ringNoDialog]);

  useEffect(() => {
    if (
      indexOfUserWithIcNum > -1 &&
      users[indexOfUserWithIcNum]?.useNanodot1 &&
      newIcNum.length === 12
    ) {
      setAssignDialogOpen(true);
      setNoNanodotDialog(false);
    } else if (
      indexOfUserWithIcNum > -1 &&
      !users[indexOfUserWithIcNum]?.useNanodot1 &&
      newIcNum.length === 12
    ) {
      setAssignDialogOpen(false);
      setNoNanodotDialog(true);
    }
  }, [users[indexOfUserWithIcNum]?.useNanodot1, newIcNum.length === 12]);

  const handleClose = () => {
    setNewIcNum("");
    setNoNanodotDialog(false);
    setAssignDialogOpen(false);
  };

  const handleCloseSwitchDialog = () => {
    setOpenSwitchDialog(false);
  };

  const handleCircularOpen = () => {
    setCircularOpen(true);
  };

  const handleCircularClose = () => {
    setCircularOpen(false);
  };

  const handleSnackbarOpen = () => {
    setSnackbarOpen({
      openSnackBar: true,
      vertical: "top",
      horizontal: "center",
    });
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen({
      ...snackbarOpen,
      openSnackBar: false,
    });
  };

  const handleRingNoChange = (e) => {
    const newValue = e.target.value;
    if (newValue.match(/[^0-9]/)) {
      setError("Insert digits only!");
      setRingNo(newValue);
    } else {
      setError("");
      setRingNo(newValue);
    }
  };

  const handleRingNo2Change = (e) => {
    const newValue = e.target.value;
    if (newValue.match(/[^0-9]/)) {
      setError("Insert digits only!");
      setRingNo2(newValue);
    } else {
      setError("");
      setRingNo2(newValue);
    }
  };

  const filteredUser = users.filter((user) => user.icNum === newIcNum && user?.useNanodot1);
  const filteredDoseCode = arrBg.filter((e) => e["Serial Number"] === newDoseCode);
  const filteredDoseCode2 = arrBg.filter((e) => e["Serial Number"] === newDoseCode2);

  useEffect(() => {
    if (filteredDoseCode.length === 0) {
      setOpenSwitchDialog(true);
    }
  }, [filteredDoseCode.length === 0, newDoseCode.length === 11]);

  useEffect(() => {
    setDuplicationDialogOpen(
      analysedUsers.filter((user) => user.doseCode === newDoseCode).length > 0 ||
        analysedUsers.filter((user) => user.doseCode2 === newDoseCode).length > 0
    );

    if (
      newDoseCode.length === 11 &&
      filteredDoseCode.length > 0 &&
      analysedUsers.filter((user) => user.doseCode === newDoseCode).length === 0 &&
      analysedUsers.filter((user) => user.doseCode2 === newDoseCode).length === 0 &&
      (useNanodot1Only || useNanodot1and2)
    ) {
      setAssignDialogOpen(false);
      setRingNoDialog(true);
    }
  }, [newDoseCode.length === 11]);

  useEffect(() => {
    setDuplicationDialogOpen(
      analysedUsers.filter((user) => user.doseCode === newDoseCode2).length > 0 ||
        analysedUsers.filter((user) => user.doseCode2 === newDoseCode2).length > 0 ||
        (newDoseCode === newDoseCode2 && newDoseCode2.length > 0)
    );

    if (
      newDoseCode2.length === 11 &&
      filteredDoseCode2.length > 0 &&
      newDoseCode !== newDoseCode2 &&
      (analysedUsers.filter((user) => user.doseCode === newDoseCode2).length === 0 ||
        analysedUsers.filter((user) => user.doseCode2 === newDoseCode2).length === 0) &&
      newDoseCode !== newDoseCode2 &&
      newDoseCode2.length > 0
    ) {
      setAssignDialog2Open(false);
      setRingNo2Dialog(true);
    }
  }, [newDoseCode2.length === 11]);

  const handleSubmitNanodot1 = () => {
    if (
      analysedUsers.filter((user) => user?.ringNo === ringNo).length === 1 ||
      analysedUsers.filter((user) => user?.ringNo2 === ringNo).length === 1
    ) {
      setDuplicationRingNoDialogOpen(true);
    } else {
      dispatch(
        analysed({
          name: filteredUser[0]?.name,
          year: newYear,
          month: newMonth,
          id: filteredUser[0]?.id,
          ic: filteredUser[0]?.icNum,
          bgHp007: (parseFloat(filteredDoseCode[0]?.["Deep Dose"]) * 100) / 100,
          doseCode: newDoseCode,
          ringNo,
          rawHp007: null,
          bgHp0072: null,
          doseCode2: null,
          ringNo2: null,
          rawHp0072: null,
        })
      );
      setNewDoseCode("");
      setRingNo("");
      setNewDoseCode2("");
      setRingNo2("");
      setNewIcNum("");
      setAssignDialog2Open(false);
      setRingNo2Dialog(false);
      setAssignDialogOpen(false);
      setRingNoDialog(false);
    }
  };

  const handleSubmitNanodot2 = () => {
    if (
      analysedUsers.filter((user) => user?.ringNo === ringNo2).length === 1 ||
      analysedUsers.filter((user) => user?.ringNo2 === ringNo2).length === 1 ||
      (ringNo === ringNo2 && ringNo2.length > 0)
    ) {
      setDuplicationRingNoDialogOpen(true);
    } else {
      dispatch(
        analysed({
          name: filteredUser[0]?.name,
          year: newYear,
          month: newMonth,
          id: filteredUser[0]?.id,
          ic: filteredUser[0]?.icNum,
          bgHp007: (parseFloat(filteredDoseCode[0]?.["Deep Dose"]) * 100) / 100,
          doseCode: newDoseCode,
          ringNo,
          rawHp007: null,
          bgHp0072: (parseFloat(filteredDoseCode2[0]?.["Deep Dose"]) * 100) / 100,
          doseCode2: newDoseCode2,
          ringNo2,
          rawHp0072: null,
        })
      );
      setNewDoseCode("");
      setNewDoseCode2("");
      setRingNo2("");
      setRingNo("");
      setNewIcNum("");
      setAssignDialog2Open(false);
      setRingNo2Dialog(false);
    }
  };

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Enter" && error === "" && ringNo.length !== 0 && useNanodot1Only) {
        // 👇️ call submit function here
        handleSubmitNanodot1();
      } else if (
        event.key === "Enter" &&
        error === "" &&
        ringNo.length !== 0 &&
        useNanodot1and2 &&
        (analysedUsers.filter((user) => user?.ringNo === ringNo).length === 1 ||
          analysedUsers.filter((user) => user?.ringNo2 === ringNo).length === 1)
      ) {
        setDuplicationRingNoDialogOpen(true);
      } else if (
        event.key === "Enter" &&
        error === "" &&
        ringNo.length !== 0 &&
        ringNo2.length === 0 &&
        useNanodot1and2 &&
        (analysedUsers.filter((user) => user?.ringNo === ringNo).length !== 1 ||
          analysedUsers.filter((user) => user?.ringNo2 === ringNo).length !== 1)
      ) {
        setAssignDialog2Open(true);
        setRingNoDialog(false);
      } else if (
        event.key === "Enter" &&
        error === "" &&
        ringNo.length !== 0 &&
        ringNo2.length !== 0 &&
        useNanodot1and2 &&
        (analysedUsers.filter((user) => user?.ringNo === ringNo2).length === 1 ||
          analysedUsers.filter((user) => user?.ringNo2 === ringNo2).length === 1 ||
          ringNo === ringNo2)
      ) {
        setDuplicationRingNoDialogOpen(true);
      } else if (
        event.key === "Enter" &&
        error === "" &&
        ringNo.length !== 0 &&
        ringNo2.length !== 0 &&
        useNanodot1and2 &&
        (analysedUsers.filter((user) => user?.ringNo === ringNo).length !== 1 ||
          analysedUsers.filter((user) => user?.ringNo2 === ringNo).length !== 1)
      ) {
        handleSubmitNanodot2();
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [error, ringNo, ringNo2, useNanodot1Only, useNanodot1and2]);

  useEffect(() => {
    if (indexOfUserWithIcNum > -1 && !users[indexOfUserWithIcNum]?.useNanodot1) {
      setNoNanodotDialog(true);
    }
  }, [newIcNum.length === 12]);

  const collArr = [
    {
      accessor: "delete",
      align: "center",
      width: "1%",
    },
    {
      Header: "name",
      accessor: "name",
      width: "45%",
      align: "left",
    },
    { Header: "ic number", accessor: "icNum", align: "center" },
    { Header: "ring no.", accessor: "ringNo", align: "center" },
    { Header: "dosimeter code", accessor: "badgeNo", align: "center" },
    { Header: "Background Hp0.07 (mSv)", accessor: "bgHp007", align: "center" },
    { Header: "2nd ring no.", accessor: "ringNo2", align: "center" },
    { Header: "2nd dosimeter code", accessor: "badgeNo2", align: "center" },
    { Header: "Background Hp0.07 (mSv)", accessor: "bgHp0072", align: "center" },
  ];

  const mappedRowsArr = analysedUsers.map((e) => ({
    delete: (
      <IconButton
        key={e.id}
        color="error"
        size="small"
        onClick={() => {
          dispatch(deleteAnalysed(e.id));
        }}
      >
        <DeleteIcon fontSize="small" />
      </IconButton>
    ),
    name: e.name,
    icNum: e.ic,
    ringNo: e.ringNo,
    badgeNo: e.doseCode,
    bgHp007: e.bgHp007.toFixed(3),
    ringNo2: e?.ringNo2 || "N/A",
    badgeNo2: e?.doseCode2 || "N/A",
    bgHp0072: e?.bgHp0072?.toFixed(3) || "N/A",
  }));

  const icDuplicateCheck = analysedUsers.filter((user) => user.ic === newIcNum);

  const submit = async (e) => {
    handleCircularOpen();
    e.preventDefault();
    const batch = writeBatch(db);
    for (let i = 0; i < analysedUsers.length; i += 1) {
      batch.update(doc(db, "users", analysedUsers[i]?.id), {
        // https://dev.to/milesbd/updating-nested-fields-in-firestore-with-dot-notation-53hp
        [`extDose.${newYear}.${newMonth}`]: {
          extDoseCode: analysedUsers[i]?.doseCode,
          extDoseCode2: analysedUsers[i]?.doseCode2,
          extRingNo: analysedUsers[i]?.ringNo,
          extRingNo2: analysedUsers[i]?.ringNo2,
          extBgHp007: analysedUsers[i]?.bgHp007,
          extBgHp0072: analysedUsers[i]?.bgHp0072,
          extHp007: null,
          extHp0072: null,
        },
      });
    }

    await batch.commit().then(() => {
      handleCircularClose();
      handleSnackbarOpen();
      setNewYear("");
      setNewMonth("");
      dispatch(resetAnalysed());
    });
  };

  const handleDateChange = (date) => {
    if (date) {
      const { $M, $y } = date;
      const monthMap = {
        0: "January",
        1: "February",
        2: "March",
        3: "April",
        4: "May",
        5: "June",
        6: "July",
        7: "August",
        8: "September",
        9: "October",
        10: "November",
        11: "December",
      };

      setNewMonth(monthMap[$M]);

      setNewYear($y);
    }
  };

  return (
    <DashboardLayout>
      <OnlineStatusSnackbar />

      <Snackbar
        key={vertical + horizontal}
        open={openSnackBar}
        autoHideDuration={6000}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: "100%" }}>
          Successfully synced to server
        </Alert>
      </Snackbar>

      <Dialog
        maxWidth="sm"
        fullWidth
        open={
          assignDialogOpen &&
          !duplicationDialogOpen &&
          newIcNum.length === 12 &&
          filteredUser.length === 1 &&
          icDuplicateCheck.length === 0
        }
      >
        <DialogTitle>
          Assigning {filteredUser[0]?.name} nanoDot™ dosimeter for {newMonth} {newYear}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please scan the dosimeter QR code or type in the code manually
          </DialogContentText>
          <MDInput
            autoFocus
            margin="dense"
            id="name"
            label="Dosimeter code"
            type="text"
            fullWidth
            variant="outlined"
            onChange={(e) => setNewDoseCode(e.target.value.toUpperCase())}
            onFocus={() => {
              setNewDoseCode("");
              setRingNo("");
              setNewDoseCode2("");
              setRingNo2("");
            }}
            inputProps={{ maxLength: 11 }}
            value={newDoseCode}
          />
        </DialogContent>
        <DialogActions>
          <MDButton variant="outlined" onClick={() => setAssignDialogOpen(false)} color="error">
            Cancel
          </MDButton>
        </DialogActions>
      </Dialog>

      <Dialog maxWidth="sm" fullWidth open={ringNoDialog}>
        <DialogTitle>
          Assigning {filteredUser[0]?.name} nanoDot™ ring number for {newMonth} {newYear}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please type in the nanoDot™ ring number manually and then press Enter on keyboard or
            click the Submit button
          </DialogContentText>
          <MDInput
            autoFocus
            margin="dense"
            id="ring-no"
            label="nanoDot™ ring number"
            type="text"
            fullWidth
            variant="outlined"
            onChange={handleRingNoChange}
            onFocus={() => {
              setRingNo("");
              setError("");
              setNewDoseCode2("");
              setRingNo2("");
            }}
            error={!!error}
            value={ringNo}
            helperText={error}
          />
        </DialogContent>
        <DialogActions>
          <MDButton variant="outlined" onClick={() => setRingNoDialog(false)} color="error">
            Cancel
          </MDButton>
          <MDButton
            disabled={error.length !== 0 || ringNo.length === 0}
            id="submit"
            variant="outlined"
            onClick={
              useNanodot1Only
                ? handleSubmitNanodot1
                : () => {
                    if (
                      error === "" &&
                      ringNo.length !== 0 &&
                      useNanodot1and2 &&
                      (analysedUsers.filter((user) => user?.ringNo === ringNo).length === 1 ||
                        analysedUsers.filter((user) => user?.ringNo2 === ringNo).length === 1)
                    ) {
                      setDuplicationRingNoDialogOpen(true);
                    } else {
                      setAssignDialog2Open(true);
                      setRingNoDialog(false);
                    }
                  }
            }
            color="success"
          >
            Submit
          </MDButton>
        </DialogActions>
      </Dialog>

      <Dialog maxWidth="sm" fullWidth open={assignDialog2Open && newDoseCode.length > 0}>
        <DialogTitle>
          Assigning {filteredUser[0]?.name} 2nd nanoDot™ dosimeter for {newMonth} {newYear}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please scan the dosimeter QR code or type in the code manually
          </DialogContentText>
          <MDInput
            autoFocus
            margin="dense"
            id="assign-dialog-2"
            label="Dosimeter code"
            type="text"
            fullWidth
            variant="outlined"
            onChange={(e) => setNewDoseCode2(e.target.value.toUpperCase())}
            onFocus={() => {
              setNewDoseCode2("");
              setRingNo2("");
            }}
            inputProps={{ maxLength: 11 }}
            value={newDoseCode2}
          />
        </DialogContent>
        <DialogActions>
          <MDButton variant="outlined" onClick={() => setAssignDialog2Open(false)} color="error">
            Cancel
          </MDButton>
        </DialogActions>
      </Dialog>

      <Dialog maxWidth="sm" fullWidth open={ringNo2Dialog}>
        <DialogTitle>
          Assigning {filteredUser[0]?.name} 2nd nanoDot™ ring number for {newMonth} {newYear}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please type in the 2nd nanoDot™ ring number manually and then press Enter on keyboard or
            click the Submit button
          </DialogContentText>
          <MDInput
            autoFocus
            margin="dense"
            id="ring-no"
            label="nanoDot™ ring number"
            type="text"
            fullWidth
            variant="outlined"
            onChange={handleRingNo2Change}
            onFocus={() => {
              setRingNo2("");
              setError("");
            }}
            error={!!error}
            value={ringNo2}
            helperText={error}
          />
        </DialogContent>
        <DialogActions>
          <MDButton variant="outlined" onClick={() => setRingNo2Dialog(false)} color="error">
            Cancel
          </MDButton>
          <MDButton
            disabled={error.length !== 0 || ringNo2.length === 0}
            id="submit"
            variant="outlined"
            onClick={handleSubmitNanodot2}
            color="success"
          >
            Submit
          </MDButton>
        </DialogActions>
      </Dialog>

      <Dialog
        open={
          openSwitchDialog &&
          newDoseCode.length === 11 &&
          filteredUser.length === 1 &&
          icDuplicateCheck.length === 0 &&
          filteredDoseCode.length === 0
        }
      >
        <>
          <DialogTitle>
            The code for the specified dosimeter was not found in the Excel file.{" "}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>Please switch to another dosimeter</DialogContentText>
          </DialogContent>
          <DialogActions>
            <MDButton variant="outlined" onClick={handleCloseSwitchDialog} color="error">
              Okay
            </MDButton>
          </DialogActions>
        </>
      </Dialog>

      <Dialog
        open={newIcNum.length === 12 && filteredUser.length === 1 && icDuplicateCheck.length === 1}
      >
        <DialogTitle>
          The user {icDuplicateCheck[0]?.name} has already been assigned with the dosimeter code{" "}
          {icDuplicateCheck[0]?.doseCode}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>Please proceed to scan the next user</DialogContentText>
        </DialogContent>
        <DialogActions>
          <MDButton variant="outlined" onClick={handleClose} color="error">
            Okay
          </MDButton>
        </DialogActions>
      </Dialog>

      <Dialog open={noNanodotDialog}>
        <DialogTitle>
          The user {users[indexOfUserWithIcNum]?.name} has not been assigned with nanoDot™ dosimeter
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please tick the use nanoDot™ dosimeter box in profile edit section
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <MDButton variant="outlined" onClick={() => setNoNanodotDialog(false)} color="error">
            Okay
          </MDButton>
        </DialogActions>
      </Dialog>

      <Dialog open={duplicationDialogOpen}>
        <DialogTitle>
          The dosimeter with code {newDoseCode2 || newDoseCode} has already been assigned to the
          user{" "}
          {analysedUsers.filter((user) => user.doseCode === newDoseCode2)[0]?.name ||
            analysedUsers.filter((user) => user.doseCode2 === newDoseCode2)[0]?.name ||
            analysedUsers.filter((user) => user.doseCode === newDoseCode)[0]?.name ||
            analysedUsers.filter((user) => user.doseCode2 === newDoseCode)[0]?.name ||
            filteredUser[0]?.name}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>Please avoid the duplication</DialogContentText>
        </DialogContent>
        <DialogActions>
          <MDButton
            variant="outlined"
            onClick={() => {
              setDuplicationDialogOpen(false);
            }}
            color="error"
          >
            Okay
          </MDButton>
        </DialogActions>
      </Dialog>

      <Dialog open={duplicationRingNoDialogOpen}>
        <DialogTitle>
          The ring number {ringNo.length > 0 && ringNo2.length > 0 ? ringNo2 : ringNo} has already
          been assigned
        </DialogTitle>
        <DialogContent>
          <DialogContentText>Please avoid the duplication</DialogContentText>
        </DialogContent>
        <DialogActions>
          <MDButton
            variant="outlined"
            onClick={() => {
              setDuplicationRingNoDialogOpen(false);
            }}
            color="error"
          >
            Okay
          </MDButton>
        </DialogActions>
      </Dialog>

      <Dialog open={newIcNum.length === 12 && users.findIndex((e) => e.icNum === newIcNum) === -1}>
        <DialogTitle>User not found</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please make sure the user with IC number {newIcNum} is registered
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <MDButton variant="outlined" onClick={() => setNewIcNum("")} color="error">
            Okay
          </MDButton>
        </DialogActions>
      </Dialog>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={circularOpen}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <DashboardNavbar />
      <MDBox py={3}>
        <Card>
          <Grid container spacing={0}>
            <Grid item xs={12} md={4} lg={4}>
              <MDBox pt={4} px={3} sx={{ pb: { xs: 0, sm: 0, md: 3, lg: 3 } }}>
                <MDBox>
                  <MDBox mb={2}>
                    <MDTypography variant="h6">
                      Please select month and year of assignment
                    </MDTypography>
                  </MDBox>
                  <MDBox mb={2}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Month and Year"
                        views={["month", "year"]}
                        onChange={handleDateChange}
                      />
                    </LocalizationProvider>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <MDBox
                px={3}
                sx={{ pb: { xs: 0, sm: 0, md: 3, lg: 3 }, pt: { xs: 0, sm: 0, md: 4, lg: 4 } }}
              >
                <MDBox>
                  {newMonth?.length > 0 && newYear.toString().length === 4 && (
                    <>
                      <MDBox mb={2}>
                        <MDTypography variant="h6">
                          Select file of .xls / .xlsx (background)
                        </MDTypography>
                      </MDBox>
                      <MDBox mb={2}>
                        <MDInput
                          id="bgFile"
                          type="file"
                          accept=".xls,.xlsx"
                          onChange={onChangeFileBackground}
                        />
                      </MDBox>
                    </>
                  )}
                </MDBox>
              </MDBox>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <MDBox
                px={3}
                sx={{ pb: { xs: 0, sm: 0, md: 3, lg: 3 }, pt: { xs: 0, sm: 0, md: 4, lg: 4 } }}
              >
                <MDBox>
                  {newMonth?.length > 0 && newYear.toString().length === 4 && arrBg.length > 0 && (
                    <>
                      <MDBox mb={2}>
                        <MDTypography variant="h6">
                          Please scan IC barcode or type in the IC number manually
                        </MDTypography>
                      </MDBox>
                      <MDBox mb={2}>
                        <MDInput
                          id="ic-num-input"
                          onFocus={() => {
                            if (
                              !assignDialogOpen &&
                              !noNanodotDialog &&
                              !ringNoDialog &&
                              !assignDialog2Open &&
                              !ringNo2Dialog
                            ) {
                              setNewIcNum("");
                            }
                          }}
                          onChange={(e) => setNewIcNum(e.target.value)}
                          inputProps={{ maxLength: 12 }}
                          style={{ width: "140px" }}
                          placeholder="Insert IC number..."
                          value={newIcNum}
                        />
                      </MDBox>
                    </>
                  )}
                </MDBox>
              </MDBox>
            </Grid>
          </Grid>
        </Card>
      </MDBox>

      {analysedUsers.length > 0 && newMonth?.length > 0 && newYear.toString().length === 4 && (
        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography variant="h6" color="white" mb={2}>
                    Dosimeter assignment for {newMonth} {newYear}
                  </MDTypography>
                  <MDButton variant="contained" color="success" onClick={submit}>
                    Sync to server ({analysedUsers.length} of{" "}
                    {users.filter((e) => e?.useNanodot1).length} active nanoDot™ users)
                  </MDButton>
                </MDBox>
                <MDBox pt={3}>
                  <DataTable
                    table={{ columns: collArr, rows: mappedRowsArr }}
                    entriesPerPage
                    isSorted
                    showTotalEntries
                    noEndBorder
                  />
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      )}
      <Footer />
    </DashboardLayout>
  );
}

export default Analysis;
