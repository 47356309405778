import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import { CircularProgress, Backdrop, Grid, Card } from "@mui/material";
import React, { useEffect, useState, useCallback, useMemo, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { doc, updateDoc, deleteDoc } from "firebase/firestore";
import { UserContext } from "context/UserContext";
import SuccessfulTransferProfileSnackBar from "components/SnackBar/SuccessfulTransferProfileSnackBar";
import authorsTableData from "./data/authorsTableData";
import DialogProfileView from "./components/DialogProfileView";
import DialogConfirmation from "./components/DialogConfirmation";
import DialogProfileEdit from "./components/DialogProfileEdit";
import OnlineStatusSnackbar from "../../onlineStatusSnackbar/onlineStatusSnackbar";
import { db } from "../../../firebase-config";
import { closeLocsDialogView } from "../../../slices/deleteSlice";

function TableComponent() {
  useEffect(() => {
    document.title = "Locations List - RadDB";
  }, []);

  const { columns, rows } = authorsTableData();

  return (
    <DataTable
      search={{ fields: ["searchable"] }}
      table={{ columns, rows }}
      entriesPerPage={{ defaultValue: 100 }}
      isSorted
      showTotalEntries
      noEndBorder
      canSearch
      isLocation
    />
  );
}

const TableComponentMemo = React.memo(TableComponent);

function LocationsTable() {
  const { locations } = useContext(UserContext);
  const dispatch = useDispatch();
  const { openLocsDialogView } = useSelector((state) => state.delete);
  const { id } = useSelector((state) => state.idDelete);
  const [showSuccessfulTransferProfileSnackBar, setShowSuccessfulTransferProfileSnackBar] =
    useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [errorDosID1, setErrorDosID1] = useState("");
  const [errorDosID2, setErrorDosID2] = useState("");

  const handleClickConfirmationOpen = () => {
    setOpenConfirmationDialog(true);
  };

  const handleCloseConfirmationDialog = () => {
    setOpenConfirmationDialog(false);
  };

  const [openDialogEdit, setOpenDialogEdit] = useState(false);

  const handleClickEditDialog = () => {
    setOpenDialogEdit(true);
  };

  const [backdropStatus, setBackdropStatus] = useState(false);

  const onClickBackDropStatus = () => {
    setBackdropStatus(true);
  };

  const closeBackDropStatus = () => {
    setBackdropStatus(false);
  };

  const height = { height: 44 };

  const specificLoc = locations.filter((e) => e.id === id);

  // https://www.youtube.com/watch?v=uVPtYLGPL80&t=405s&ab_channel=Logicism
  const handleDelete = useCallback(async (idFB) => {
    const docRef = doc(db, "locations", idFB);
    await deleteDoc(docRef);
  }, []);

  const initialStates = {
    newLocName: specificLoc[0]?.locationName,
    newDosID1: specificLoc[0]?.dosimeterID1,
    newDosID2: specificLoc[0]?.dosimeterID2,
    newLocID1: specificLoc[0]?.locationID1,
    newLocID2: specificLoc[0]?.locationID2,
  };

  const immutableState = { ...initialStates };
  const memoedInitialStates = useMemo(() => immutableState, [specificLoc[0]?.locationName]);
  const [state, setState] = useState(memoedInitialStates);

  const onDosimeterID1Change = (e) => {
    const newValue = e.target.value;

    if (newValue.length < 11 && newValue.length > 0) {
      setErrorDosID1("Dosimeter ID should be 11 characters");
      setState({ ...state, newDosID1: newValue });
    } else if (
      locations.filter(
        (f) =>
          f?.locationID1 !== state.newLocID1 &&
          f.dosimeterID1?.toLowerCase() === newValue?.toLowerCase()
      ).length > 0 ||
      locations.filter(
        (f) =>
          f?.locationID1 !== state.newLocID1 &&
          f.dosimeterID2?.toLowerCase() === newValue?.toLowerCase()
      ).length > 0
    ) {
      setErrorDosID1("Dosimeter ID already existed in database");
      setState({ ...state, newDosID1: newValue });
    } else if (
      (state.dosimeterID2?.toLowerCase() === newValue?.toLowerCase() && newValue.length > 0) ||
      locations.filter(
        (f) =>
          f?.locationID1 === state.newLocID1 &&
          f?.dosimeterID2?.toLowerCase() === newValue?.toLowerCase()
      ).length > 0
    ) {
      setErrorDosID1("Dosimeter ID already assigned to Dosimeter ID 2");
      setState({ ...state, newDosID1: newValue });
    } else if (
      locations.filter((f) => f.dosimeterID1?.toLowerCase() === state?.dosimeterID1?.toLowerCase())
        .length === 0 &&
      locations.filter((f) => f.dosimeterID1?.toLowerCase() === state?.dosimeterID2?.toLowerCase())
        .length === 0 &&
      locations.filter((f) => f.dosimeterID2?.toLowerCase() === state?.dosimeterID2?.toLowerCase())
        .length === 0 &&
      locations.filter((f) => f.dosimeterID2?.toLowerCase() === state?.dosimeterID1?.toLowerCase())
        .length === 0 &&
      state?.dosimeterID2?.toLowerCase() !== newValue.toLowerCase()
    ) {
      setErrorDosID1("");
      setErrorDosID2("");
      setState({ ...state, newDosID1: newValue });
    } else {
      setErrorDosID1("");
      setState({ ...state, newDosID1: newValue });
    }
  };

  const onDosimeterID2Change = (e) => {
    const newValue = e.target.value;

    if (newValue.length < 11 && newValue.length > 0) {
      setErrorDosID2("Dosimeter ID should be 11 characters");
      setState({ ...state, newDosID2: newValue });
    } else if (
      locations.filter(
        (f) =>
          f?.locationID2 !== state.newLocID2 &&
          f?.dosimeterID1?.toLowerCase() === newValue?.toLowerCase()
      ).length > 0 ||
      locations.filter(
        (f) =>
          f?.locationID2 !== state.newLocID2 &&
          f?.dosimeterID2?.toLowerCase() === newValue?.toLowerCase()
      ).length > 0
    ) {
      setErrorDosID2("Dosimeter ID already existed in database");
      setState({ ...state, newDosID2: newValue });
    } else if (
      (state?.dosimeterID1?.toLowerCase() === newValue?.toLowerCase() && newValue.length > 0) ||
      locations.filter(
        (f) =>
          f?.locationID2 === state.newLocID2 &&
          f?.dosimeterID1?.toLowerCase() === newValue?.toLowerCase()
      ).length > 0
    ) {
      setErrorDosID2("Dosimeter ID already assigned to Dosimeter ID 1");
      setState({ ...state, newDosID2: newValue });
    } else if (
      locations.filter((f) => f?.dosimeterID1?.toLowerCase() === state?.dosimeterID1?.toLowerCase())
        .length === 0 &&
      locations.filter((f) => f?.dosimeterID1?.toLowerCase() === state?.dosimeterID2?.toLowerCase())
        .length === 0 &&
      locations.filter((f) => f?.dosimeterID2?.toLowerCase() === state?.dosimeterID2?.toLowerCase())
        .length === 0 &&
      locations.filter((f) => f?.dosimeterID2?.toLowerCase() === state?.dosimeterID1?.toLowerCase())
        .length === 0 &&
      state?.dosimeterID2?.toLowerCase() !== newValue.toLowerCase()
    ) {
      setErrorDosID1("");
      setErrorDosID2("");
      setState({ ...state, newDosID2: newValue });
    } else {
      setErrorDosID2("");
      setState({ ...state, newDosID2: newValue });
    }
  };

  const handleCloseEditDialog = () => {
    setState(memoedInitialStates);
    setOpenDialogEdit(false);
    setErrorDosID1("");
    setErrorDosID2("");
  };

  useEffect(() => {
    setState(immutableState);
  }, [specificLoc[0]?.id, openDialogEdit, openLocsDialogView, locations]);

  const submit = async (e) => {
    e.preventDefault();
    onClickBackDropStatus();
    await updateDoc(doc(db, "locations", id), {
      locationName: state.newLocName,
      dosimeterID1: state.newDosID1,
      dosimeterID2: state.newDosID2,
    }).then(() => {
      handleCloseEditDialog();
      closeBackDropStatus();
    });
  };

  return (
    <DashboardLayout>
      <OnlineStatusSnackbar />

      <SuccessfulTransferProfileSnackBar
        showSuccessfulTransferProfileSnackBar={showSuccessfulTransferProfileSnackBar}
        onClose={() => setShowSuccessfulTransferProfileSnackBar(false)}
      />

      <DialogProfileView
        openDialogView={openLocsDialogView && specificLoc.length !== 0}
        handleClickEditDialog={handleClickEditDialog}
        handleDelete={handleDelete}
        dispatchCloseDialogView={() => {
          dispatch(closeLocsDialogView());
        }}
        locationName={specificLoc[0]?.locationName}
        locationID1={specificLoc[0]?.locationID1}
        dosimeterID1={specificLoc[0]?.dosimeterID1}
        locationID2={specificLoc[0]?.locationID2}
        dosimeterID2={specificLoc[0]?.dosimeterID2}
        id={specificLoc[0]?.id}
      />

      <DialogConfirmation
        openConfirmationDialog={openConfirmationDialog && specificLoc.length !== 0}
        handleCloseConfirmationDialog={handleCloseConfirmationDialog}
        dispatchCloseDialogView={() => {
          handleCloseConfirmationDialog();
          dispatch(closeLocsDialogView());
          handleCloseEditDialog();
          handleDelete(id);
        }}
      />

      <DialogProfileEdit
        openDialogEdit={openDialogEdit && specificLoc.length !== 0}
        submit={submit}
        height={height}
        handleClickConfirmationOpen={handleClickConfirmationOpen}
        handleCloseEditDialog={() => handleCloseEditDialog()}
        handleDosimeterID1Change={onDosimeterID1Change}
        handleDosimeterID2Change={onDosimeterID2Change}
        errorDosID1={errorDosID1}
        errorDosID2={errorDosID2}
        locationName={state?.newLocName}
        locationID1={state?.newLocID1}
        dosimeterID1={state?.newDosID1}
        locationID2={state?.newLocID2}
        dosimeterID2={state?.newDosID2}
        handleLocationNameChange={(e) => setState({ ...state, newLocName: e.target.value })}
      />

      <DashboardNavbar />

      <MDBox pt={6} pb={3} position="relative">
        <Backdrop
          sx={{
            borderRadius: 5,
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
            position: "absolute",

            width: "100%",
            height: "100%",
          }}
          open={!locations.length}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 9999,
          }}
          open={backdropStatus}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card sx={{ marginTop: 8 }}>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Locations List
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <TableComponentMemo />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default LocationsTable;
