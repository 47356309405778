/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts
import Dashboard from "layouts/dashboard";
import Tables from "layouts/tables";
// import Billing from "layouts/billing";
// import Notifications from "layouts/notifications";
// import Profile from "layouts/profile";
// import SignUp from "layouts/authentication/sign-up";

// @mui icons
import Icon from "@mui/material/Icon";
import Register from "layouts/register";
import EnvRegister from "layouts/environmental-dosimeter/register";
import ProtectedRoute from "ProtectedRoute";
import PersonnelPreAnalysis from "layouts/personnel-dosimeter/pre-analysis";
import PersonnelPostAnalysis from "layouts/personnel-dosimeter/post-analysis";
import PersonnelAnalysisResults from "layouts/personnel-dosimeter/analysis-results";
import ExtremityAnalysis from "layouts/extremity-dosimeter/pre-analysis";
import ExtremityPostAnalysis from "layouts/extremity-dosimeter/post-analysis";
import ExtremityAnalysisResults from "layouts/extremity-dosimeter/analysis-results";
import LocationsTable from "layouts/environmental-dosimeter/tables";
import LocationsAnalysis from "layouts/environmental-dosimeter/locations-analysis";
import LocationsAnalysisResults from "layouts/environmental-dosimeter/analysis-results";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/",
    component: (
      <ProtectedRoute>
        <Dashboard />
      </ProtectedRoute>
    ),
  },
  {
    type: "divider",
    key: "div0",
  },
  {
    type: "accordion",
    name: "Test",
    title: "Radiation Workers",
    key: "test",
    panel: "radiation-workers",
    anak: [
      {
        name: "Profiles List",
        icon: <Icon fontSize="small">table_view</Icon>,
        key: "radiation-workers/profiles-list",
        route: "/radiation-workers/profiles-list",
        component: (
          <ProtectedRoute>
            <Tables />
          </ProtectedRoute>
        ),
      },
      {
        name: "Register",
        icon: <Icon fontSize="small">receipt_long</Icon>,
        key: "radiation-workers/register",
        route: "/radiation-workers/register",
        component: (
          <ProtectedRoute>
            <Register />
          </ProtectedRoute>
        ),
      },
    ],
  },
  {
    type: "divider",
    key: "div1",
  },
  // {
  //   type: "collapse",
  //   name: "Radiation Workers",
  //   key: "radiation-workers",
  //   icon: <Icon fontSize="small">table_view</Icon>,
  //   route: "/radiation-workers",
  //   component: (
  //     <ProtectedRoute>
  //       <Tables />
  //     </ProtectedRoute>
  //   ),
  // },
  // {
  //   type: "collapse",
  //   name: "Register",
  //   key: "register",
  //   icon: <Icon fontSize="small">receipt_long</Icon>,
  //   route: "/register",
  //   component: (
  //     <ProtectedRoute>
  //       <Register />
  //     </ProtectedRoute>
  //   ),
  // },
  {
    type: "accordion",
    name: "Test",
    title: "Personnel Dosimeter",
    key: "test",
    panel: "personnel-dosimeter",
    anak: [
      {
        name: "Dosimeter Assignment",
        key: "personnel-dosimeter/dosimeter-assignment",
        icon: <Icon fontSize="small">assignment</Icon>,
        route: "/personnel-dosimeter/dosimeter-assignment",
        component: (
          <ProtectedRoute>
            <PersonnelPreAnalysis />
          </ProtectedRoute>
        ),
      },
      {
        name: "Dosimeter Analysis",
        key: "personnel-dosimeter/dosimeter-analysis",
        icon: <Icon fontSize="small">biotech</Icon>,
        route: "/personnel-dosimeter/dosimeter-analysis",
        component: (
          <ProtectedRoute>
            <PersonnelPostAnalysis />
          </ProtectedRoute>
        ),
      },
      {
        name: "Analysis Results",
        key: "personnel-dosimeter/analysis-results",
        icon: <Icon fontSize="small">format_list_numbered</Icon>,
        route: "/personnel-dosimeter/analysis-results",
        component: (
          <ProtectedRoute>
            <PersonnelAnalysisResults />
          </ProtectedRoute>
        ),
      },
    ],
  },
  {
    type: "divider",
    key: "div2",
  },
  {
    type: "accordion",
    name: "Test",
    title: "Extremities Dosimeter",
    key: "test",
    panel: "extremity-dosimeter",
    anak: [
      {
        name: "Dosimeter Assignment",
        key: "extremities-dosimeter/dosimeter-assignment",
        icon: <Icon fontSize="small">assignment</Icon>,
        route: "/extremities-dosimeter/dosimeter-assignment",
        component: (
          <ProtectedRoute>
            <ExtremityAnalysis />
          </ProtectedRoute>
        ),
      },
      {
        name: "Dosimeter Analysis",
        key: "extremities-dosimeter/dosimeter-analysis",
        icon: <Icon fontSize="small">biotech</Icon>,
        route: "/extremities-dosimeter/dosimeter-analysis",
        component: (
          <ProtectedRoute>
            <ExtremityPostAnalysis />
          </ProtectedRoute>
        ),
      },
      {
        name: "Analysis Results",
        key: "extremities-dosimeter/analysis-results",
        icon: <Icon fontSize="small">format_list_numbered</Icon>,
        route: "/extremities-dosimeter/analysis-results",
        component: (
          <ProtectedRoute>
            <ExtremityAnalysisResults />
          </ProtectedRoute>
        ),
      },
    ],
  },
  {
    type: "divider",
    key: "div3",
  },
  {
    type: "accordion",
    name: "Test",
    title: "Environmental Dosimeter",
    key: "test",
    panel: "environmental-dosimeter",
    anak: [
      {
        name: "Locations Assignment",
        key: "environmental-dosimeter/locations-assignment",
        icon: <Icon fontSize="small">add_location</Icon>,
        route: "/environmental-dosimeter/locations-assignment",
        component: (
          <ProtectedRoute>
            <EnvRegister />
          </ProtectedRoute>
        ),
      },
      {
        name: "Locations List",
        key: "environmental-dosimeter/locations-list",
        icon: <Icon fontSize="small">assignment</Icon>,
        route: "/environmental-dosimeter/locations-list",
        component: (
          <ProtectedRoute>
            <LocationsTable />
          </ProtectedRoute>
        ),
      },
      {
        name: "Dosimeter Analysis",
        key: "environmental-dosimeter/dosimeter-analysis",
        icon: <Icon fontSize="small">biotech</Icon>,
        route: "/environmental-dosimeter/dosimeter-analysis",
        component: (
          <ProtectedRoute>
            <LocationsAnalysis />
          </ProtectedRoute>
        ),
      },
      {
        name: "Analysis Results",
        key: "environmental-dosimeter/analysis-results",
        icon: <Icon fontSize="small">format_list_numbered</Icon>,
        route: "/environmental-dosimeter/analysis-results",
        component: (
          <ProtectedRoute>
            <LocationsAnalysisResults />
          </ProtectedRoute>
        ),
      },
    ],
  },

  // {
  //   type: "collapse",
  //   name: "Radioactive Purchase",
  //   key: "billing",
  //   icon: <Icon fontSize="small">attach_money</Icon>,
  //   route: "/billing",
  //   component: (
  //     <ProtectedRoute>
  //       <Billing />
  //     </ProtectedRoute>
  //   ),
  // },
  /*
  {
    type: "collapse",
    name: "Notifications",
    key: "notifications",
    icon: <Icon fontSize="small">notifications</Icon>,
    route: "/notifications",
    component: <Notifications />,
  },
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/profile",
    component: <Profile />,
  },
  {
    type: "collapse",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
  },
  {
    type: "collapse",
    name: "Sign Up",
    key: "sign-up",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/authentication/sign-up",
    component: <SignUp />,
  },
  */
];

export default routes;
