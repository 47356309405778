import { useContext } from "react";
import { UserContext } from "context/UserContext";

export default function professionsFunction() {
  const { users } = useContext(UserContext);

  const jobs = [...new Set(users.map((user) => user?.job))].sort();

  const data = jobs.map((job) => users.filter((user) => user?.job === job && user?.active).length);

  const professions = {
    labels: jobs,
    datasets: {
      data,
      backgroundColors: [
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "error",
        "light",
        "dark",
        "nine",
      ],
      cutout: "70%",
    },
  };

  return professions;
}
