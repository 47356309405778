import { useContext } from "react";
import { UserContext } from "context/UserContext";
import colors from "assets/theme/base/colors";

export default function reportsBarChartData(year) {
  const { users } = useContext(UserContext);

  // const year = new Date().getFullYear();
  // const year = 2023;
  const jan = users.filter((e) => e[year]?.January?.hp10 || e[year]?.January?.hp10 === 0).length;
  const feb = users.filter((e) => e[year]?.February?.hp10 || e[year]?.February?.hp10 === 0).length;
  const mac = users.filter((e) => e[year]?.March?.hp10 || e[year]?.March?.hp10 === 0).length;
  const apr = users.filter((e) => e[year]?.April?.hp10 || e[year]?.April?.hp10 === 0).length;
  const may = users.filter((e) => e[year]?.May?.hp10 || e[year]?.May?.hp10 === 0).length;
  const jun = users.filter((e) => e[year]?.June?.hp10 || e[year]?.June?.hp10 === 0).length;
  const jul = users.filter((e) => e[year]?.July?.hp10 || e[year]?.July?.hp10 === 0).length;
  const aug = users.filter((e) => e[year]?.August?.hp10 || e[year]?.August?.hp10 === 0).length;
  const sep = users.filter(
    (e) => e[year]?.September?.hp10 || e[year]?.September?.hp10 === 0
  ).length;
  const oct = users.filter((e) => e[year]?.October?.hp10 || e[year]?.October?.hp10 === 0).length;
  const nov = users.filter((e) => e[year]?.November?.hp10 || e[year]?.November?.hp10 === 0).length;
  const dec = users.filter((e) => e[year]?.December?.hp10 || e[year]?.December?.hp10 === 0).length;

  const janC = users.filter((e) => e[year]?.January?.doseCode).length;
  const febC = users.filter((e) => e[year]?.February?.doseCode).length;
  const macC = users.filter((e) => e[year]?.March?.doseCode).length;
  const aprC = users.filter((e) => e[year]?.April?.doseCode).length;
  const mayC = users.filter((e) => e[year]?.May?.doseCode).length;
  const junC = users.filter((e) => e[year]?.June?.doseCode).length;
  const julC = users.filter((e) => e[year]?.July?.doseCode).length;
  const augC = users.filter((e) => e[year]?.August?.doseCode).length;
  const sepC = users.filter((e) => e[year]?.September?.doseCode).length;
  const octC = users.filter((e) => e[year]?.October?.doseCode).length;
  const novC = users.filter((e) => e[year]?.November?.doseCode).length;
  const decC = users.filter((e) => e[year]?.December?.doseCode).length;

  const chartData = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    datasets: [
      {
        label: "Assigned dosimeters",
        data: [janC, febC, macC, aprC, mayC, junC, julC, augC, sepC, octC, novC, decC],
        backgroundColor: colors.success.main, // original: "rgba(255, 255, 255, 0.8)"
      },
      {
        label: "Analysed dosimeters",
        data: [jan, feb, mac, apr, may, jun, jul, aug, sep, oct, nov, dec],
        backgroundColor: colors.dark.main,
      },
    ],
  };

  return chartData;
}
