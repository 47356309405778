import * as XLSX from "xlsx";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

// @mui material components
import {
  Grid,
  Card,
  // FormControl,
  // InputLabel,
  // Select,
  // MenuItem,
  Backdrop,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";

// import DeleteIcon from "@mui/icons-material/DeleteForever";

import DataTable from "examples/Tables/DataTable";

import * as React from "react";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
// import DataTable from "examples/Tables/DataTable";

import { useEffect, useState, useCallback, useContext } from "react";
import { doc, writeBatch } from "firebase/firestore";
import { UserContext } from "context/UserContext";

import MDInput from "components/MDInput";

import { db } from "../../../firebase-config";
import OnlineStatusSnackbar from "../../onlineStatusSnackbar/onlineStatusSnackbar";

function PostAnalysis() {
  useEffect(() => {
    document.title = "Dosimeter Analysis - RadDB";
  }, []);
  const { users } = useContext(UserContext);
  const [arr, setArr] = useState([]);
  const [newDeepDose, setNewDeepDose] = useState([]);
  const [newShallowDose, setNewShallowDose] = useState([]);
  const [circularOpen, setCircularOpen] = useState(false);

  const onChangeFileActual = useCallback(
    (e) => {
      const [file] = e.target.files;
      const reader = new FileReader();

      reader.onload = (evt) => {
        const jsonObj = [];
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, { type: "binary" });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const dataXLSX = XLSX.utils.sheet_to_csv(ws, { header: 1 }).split("\n");
        // setArr(dataXLSX);

        // Source: https://stackoverflow.com/questions/59016562/parse-csv-records-in-to-an-array-of-objects-in-javascript
        const headers = dataXLSX[0].split(",");
        for (let i = 1; i < dataXLSX.length; i += 1) {
          const dutu = dataXLSX[i].split(",");
          const obj = {};
          for (let j = 0; j < dutu.length; j += 1) {
            obj[headers[j].trim()] = dutu[j].trim();
          }

          jsonObj.push(obj);
        }

        const mapped = jsonObj.filter((element) => element["Serial Number"] !== "");

        setArr(mapped);
      };

      reader.readAsBinaryString(file);
    },
    [arr]
  );

  const [newYear, setNewYear] = useState("");

  // const setNewYearFunc = useCallback(
  //   (e) => {
  //     setNewYear(e.target.value);
  //   },
  //   [newYear]
  // );

  const [newMonth, setNewMonth] = useState("");

  // const setNewMonthFunc = useCallback(
  //   (e) => {
  //     setNewMonth(e.target.value);
  //   },
  //   [newMonth]
  // );

  const [snackbarOpen, setSnackbarOpen] = useState({
    openSnackBar: false,
    vertical: "top",
    horizontal: "center",
  });

  const [snackbarSuccessOpen, setSnackbarSuccessOpen] = useState({
    openSnackBarSuccess: false,
    vertical: "top",
    horizontal: "center",
  });

  const [newHp10ControlReading, setNewHp10ControlReading] = useState("");

  const setNewHp10ControlReadingFunc = useCallback(
    (e) => {
      setNewHp10ControlReading(e.target.value);
    },
    [newHp10ControlReading]
  );

  const [newHp007ControlReading, setNewHp007ControlReading] = useState("");

  const setNewHp007ControlReadingFunc = useCallback(
    (e) => {
      setNewHp007ControlReading(e.target.value);
    },
    [newHp007ControlReading]
  );

  const { vertical, horizontal, openSnackBar } = snackbarOpen;

  const { verticalSuccess, horizontalSuccess, openSnackBarSuccess } = snackbarSuccessOpen;

  const filteredArr = users.filter((user) => user[newYear]?.[newMonth]?.doseCode);

  useEffect(() => {
    if (document.getElementById("deepDoseInput")) {
      document.getElementById("deepDoseInput").focus();
    }
  }, [filteredArr.length > 0]);

  useEffect(() => {
    if (document.getElementById("shallowDoseInput")) {
      document.getElementById("shallowDoseInput").focus();
    }
  }, [newHp10ControlReading.length === 4]);

  useEffect(() => {
    if (document.getElementById("actualFile")) {
      document.getElementById("actualFile").focus();
    }
  }, [newHp007ControlReading.length === 4]);

  useEffect(() => {
    for (let i = 0; i < filteredArr.length; i += 1) {
      for (let j = 0; j < arr.length; j += 1) {
        if (arr[j]["Serial Number"] === filteredArr[i][newYear][newMonth].doseCode) {
          const x = arr[j]["Deep Dose"];
          filteredArr[i][newYear][newMonth].hp10 = parseFloat(x).toFixed(2);
          setNewDeepDose((current) => [...current, filteredArr[i][newYear][newMonth].hp10]);
        }
      }
    }

    for (let i = 0; i < filteredArr.length; i += 1) {
      for (let j = 0; j < arr.length; j += 1) {
        if (arr[j]["Serial Number"] === filteredArr[i][newYear][newMonth].doseCode) {
          const x = arr[j]["Shallow Dose"];
          filteredArr[i][newYear][newMonth].hp007 = parseFloat(x).toFixed(2);
          setNewShallowDose((current) => [...current, filteredArr[i][newYear][newMonth].hp007]);
        }
      }
    }
  }, [arr]);

  const row = [...filteredArr];

  const handleSnackbarOpen = () => {
    setSnackbarOpen({
      openSnackBar: true,
      vertical: "top",
      horizontal: "center",
    });
  };

  const handleSnackbarClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen({
      ...snackbarOpen,
      openSnackBar: false,
    });
  };

  const handleSnackbarSuccessOpen = () => {
    setSnackbarSuccessOpen({
      openSnackBarSuccess: true,
      verticalSuccess: "top",
      horizontalSuccess: "center",
    });
  };

  const handleSnackbarSuccessClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarSuccessOpen({
      ...snackbarSuccessOpen,
      openSnackBarSuccess: false,
    });
  };

  const collArr = [
    {
      Header: "name",
      accessor: "name",
      width: "45%",
      align: "left",
    },
    { Header: "ic number", accessor: "icNum", align: "center" },
    { Header: "dosimeter code", accessor: "badgeNo", align: "center" },
    { Header: "Background Hp10 (mSv)", accessor: "bgHp10", align: "center" },
    { Header: "Background Hp0.07 (mSv)", accessor: "bgHp007", align: "center" },
  ];

  const mappedRowsArr = filteredArr.map((e) => ({
    name: e.name,
    icNum: e.icNum,
    badgeNo: e[newYear][newMonth].doseCode,
    bgHp10: e[newYear][newMonth].bgHp10,
    bgHp007: e[newYear][newMonth].bgHp007,
  }));

  const collArrWithCtrlRdgAndActualBg = [
    {
      Header: "name",
      accessor: "name",
      width: "35%",
      align: "left",
    },
    { Header: "ic number", accessor: "icNum", align: "center" },
    { Header: "dosimeter code", accessor: "badgeNo", align: "center" },
    { Header: "Actual Hp10 (mSv)", accessor: "actualHp10", align: "center" },
    { Header: "Actual Hp0.07 (mSv)", accessor: "actualHp007", align: "center" },
    { Header: "Background Hp10 (mSv)", accessor: "bgHp10", align: "center" },
    { Header: "Background Hp0.07 (mSv)", accessor: "bgHp007", align: "center" },
    { Header: "Hp10 (mSv)", accessor: "hp10", align: "center" },
    { Header: "Hp0.07 (mSv)", accessor: "hp007", align: "center" },
  ];

  const mappedRowsArrWithCtrlRdgAndActualBg = row.map((e) => ({
    name: e.name,
    icNum: e.icNum,
    badgeNo: e[newYear][newMonth].doseCode,
    actualHp10: e[newYear][newMonth].hp10,
    actualHp007: e[newYear][newMonth].hp007,
    bgHp10: e[newYear][newMonth].bgHp10,
    bgHp007: e[newYear][newMonth].bgHp007,
    hp10:
      Math.round(
        parseFloat(e[newYear][newMonth].hp10) * 100 -
          newHp10ControlReading * 100 -
          parseFloat(e[newYear][newMonth].bgHp10) * 100
      ) /
        100 <
      0.05
        ? 0
        : (
            Math.round(
              parseFloat(e[newYear][newMonth].hp10) * 100 -
                newHp10ControlReading * 100 -
                parseFloat(e[newYear][newMonth].bgHp10) * 100
            ) / 100
          ).toFixed(2),
    hp007:
      Math.round(
        parseFloat(e[newYear][newMonth].hp007) * 100 -
          newHp007ControlReading * 100 -
          parseFloat(e[newYear][newMonth].bgHp007) * 100
      ) /
        100 <
      0.05
        ? 0
        : (
            Math.round(
              parseFloat(e[newYear][newMonth].hp007) * 100 -
                newHp007ControlReading * 100 -
                parseFloat(e[newYear][newMonth].bgHp007) * 100
            ) / 100
          ).toFixed(2),
  }));

  // const resetInputYear = (e) => {
  //   e.target.value = "";
  //   setNewYear("");
  //   setNewMonth("");
  //   setNewHp007ControlReading("");
  //   setNewHp10ControlReading("");
  //   setNewDeepDose([]);
  // };

  const resetHp10InputControlReading = (e) => {
    e.target.value = "";
    setNewHp10ControlReading("");
  };

  const resetHp007InputControlReading = (e) => {
    e.target.value = "";
    setNewHp007ControlReading("");
  };

  useEffect(() => {
    if (document.getElementById("month-select")) {
      document.getElementById("month-select").focus();
    }
  }, [newYear.length === 4]);

  useEffect(() => {
    if (filteredArr.length === 0 && newMonth?.length !== 0) {
      handleSnackbarOpen();
    }

    if (filteredArr.length > 0 && newMonth?.length !== 0) {
      handleSnackbarClose();
    }
  }, [filteredArr.length > 0, newMonth]);

  const handleCircularOpen = () => {
    setCircularOpen(true);
  };

  const handleCircularClose = () => {
    setCircularOpen(false);
  };

  const submit = async (e) => {
    handleCircularOpen();
    e.preventDefault();
    const batch = writeBatch(db);
    for (let i = 0; i < row.length; i += 1) {
      batch.update(doc(db, "users", row[i]?.id), {
        // https://dev.to/milesbd/updating-nested-fields-in-firestore-with-dot-notation-53hp
        [`${newYear}.${newMonth}`]: {
          doseCode: row[i][newYear][newMonth].doseCode,
          rawHp10: parseFloat(row[i][newYear][newMonth].hp10),
          rawHp007: parseFloat(row[i][newYear][newMonth].hp007),
          bgHp10: parseFloat(row[i][newYear][newMonth].bgHp10),
          bgHp007: parseFloat(row[i][newYear][newMonth].bgHp007),
          ctrlValueHp10: parseFloat(newHp10ControlReading),
          ctrlValueHp007: parseFloat(newHp007ControlReading),
          hp10:
            Math.round(
              parseFloat(row[i][newYear][newMonth].hp10) * 100 -
                newHp10ControlReading * 100 -
                parseFloat(row[i][newYear][newMonth].bgHp10) * 100
            ) /
              100 <
            0.05
              ? 0
              : parseFloat(
                  Math.round(
                    parseFloat(row[i][newYear][newMonth].hp10) * 100 -
                      newHp10ControlReading * 100 -
                      parseFloat(row[i][newYear][newMonth].bgHp10) * 100
                  ) / 100
                ),
          hp007:
            Math.round(
              parseFloat(row[i][newYear][newMonth].hp007) * 100 -
                newHp007ControlReading * 100 -
                parseFloat(row[i][newYear][newMonth].bgHp007) * 100
            ) /
              100 <
            0.05
              ? 0
              : parseFloat(
                  Math.round(
                    parseFloat(row[i][newYear][newMonth].hp007) * 100 -
                      newHp007ControlReading * 100 -
                      parseFloat(row[i][newYear][newMonth].bgHp007) * 100
                  ) / 100
                ),
        },
      });
    }

    await batch.commit().then(() => {
      handleCircularClose();
      handleSnackbarSuccessOpen();
      setNewYear("");
      setNewMonth("");
    });
  };

  const handleDateChange = (date) => {
    if (date) {
      const { $M, $y } = date;
      const monthMap = {
        0: "January",
        1: "February",
        2: "March",
        3: "April",
        4: "May",
        5: "June",
        6: "July",
        7: "August",
        8: "September",
        9: "October",
        10: "November",
        11: "December",
      };

      setNewMonth(monthMap[$M]);

      setNewYear($y);
    }
  };

  return (
    <DashboardLayout>
      <OnlineStatusSnackbar />
      {filteredArr.length === 0 && newMonth?.length !== 0 && newYear.toString().length === 4 && (
        <Snackbar
          key={vertical + horizontal}
          open={openSnackBar}
          autoHideDuration={6000}
          anchorOrigin={{ vertical, horizontal }}
        >
          <Alert severity="error" onClose={handleSnackbarClose} sx={{ width: "100%" }}>
            No users assigned for {newMonth} {newYear}. Please go to Dosimeter Assignment tab to
            assign dosimeters.
          </Alert>
        </Snackbar>
      )}

      <Snackbar
        key={verticalSuccess + horizontalSuccess}
        open={openSnackBarSuccess}
        autoHideDuration={6000}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert severity="success" onClose={handleSnackbarSuccessClose} sx={{ width: "100%" }}>
          Successfully synced to server
        </Alert>
      </Snackbar>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={circularOpen}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <DashboardNavbar />

      <MDBox py={3}>
        <Card>
          <Grid container spacing={0}>
            <Grid item xs={12} md={4} lg={4}>
              <MDBox pt={4} px={3} sx={{ pb: { xs: 0, sm: 0, md: 3, lg: 3 } }}>
                <MDBox>
                  <MDBox mb={2}>
                    <MDTypography variant="h6">
                      Please select month and year of analysis
                    </MDTypography>
                  </MDBox>
                  <MDBox mb={2}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Month and Year"
                        views={["month", "year"]}
                        onChange={handleDateChange}
                      />
                    </LocalizationProvider>
                  </MDBox>
                </MDBox>
                {/* <MDBox>
                  <MDBox mb={2}>
                    <MDTypography variant="h6">Year of analysis : {newYear}</MDTypography>
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      autoFocus
                      onFocus={resetInputYear}
                      onChange={setNewYearFunc}
                      inputProps={{ maxLength: 4 }}
                      style={{ width: "100px" }}
                      placeholder="Insert year..."
                    />
                  </MDBox>
                  {newYear.length === 4 && (
                    <>
                      <MDBox mb={2}>
                        <MDTypography variant="h6">Month of analysis : {newMonth}</MDTypography>
                      </MDBox>
                      <MDBox mb={2}>
                        <FormControl required style={{ width: "100px" }}>
                          <InputLabel id="status-select-label">Month</InputLabel>
                          <Select
                            labelId="month-select-label"
                            id="month-select"
                            value={newMonth}
                            label="Month"
                            onChange={setNewMonthFunc}
                            style={{ height: 44 }}
                          >
                            <MenuItem value="">
                              <em>Please Select</em>
                            </MenuItem>
                            <MenuItem value="January">January</MenuItem>
                            <MenuItem value="February">February</MenuItem>
                            <MenuItem value="March">March</MenuItem>
                            <MenuItem value="April">April</MenuItem>
                            <MenuItem value="May">May</MenuItem>
                            <MenuItem value="June">June</MenuItem>
                            <MenuItem value="July">July</MenuItem>
                            <MenuItem value="August">August</MenuItem>
                            <MenuItem value="September">September</MenuItem>
                            <MenuItem value="October">October</MenuItem>
                            <MenuItem value="November">November</MenuItem>
                            <MenuItem value="December">December</MenuItem>
                          </Select>
                        </FormControl>
                      </MDBox>
                    </>
                  )}
                </MDBox> */}
              </MDBox>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <MDBox
                px={3}
                sx={{ pt: { xs: 0, sm: 0, md: 4, lg: 4 }, pb: { xs: 0, sm: 0, md: 3, lg: 3 } }}
              >
                <MDBox>
                  {filteredArr.length > 0 &&
                    newMonth.length > 0 &&
                    newYear.toString().length === 4 && (
                      <>
                        <MDBox mb={2}>
                          <MDTypography variant="h6">
                            Hp10 control value : {newHp10ControlReading} (mSv)
                          </MDTypography>
                        </MDBox>
                        <MDBox mb={2}>
                          <MDInput
                            id="deepDoseInput"
                            onFocus={resetHp10InputControlReading}
                            onChange={setNewHp10ControlReadingFunc}
                            inputProps={{ maxLength: 4 }}
                            style={{ width: "150px" }}
                            placeholder="Hp10 control value..."
                          />
                        </MDBox>
                      </>
                    )}
                  {filteredArr.length > 0 &&
                    newMonth.length > 0 &&
                    newYear.toString().length === 4 &&
                    newHp10ControlReading.length > 0 && (
                      <>
                        <MDBox mb={2}>
                          <MDTypography variant="h6">
                            Hp0.07 control value : {newHp007ControlReading} (mSv)
                          </MDTypography>
                        </MDBox>
                        <MDBox mb={2}>
                          <MDInput
                            id="shallowDoseInput"
                            onFocus={resetHp007InputControlReading}
                            onChange={setNewHp007ControlReadingFunc}
                            inputProps={{ maxLength: 4 }}
                            style={{ width: "150px" }}
                            placeholder="Hp0.07 control value..."
                          />
                        </MDBox>
                      </>
                    )}
                </MDBox>
              </MDBox>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <MDBox pb={3} px={3} sx={{ pt: { xs: 0, sm: 0, md: 4, lg: 4 } }}>
                <MDBox>
                  {filteredArr.length > 0 &&
                    newMonth.length > 0 &&
                    newYear.toString().length === 4 &&
                    newHp10ControlReading.length > 0 &&
                    newHp007ControlReading.length > 0 && (
                      <>
                        <MDBox mb={2}>
                          <MDTypography variant="h6">
                            Select file of .xls / .xlsx (actual)
                          </MDTypography>
                        </MDBox>
                        <MDBox mb={2}>
                          <MDInput
                            id="actualFile"
                            type="file"
                            accept=".xls,.xlsx"
                            onChange={onChangeFileActual}
                          />
                        </MDBox>
                      </>
                    )}
                </MDBox>
              </MDBox>
            </Grid>
          </Grid>
        </Card>
      </MDBox>

      {filteredArr.length > 0 &&
        newMonth?.length > 0 &&
        newYear.toString().length === 4 &&
        newDeepDose.length === 0 && (
          <MDBox pt={6} pb={3}>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Card>
                  <MDBox
                    mx={2}
                    mt={-3}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                  >
                    <MDTypography variant="h6" color="white">
                      Assigned dosimeters for {newMonth} {newYear}
                    </MDTypography>
                  </MDBox>
                  <MDBox pt={3}>
                    <DataTable
                      table={{ columns: collArr, rows: mappedRowsArr }}
                      entriesPerPage
                      isSorted
                      showTotalEntries
                      noEndBorder
                    />
                  </MDBox>
                </Card>
              </Grid>
            </Grid>
          </MDBox>
        )}

      {filteredArr.length > 0 &&
        newMonth?.length > 0 &&
        newYear.toString().length === 4 &&
        newShallowDose.length > 0 &&
        newDeepDose.length > 0 && (
          <MDBox pt={6} pb={3}>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Card>
                  <MDBox
                    mx={2}
                    mt={-3}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                  >
                    <MDTypography variant="h6" color="white" mb={2}>
                      Assigned dosimeters for {newMonth} {newYear}
                    </MDTypography>
                    <MDButton
                      variant="contained"
                      color="success"
                      onClick={submit}
                      disabled={
                        !(filteredArr.filter((e) => e[newYear][newMonth].hp10).length > 0)
                        // !filteredArr.every((e) => e[newYear][newMonth].hp007) &&
                        // !filteredArr.every((e) => e[newYear][newMonth].bgHp10) &&
                        // !filteredArr.every((e) => e[newYear][newMonth].bgHp007)
                      }
                    >
                      Sync to server
                    </MDButton>
                  </MDBox>
                  <MDBox pt={3}>
                    <DataTable
                      table={{
                        columns: collArrWithCtrlRdgAndActualBg,
                        rows: mappedRowsArrWithCtrlRdgAndActualBg,
                      }}
                      entriesPerPage
                      isSorted
                      showTotalEntries
                      noEndBorder
                    />
                  </MDBox>
                </Card>
              </Grid>
            </Grid>
          </MDBox>
        )}
      <Footer />
    </DashboardLayout>
  );
}

export default PostAnalysis;
