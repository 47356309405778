import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  // openDialogEdit: false,
  openDialogView: false,
  openLocsDialogView: false,
};

/* eslint-disable no-param-reassign */
const deleteSlice = createSlice({
  name: "delete",
  initialState,
  reducers: {
    openLocsDialogView: (state) => {
      state.openLocsDialogView = true;
    },
    closeLocsDialogView: (state) => {
      state.openLocsDialogView = false;
    },
    openDialogView: (state) => {
      state.openDialogView = true;
    },
    closeDialogView: (state) => {
      state.openDialogView = false;
    },
  },
});
/* eslint-enable no-param-reassign */

export const { openLocsDialogView, closeLocsDialogView, openDialogView, closeDialogView } =
  deleteSlice.actions;

export default deleteSlice.reducer;
