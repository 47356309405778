import colors from "assets/theme/base/colors";
import { useMaterialUIController } from "context";

const { professionPallette, dark } = colors;

function configs(labels, datasets) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const backgroundColors = [];

  if (datasets.backgroundColors) {
    datasets.backgroundColors.forEach((color) =>
      professionPallette[color]
        ? backgroundColors.push(professionPallette[color].state)
        : backgroundColors.push(dark.main)
    );
  } else {
    backgroundColors.push(dark.main);
  }

  return {
    data: {
      labels,
      datasets: [
        {
          label: datasets.label,
          weight: 9,
          tension: 0.9,
          pointRadius: 2,
          borderWidth: 2,
          backgroundColor: backgroundColors,
          fill: false,
          data: datasets.data,
          cutout: datasets.cutout,
        },
      ],
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: true,
          responsive: true,
          position: "left",
          align: "center",
          labels: {
            color: darkMode ? "#ffffffcc" : colors.text.main,
            boxWidth: 12,
            useBorderRadius: true,
            borderRadius: 5,
          },
          maxWidth: 1000,
        },
      },
      interaction: {
        intersect: true,
        mode: "index",
      },
      scales: {
        y: {
          grid: {
            drawBorder: false,
            display: false,
            drawOnChartArea: false,
            drawTicks: false,
          },
          ticks: {
            display: false,
          },
        },
        x: {
          grid: {
            drawBorder: false,
            display: false,
            drawOnChartArea: false,
            drawTicks: false,
          },
          ticks: {
            display: false,
          },
        },
      },
    },
  };
}

export default configs;
